import { useEffect, useState } from "react";
import axios from "axios";
import env from "../env";
import serializeParams from "~utilities/serializeParams";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { msalConfig } from "MSALConfig";

const _getAuthorizationToken = () =>
  localStorage.getItem("pocketbook_authorization_token");

export const getHeaders = () => {
  return {
    Authorization: `Bearer ${_getAuthorizationToken()}`,
    Accept: "application/json",
  };
};

export const getHeadersAZ = (identityToken, accessToken) => {
  return {
    // The id token is verifiable, while the accessToken is not.
    // We need both, so we create a composite authorization token
    Authorization: `Bearer ${identityToken}.${accessToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const unauthenticatedApiInstance = axios.create({
  baseURL: env.API_URL,
  headers: new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
  }),
});

export const getApiInstance = (
  msal,
  path,
  params = {},
  method = "get",
  data,
  reqUrl
) => {
  const { instance, accounts } = msal;
  const url = reqUrl || `${env.API_URL}${path}?${serializeParams(params)}`;
  return instance
    .acquireTokenSilent({
      account: accounts[0],
      scopes: msalConfig["auth"]["identityScopes"],
    })
    .then((authenticationResponse) => {
      return axios({
        url,
        method,
        headers: getHeadersAZ(
          authenticationResponse.idToken,
          authenticationResponse.accessToken
        ),
        data,
      })
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });
    });
};

export const useApi = (path, params = {}, method = "get") => {
  const msal = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true); // false only if we have 'data' in apiResponse
  const [apiResponse, setApiResponse] = useState();
  const url = `${env.API_URL}${path}?${serializeParams(params)}`;

  useEffect(() => {
    const getData = async () => {
      if (isAuthenticated && msal.inProgress === InteractionStatus.None) {
        const data = await getApiInstance(msal, null, null, method, null, url);
        setApiResponse(data);
        setIsLoading(false);
      }
    };
    getData();
    return () => {
      // Cleean up
      setIsLoading(true);
      setApiResponse();
    };
  }, [isAuthenticated, msal, url, method]);

  return [{ ...apiResponse, loading: isLoading }];
};
export const getCachedRequest = async (msal, resource, params) => {
  const cacheName = `${resource}DATA`;
  const regionDataCache = await window.caches.open(cacheName);

  const cacheHit = await regionDataCache.match(params);

  var expired = false;
  var formData; // declaring here because cannot read a `response` twice
  if (cacheHit) {
    formData = await cacheHit.formData();
    expired = new Date().getTime() > formData.get("expires");
  }
  var regionsLoading, responseData;
  try {
    if (cacheHit && !expired) {
      regionsLoading = false;
      responseData = JSON.parse(formData.get(resource));
      return responseData;
    } else {
      const response = await getApiInstance(msal, `/${resource}`, params);
      regionsLoading = response.loading;
      responseData = response.data;

      // This sets the expiration to 24 hours from the current moment
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const expires = new Date(
        new Date().getTime() + oneDayInMilliseconds
      ).getTime();
      formData = new FormData();
      formData.append(resource, JSON.stringify(responseData));
      formData.append("loading", regionsLoading);
      formData.append("expires", expires);

      const toCache = new Response(formData);
      await regionDataCache.put(params, toCache);

      return responseData;
    }
  } catch (error) {
    console.error(error); // TODO: Implement frontend-reporting, like bugsnag/logz.io / retry
  }
};
