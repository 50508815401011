import { getApiInstance } from "~utilities/api";
import env from "~utilities/../env";
import { DirectUpload } from "activestorage";

export const createListing = async (msal, values, files, cb) => {
  const createListing = getApiInstance(
    msal,
    "/listings",
    {
      include: { regions: true },
    },
    "post",
    { listing: values }
  );
  const createImages = function (response) {
    if (!files || !files.length || !files[0]) {
      return new Promise((resolve) => resolve([[response.data.id, []]]));
    }

    return Promise.all(
      Array.from(files).map(function (file) {
        const railsActiveStorageDirectUploadsUrl = `${env.API_URL}/rails/active_storage/direct_uploads`;

        return new Promise((resolve, reject) => {
          const upload = new DirectUpload(
            file,
            railsActiveStorageDirectUploadsUrl
          );
          upload.create((error, blob) => {
            if (error) {
              reject(error);
            } else {
              // blob exists in active_storage_blobs
              resolve([response.data.id, blob.signed_id]);
            }
          });
        });
      })
    );
  };

  const updateListingWithImage = async function (responsesWithBlobIds) {
    return await getApiInstance(
      msal,
      `/listings/${responsesWithBlobIds[0][0]}`,
      {
        include: {
          image_urls: true,
        },
      },
      "patch",
      {
        listing: {
          ...values,
          images: responsesWithBlobIds.map((resp) => resp[1]),
        },
      }
    );
  };

  const newListing = createListing
    .then(createImages)
    .then(updateListingWithImage)
    .then(cb)
    .catch((reason) => console.error(reason));
  return newListing;
  // TODO: handle error e.g. 422
};
