import React, { createContext, useContext, useState, useEffect } from "react";

const useViewportWidth = () => {
  const isSSR = () => typeof window === "undefined";
  const [viewportWidth, setViewportWidth] = useState(
    !isSSR() && window.innerWidth >= 992
  );
  useEffect(() => {
    if (!isSSR()) {
      const toggleDesktop = () => setViewportWidth(window.innerWidth);
      toggleDesktop(); // initial render
      window.addEventListener("resize", toggleDesktop);
      // clean up event listener
      return (_) => window.removeEventListener("resize", toggleDesktop);
    }
  });
  return viewportWidth;
};

const MediaContext = createContext({
  isMobile: true,
  isDesktop: false,
});

export default function MediaProvider({ children }) {
  const viewportWidth = useViewportWidth();
  const value = {
    viewportWidth,
    isMobile: viewportWidth < 991,
    isDesktop: viewportWidth >= 992,
  };
  return (
    <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
  );
}

export const useMediaContext = () => useContext(MediaContext);
