import React, { useEffect, useState } from "react";
import {
  formatCurrencyRangeCompact,
  formatPercent,
  formatDate,
} from "~utilities/format";
import { useListProvider, ListProvider } from "~providers/list";
import Table from "~components/Table";
import initialState from "./initialState";
import Loading from "~components/Loading";
import Layout from "~components/requirements/list/Layout";
import { getApiInstance } from "~utilities/api";
import CapitalizedList from "~components/CapitalizedList";
import { useMsal } from "@azure/msal-react";
import { Row, Col } from "reactstrap";
import FilterSidebar from "~components/FilterSidebar";
import { colors } from "~utilities/styles";
import styled from "styled-components";
import { useAuth } from "~providers/auth";
import { defaultFilters } from "~components/FilterSidebar";
import Popover from "~components/Popover";
import { useHistory } from "react-router-dom";
import OpportunityLeads from "~components/OpportunityLeads";
import { createOpportunity } from "~components/requirements/show/components/utils";
import ToastView from "~components/ToastView";

const SelfTag = styled.span`
  display: inline-block;
  background: ${colors.secondary};
  text-align: center;
  color: ${colors.foreground};
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
  border-radius: 2px;
`;

const StyledEmail = styled.div`
  color: ${colors.primary};
`;

const StyledPopover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;

  svg {
    line-height: 75px;
    height: 75px;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case "updateListing":
      return {
        ...state,
        ...action.payload,
      };

    case "addOpportunity":
      const { data } = action.payload;
      const opportunity = {
        ...data,
        ...{ comments: data.comments || [] },
      };
      return {
        ...state,
        opportunity,
        message: "Created Opportunity",
      };

    case "hideToast":
      return {
        ...state,
        message: "",
      };

    default:
      return state;
  }
};

const ListContent = ({ dispatch, listState }) => {
  const { state } = useListProvider();
  const [requirements, setRequirements] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const msal = useMsal();
  const auth = useAuth();

  const addOpportunity = (requirement, listing) => {
    createOpportunity({
      listing,
      requirementId: requirement.id,
      msal,
      dispatch,
      isBuyer: true,
    });
  };

  const onClose = () => {
    dispatch({ type: "hideToast" });
  };

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { loading, data } = await getApiInstance(msal, "/requirements", {
        ...state.params,
        where: {
          ...state.params.where,
          ...defaultFilters,
        },
        include: {
          ...state.params.include,
          leads: {
            ...(state.params.include?.leads || {}),
            user: true,
          },
        },
      });
      if (state.params.where.user_id === true) {
        setRequirements(data);
      } else {
        let filterData = [];
        filterData = data.filter((object) => object.user.id !== auth.user.id);
        setRequirements(filterData);
      }
      setLoading(loading);
    }
    fetch();
  }, [msal, state.params, dispatch, auth]);
  const history = useHistory();

  const onClick = (id) => {
    history.push(`/requirements/${id}`);
  };

  return (
    <Layout>
      <ToastView
        isOpen={!!listState.message}
        message={listState.message}
        closeToast={onClose}
        navigateToPage={() => onClick(listState.opportunity?.listing.id)}
      />
      <Row className="py-3">
        <Col sm="4" md="3" lg="3" xl="2" xxl="2" hidden={!state.displayFilter}>
          <FilterSidebar />
        </Col>
        <Col
          sm={state.displayFilter ? "8" : ""}
          md={state.displayFilter ? "9" : ""}
          lg={state.displayFilter ? "9" : ""}
          xl={state.displayFilter ? "10" : ""}
          xxl={state.displayFilter ? "10" : ""}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <Table
              resourcePath="requirements"
              tableHeight="calc(100vh - 160px)"
              columns={[
                {
                  id: "name",
                  Header: "Requirement",
                  accessor: ({ name }) => {
                    return name && name.toLowerCase();
                  },
                  columnWidth: "40rem",
                  Cell: ({ row }) => {
                    const { name, user } = row.original;
                    return (
                      <div className="h-100 d-flex flex-column justify-content-between">
                        {user.id === auth.user.id && (
                          <small>
                            <SelfTag>My Requirement</SelfTag>
                          </small>
                        )}
                        {name && <strong>{name}</strong>}
                        <br />
                        {user && (
                          <div className="d-flex flex-column justify-content-end">
                            <small>{user.name}</small>
                            <StyledEmail>{user.email}</StyledEmail>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
                {
                  id: "min_capitalization_percent",
                  Header: "Min. Cap Rate",
                  columnWidth: "10rem",
                  accessor: ({ min_capitalization_percent }) => {
                    return formatPercent(min_capitalization_percent, 3);
                  },
                },
                {
                  id: "price",
                  Header: "Price",
                  columnWidth: "15rem",
                  accessor: ({ min_price, max_price }) => {
                    return max_price || min_price;
                  },
                  Cell: ({ row }) => {
                    const { min_price, max_price } = row.original;
                    return (
                      <strong>
                        {formatCurrencyRangeCompact(min_price, max_price)}
                      </strong>
                    );
                  },
                },
                {
                  id: "types",
                  Header: "Property Type",
                  columnWidth: "10rem",

                  accessor: (requirement) => {
                    return requirement.types.length > 1
                      ? requirement.types.length
                      : requirement.types?.[0];
                  },
                  Cell: ({ row }) => {
                    const { types } = row.original;
                    return <CapitalizedList items={types} />;
                  },
                },
                {
                  id: "regions",
                  Header: "Preferred Location",
                  columnWidth: "10rem",
                  accessor: ({ regions }) => {
                    const names = regions.map((region) => region.name);
                    return names.length > 1 ? names.length : names?.[0];
                  },
                  Cell: ({ row }) => {
                    let { regions } = row.original;
                    regions =
                      regions.length > 0 ? regions : [{ name: "National" }];
                    return (
                      <CapitalizedList
                        items={regions.map((region) => region.name)}
                      />
                    );
                  },
                },
                {
                  id: "expires_on",
                  Header: "Expiration",
                  columnWidth: "10rem",
                  accessor: ({ expires_on }) => {
                    return formatDate(expires_on);
                  },
                  Cell: ({ row }) => {
                    let { expires_on } = row.original;
                    return <strong>{formatDate(expires_on)}</strong>;
                  },
                },
                {
                  id: "action",
                  Header: "Actions",
                  columnWidth: "10rem",
                  accessor: () => {
                    return "";
                  },
                  Cell: ({ row }) => {
                    const { id, leads } = row.original;
                    const menuItems = [
                      { text: "View", action: () => onClick(id) },
                      {
                        text: (
                          <OpportunityLeads
                            addOpportunity={(req) =>
                              addOpportunity(req, row.original)
                            }
                            resourcePath={"requirements"}
                            leads={leads}
                          />
                        ),
                        action: () => {},
                      },
                    ];
                    return (
                      <StyledPopover>
                        <Popover
                          items={menuItems}
                          width={160}
                          tippyProps={{ placement: "right", offset: [0, -15] }}
                          overflowVisible
                        />
                      </StyledPopover>
                    );
                  },
                },
              ]}
              data={requirements}
            />
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default function List() {
  const [state, dispatch] = React.useReducer(reducer, null);
  return (
    <ListProvider
      initialState={initialState}
      reducer={reducer}
      store={state || {}}
      dispatch={dispatch}
    >
      <ListContent dispatch={dispatch} listState={state || {}} />
    </ListProvider>
  );
}
