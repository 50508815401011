import React from "react";
import Popover from "~components/Popover";
import Loading from "~components/Loading";
import { formatCurrency } from "~utilities/format";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import { getActions, Label, DisplayText, placeholder } from "./utils";
import Thumbnail from "~components/Thumbnail";
import { useMsal } from "@azure/msal-react";
import { getApiInstance } from "~utilities/api";

const zeroStateLabels = {
  all: " opportunities or leads",
  open: " open opportunities",
  newMessages: " opportunities with new messages",
  closed: " closed opportunities",
  leads: " leads",
};

const ThumbnailWrapper = styled.div`
  ${({ card }) => card.requirement_id && !card.is_active && `opacity: 0.35;`}
`;

const StyledCard = styled.div`
  display: flex;
  margin-top: 6px;
  background: ${colors.foreground};
  border-radius: 6px;
  position: relative;
  border: 1px solid ${colors.gray["200"]};

  ${({ card }) =>
    card.requirement_id &&
    (!card.is_open || !card.is_active) &&
    `background: ${colors.gray["100"]}; color: ${colors.gray["400"]};`}
`;

const WarningBar = styled.div`
  position: absolute;
  left: -2px;
  top: 0;
  bottom: 0;
  border-radius: 3px;
  width: 6px;
  background: ${colors.warning};
`;

const ClickableDiv = styled.div`
  cursor: pointer;

  p {
    margin-bottom: 0;
  }
`;

const WarningMessage = styled.div`
  color: ${colors.warning};
`;

const BuyerZeroState = ({ filterState }) => {
  return (
    <div>
      This requirement has no
      {zeroStateLabels[filterState]}.
    </div>
  );
};

const SellerZeroState = ({ filterState }) => {
  return (
    <div>
      {filterState !== "all" && filterState !== "leads"
        ? `You have no ${zeroStateLabels[filterState]} with this requirement`
        : "You have no listings, create one to start a conversation with the creator of this requirement"}
      .
    </div>
  );
};

const RequirementCard = ({
  handleClick,
  requirement,
  closeModal,
  dispatch,
  currentUser,
  card,
  isBuyer,
}) => {
  const msal = useMsal();
  const actionCallback = (action) => {
    dispatch(action);
    closeModal();
  };
  const isOpportunity = card.requirement_id;
  const actions = getActions(
    card,
    requirement,
    currentUser,
    msal,
    actionCallback
  );
  const onClick = () => {
    const selectedCard = isOpportunity
      ? { opportunity: card }
      : { listing: card };
    handleClick(selectedCard);
  };
  const {
    name,
    image_urls,
    address,
    price,
    user: cardUser,
  } = isOpportunity ? card.listing : card;
  const [firstComment] = card?.comments || [];
  const showWarning =
    isOpportunity && firstComment && firstComment?.user_id !== currentUser.id;
  const menuItems = [{ text: "View", action: onClick }, ...actions];
  const listingText = isBuyer ? "Lead" : "Listing";

  const [user, setUser] = React.useState(cardUser);

  React.useEffect(
    function () {
      if (user) {
        return;
      }
      if (isOpportunity) {
        setUser(card.requirement.user);
        return;
      }
      const toInvoke = async function () {
        const { data } = await getApiInstance(msal, "/users/byid", {
          id: card.user_id,
        });
        setUser(data);
      };
      toInvoke();
    },
    [msal, card, user, cardUser, isOpportunity]
  );

  return !user ? (
    <Loading></Loading>
  ) : (
    <StyledCard card={card}>
      <ClickableDiv className="d-flex p-4 w-100 truncate" onClick={onClick}>
        {showWarning && <WarningBar />}
        <ThumbnailWrapper card={card}>
          <Thumbnail imageUrl={image_urls?.[0]} />
        </ThumbnailWrapper>
        <div className="row px-3 truncate" style={{ flex: 1 }}>
          <div className="col-md-12">
            <h5 className="mb-2">
              <strong>{isOpportunity ? "Opportunity" : listingText}</strong>
            </h5>
          </div>
          <div className="col-md-6">
            <Label>Name:</Label>
            <DisplayText text={name} />
            <Label>Address:</Label>
            <DisplayText text={address} />
            <Label>Price:</Label>
            <p>{price ? formatCurrency(price) : placeholder}</p>
          </div>
          <div className="col-md-6">
            <Label>Contact:</Label>
            <div>
              <div>{user.name}</div>
              <div className="link-primary">{user.email}</div>
            </div>
            {isOpportunity && (
              <div className="d-flex flex-column">
                <div className="text-capitalize text-left">
                  <strong>Status: </strong>
                  {card.is_active &&
                  requirement.is_open &&
                  requirement.is_active &&
                  card.listing.is_active
                    ? "Open"
                    : "Closed"}
                </div>
                <div>
                  {`${card.comments?.length || "No "} 
              ${card.comments?.length === 1 ? "Comment" : "Comments"} `}
                </div>
                {showWarning && (
                  <WarningMessage>
                    <em>New Message!</em>
                  </WarningMessage>
                )}
              </div>
            )}
          </div>
        </div>
      </ClickableDiv>
      <div className="p-4 mt-1">
        <Popover
          items={menuItems}
          width={160}
          tippyProps={{ placement: "auto", offset: [0, -15] }}
        />
      </div>
    </StyledCard>
  );
};

export default function RequirementCards({
  handleClick,
  requirement,
  closeModal,
  dispatch,
  cardList,
  filterState,
  currentUser,
}) {
  const isBuyer = currentUser.id === requirement.user_id;
  const ZeroStateRenderer = isBuyer ? BuyerZeroState : SellerZeroState;
  if (!cardList) {
    return (
      <div className="d-flex align-items-center px-5 py-3">
        <Loading />
      </div>
    );
  }
  return (
    <section className="px-3 pb-2 flex-grow-1 overflow-auto">
      {!cardList.length ? (
        <ZeroStateRenderer filterState={filterState} />
      ) : (
        cardList.map((item) => {
          return (
            <RequirementCard
              key={item.id}
              {...{
                card: item,
                handleClick,
                closeModal,
                dispatch,
                requirement,
                currentUser,
                isBuyer,
              }}
            />
          );
        })
      )}
    </section>
  );
}
