import React from "react";
import { Button } from "reactstrap";
import AuthForm from "../components/AuthForm";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";

function handleLogin(instance, history) {
  instance.loginRedirect().catch((e) => {
    console.error(e);
  });
  history.push("/");
}

export default function Login() {
  const { instance } = useMsal();
  const history = useHistory();
  return (
    <AuthForm title="Login">
      <div className="p-5">
        <Button
          block
          className="mb-3"
          size="lg"
          onClick={() => handleLogin(instance, history)}
        >
          Login
        </Button>
      </div>
    </AuthForm>
  );
}
