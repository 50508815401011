import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Container } from "reactstrap";
import TabNavigation from "~components/TabNavigation";
import styled from "styled-components";
import { colors, zIndex } from "~utilities/styles";

const NavSection = styled.section`
  background: ${colors.foreground};
  border-bottom: 1px solid ${colors.gray["300"]};
  z-index: ${zIndex.elevated};
  position: sticky;
  top: 49px;
`;

const WelcomeText = styled.div`
  font-size: 1.5rem;
`;

const TabWrapper = styled.div`
  flex: 1;
`;

export default function Layout({ children }) {
  const match = useRouteMatch();
  // eslint-disable-next-line no-unused-vars
  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    setActiveLink(match.path);
  }, [match]);

  return (
    <Container fluid>
      <NavSection className="row px-4">
        <WelcomeText className="d-flex align-items-center">Welcome</WelcomeText>
        <TabWrapper className="d-flex justify-content-center">
          <TabNavigation
            expanded
            tabs={[
              {
                path: "/myrequirements",
                title: "My Requirements",
              },
              {
                path: "/mylistings",
                title: "My Listings",
              },
            ]}
          />
        </TabWrapper>
      </NavSection>
      {children}
    </Container>
  );
}
