import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { colors } from "~utilities/styles";

const DatePickerWrapper = styled.div`
  .selected-day {
    background: ${colors.primary};
    &:hover {
      background: ${colors.primaryDark};
    }
  }
`;

export default function DatePickerField({ ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const dateValue = new Date(field.value);
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  return (
    <DatePickerWrapper>
      <DatePicker
        {...field}
        {...props}
        {...{
          popperModifiers: [
            {
              name: "arrow",
              options: {
                padding: 20,
              },
            },
            ...props.popperModifiers,
          ],
        }}
        selected={(field.value && dateValue) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        dayClassName={(date) => {
          if (
            (!field.value && date.toString() === today.toString()) ||
            (field.value && date.toString() === dateValue.toString())
          ) {
            return "selected-day";
          }
          return "";
        }}
      />
    </DatePickerWrapper>
  );
}

DatePickerField.defaultProps = { popperModifiers: [] };
