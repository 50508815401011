import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useShowProvider } from "~providers/show";
import Loading from "~components/Loading";
import FullScreenLayout from "~components/pages/FullScreenLayout";
import { formatCurrency, formatDate, formatPercent } from "~utilities/format";
import ListingContent from "./components/ListingContent";
import { Label, DisplayText } from "./components/utils";
import { useAuth } from "../../../providers/auth";
import BadgeList from "~components/BadgeList";
import { useApi } from "~utilities/api";
import styled from "styled-components";
import { colors, setBreakPoint } from "~utilities/styles";
import Button from "~components/Button";
import EditModal from "./../edit/index";
import Thumbnail from "~components/Thumbnail";
import Modal from "~components/Modal";

const ContentSection = styled.section`
  background: ${colors.gray["100"]};
  flex-wrap: wrap;
  overflow: auto;

  ${setBreakPoint({
    size: "md",
    styles: `overflow: hidden; flex-wrap: no-wrap;`,
  })}
`;

const DetailsPanel = styled.div`
  background: ${colors.foreground};
`;

const CardsPanel = styled.div`
  ${setBreakPoint({
    size: "md",
    styles: `max-height: 100%;`,
  })}
`;

const StyledThumbnail = styled.span`
  cursor: pointer;
`;

const ThumbnailWrapper = styled.span`
  max-width: 100%;
  overflow: auto;
`;

const ImagesWrapper = styled.section`
  img {
    width: 100%;
    height: auto;
  }
  ${setBreakPoint({
    size: "md",
    styles: `height: 100%;
  overflow: auto;`,
  })}
`;

const Images = ({ urls, onClick }) => {
  return (
    <ThumbnailWrapper className="d-flex p-2">
      {urls.map((url) => {
        return (
          <StyledThumbnail
            key={url}
            className="mr-2 mb-2 box-shadow"
            onClick={() => onClick(url)}
          >
            <Thumbnail imageUrl={url} />
          </StyledThumbnail>
        );
      })}
    </ThumbnailWrapper>
  );
};

const BuyerContent = ({ user, dispatch, store, listing }) => {
  const [updatedState, setUpdatedState] = useState(false);
  const [requirementsResponse] = useApi("/requirements", {
    limit: 1000,
    include: {
      opportunities: { listing: true },
      regions: true,
      user: true,
    },
    where: {
      user: {
        email: user.email,
      },
      is_active: true,
    },
  });
  const [opportunitiesResponse] = useApi("/opportunities", {
    limit: 1000,
    include: {
      requirement: {
        regions: true,
        user: true,
      },
      comments: {
        body: true,
        user: true,
        document_urls: true,
      },
    },
    where: {
      listing_id: listing.id,
    },
  });
  useEffect(() => {
    if (
      !opportunitiesResponse.loading &&
      !requirementsResponse.loading &&
      !updatedState
    ) {
      setUpdatedState(true);
      dispatch({
        type: "setStore",
        payload: {
          ...listing,
          ...{
            opportunities: opportunitiesResponse.data,
            leads: requirementsResponse.data,
          },
        },
      });
    }
  }, [
    store,
    dispatch,
    opportunitiesResponse,
    requirementsResponse,
    updatedState,
    listing,
  ]);
  if (opportunitiesResponse.loading || requirementsResponse.loading) {
    return (
      <div className="d-flex align-items-center px-5 py-3">
        <Loading />
      </div>
    );
  }
  return <ListingContent {...{ user, dispatch, store, listing }} />;
};

export default function Show(props) {
  const {
    request: [response],
    dispatch,
    store: listing,
  } = useShowProvider();
  const { user: currentUser } = useAuth();
  const { url } = useRouteMatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPhotoUrl, selectPhotoUrl] = useState();
  useEffect(() => {
    if (!response.loading && !listing) {
      dispatch({ type: "setStore", payload: response.data });
    }
  }, [response, listing, dispatch]);
  if (response.loading || !listing) return <Loading />;
  if (response.error) return <p>Error!</p>;
  const {
    user_id,
    name,
    user,
    description,
    price,
    types,
    expires_on,
    address,
    image_urls,
    capitalization_percent,
    lease_term,
  } = listing;
  const baseUrl = "/" + url.split("/")[1];
  const isSeller = currentUser.id === user_id;
  return (
    <FullScreenLayout
      {...{
        title: name,
        closeLink: baseUrl,
        titleLabel: "Listing:",
        user,
      }}
    >
      <ContentSection className="d-flex flex-grow-1">
        <DetailsPanel className="col-sm-12 col-md-4 p-0">
          <div className="px-4 pb-4">
            {isSeller && (
              <div className="d-flex align-items-center py-3">
                <Button
                  buttonAttributes={{ className: "mr-3" }}
                  theme="primary"
                  onClick={() => setShowEditModal(true)}
                >
                  Edit Listing
                </Button>
              </div>
            )}
            <div className="d-flex align-items-center">
              <h3 className="my-4 mr-4">Details</h3>
            </div>
            {description && <p>{description}</p>}
            <Label>Expires on</Label>
            <DisplayText text={expires_on && formatDate(expires_on)} />
            <Label>Listing Price</Label>
            <DisplayText text={price && formatCurrency(price)} />
            <Label>Cap Rate</Label>
            <DisplayText
              text={
                capitalization_percent
                  ? formatPercent(capitalization_percent, 3)
                  : "Unspecified"
              }
            />
            <Label>Lease Term</Label>
            <DisplayText
              text={
                lease_term
                  ? lease_term + " Year" + (lease_term > 1 ? "s" : "")
                  : "Unspecified"
              }
            />
            <Label>Address</Label>
            <DisplayText text={address || "Unspecified"} />
            <Label>Types</Label>
            {types.length ? <BadgeList items={types} /> : "Unspecified"}
            <ImagesWrapper className="py-4">
              {image_urls && (
                <>
                  <Label>Images</Label>
                  <Images urls={image_urls} onClick={selectPhotoUrl} />
                </>
              )}
            </ImagesWrapper>
          </div>
          {selectedPhotoUrl && (
            <Modal
              {...{
                handleClose: () => {
                  selectPhotoUrl();
                },
                asideUrls: image_urls,
                selectedUrl: selectedPhotoUrl,
                setSelectedUrl: selectPhotoUrl,
              }}
            />
          )}
        </DetailsPanel>
        <CardsPanel className="col-sm-12 col-md-8 d-flex flex-column p-0">
          {isSeller ? (
            <ListingContent
              {...{
                user: currentUser,
                dispatch,
                store: listing,
                listing,
              }}
            />
          ) : (
            <BuyerContent
              {...{
                user: currentUser,
                dispatch,
                store: listing,
                listing,
              }}
            />
          )}
        </CardsPanel>
        {showEditModal && (
          <EditModal
            listing={listing}
            handleClose={() => {
              setShowEditModal(false);
            }}
            dispatch={dispatch}
          />
        )}
      </ContentSection>
    </FullScreenLayout>
  );
}
