import React, { useState } from "react";
import { formatCurrency, formatDate, formatPercent } from "~utilities/format";
import Comments from "~components/Comments";
import Modal, { Divider } from "~components/Modal";
import { getActions, Label, DisplayText, placeholder } from "./utils";
import Popover from "components/Popover";
import { getApiInstance } from "~utilities/api";
import Thumbnail from "~components/Thumbnail";
import Button from "~components/Button";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Tooltip from "~components/Tooltip";
import BadgeList from "~components/BadgeList";
import { useMsal } from "@azure/msal-react";
import * as matomo from "../../../../matomo.js";

const ClosedLabel = styled.div`
  color: ${colors.gray["600"]};
`;

const StyledThumbnail = styled.span`
  cursor: pointer;
`;

const ThumbnailWrapper = styled.span`
  max-width: 100%;
  overflow: auto;
`;

const Images = ({ urls, onClick }) => {
  return (
    <ThumbnailWrapper className="d-flex p-2">
      {urls.map((url) => {
        return (
          <StyledThumbnail
            key={url}
            className="mr-2 mb-2 box-shadow"
            onClick={() => onClick(url)}
          >
            <Thumbnail imageUrl={url} />
          </StyledThumbnail>
        );
      })}
    </ThumbnailWrapper>
  );
};

const CASection = ({ cb }) => {
  return (
    <div className="px-4">
      <h4>Confidential</h4>
      <p className="mb-5">
        By clicking below you agree not to share any information about this
        listing.
      </p>
      <Button theme="primary" onClick={cb}>
        I Agree
      </Button>
    </div>
  );
};

const getCardFromStore = (opportunity, listing, store) => {
  const cards = opportunity ? store.opportunities : store.leads;
  const id = opportunity?.id || listing?.id;
  return cards.find((card) => card.id === id);
};

const canOpportunityBeCreated = (requirement, listing) => {
  return (
    requirement &&
    requirement.is_open &&
    requirement.is_active &&
    listing &&
    listing.is_active
  );
};

const canOpportunityBeCommented = (opportunity, requirement, listing) => {
  return (
    requirement &&
    requirement.is_open &&
    requirement.is_active &&
    listing &&
    listing.is_active &&
    opportunity.requirement_id &&
    opportunity.listing_id &&
    opportunity.is_active
  );
};

export default function CardModal({
  opportunity,
  listing,
  currentUser,
  handleClose,
  title,
  dispatch,
  requirement,
  store,
}) {
  /* 
    Card modal is used to display requirement cards, which will either be
    an opportunity or a listing. to determine if the card is an opportunity
    we can check if card has the a value for requirement_id, otherwise it
    is a listing.
  */
  const msal = useMsal();
  const [selectedUrl, setSelectedUrl] = useState();
  const [loading, setLoading] = useState();
  const card = getCardFromStore(opportunity, listing, store);
  const [showContent, setShowContent] = useState(
    card.requirement_id || currentUser.id === listing?.user_id
  );
  // listing data to display, if card is an opportunity
  // key into the opportunity's listing
  const {
    id,
    name,
    description,
    address,
    price,
    image_urls,
    types,
    expires_on,
    capitalization_percent,
    lease_term,
  } = card.requirement_id ? card?.listing : card;
  const actions = getActions(card, requirement, currentUser, msal, dispatch);
  const isBuyer = requirement.user_id === currentUser.id;
  const commentAction = async (commentBody, documentBlobs) => {
    setLoading(true);
    //TODO: this should be one request
    const vars = {
      listing_id: id,
      requirement_id: requirement.id,
    };
    // create opportunity
    const { data } = await await getApiInstance(
      msal,
      "/opportunities",
      {},
      "post",
      vars
    );
    const commentVars = {
      comment: {
        opportunity_id: data.id,
        user_id: isBuyer ? requirement.user_id : listing.user_id,
        body: commentBody,
        documents: documentBlobs,
      },
    };
    matomo.trackEvent(
      isBuyer ? "My Requirement Detail" : "Requirement Detail",
      "Create Opportunity",
      data.id
    );
    // add comment to opportunity
    const comments = await getApiInstance(
      msal,
      "/comments",
      {
        include: {
          user: true,
          document_urls: true,
        },
      },
      "post",
      commentVars
    );
    matomo.trackEvent(
      isBuyer ? "My Requirement Detail" : "Requirement Detail",
      "Create comment with opportunity",
      comments.data.id,
      comments.data.document_urls?.length || 0
    );
    const newOpportunity = {
      ...data,
      requirement_id: requirement.id,
      listing_id: card.id,
      listing: card,
      requirement,
      comments: [comments.data],
    };
    // add new opportunity with comment to the store
    dispatch({
      type: "addOpportunity",
      payload: { data: newOpportunity },
    });
    handleClose();
  };
  const isOpportunity = card.requirement_id;
  listing = isOpportunity ? card.listing : listing;
  const allowComments = isOpportunity
    ? canOpportunityBeCommented(card, requirement, listing)
    : canOpportunityBeCreated(requirement, listing);
  const isClosed = {
    opportunityClosed: isOpportunity && !card.is_active,
    requirementClosedOrDeactivated:
      !requirement.is_open || !requirement.is_active,
    listingClosed: !listing.is_active,
  };
  return (
    <Modal
      {...{
        handleClose,
        title,
        asideUrls: image_urls,
        selectedUrl,
        setSelectedUrl,
        loading,
      }}
    >
      <div className="d-flex flex-column">
        <div className="px-4 pt-4">
          <Label>Listing Name:</Label>
          <div className="d-flex justify-content-between w-100">
            <div>
              <h3 className="mb-3">{name}</h3>
              <div>
                <Label>Contact Information:</Label>
                <>{currentUser?.name}</>
              </div>
              <div>
                <a
                  className="link-primary"
                  href={`mailto:${currentUser?.email}`}
                >
                  {currentUser?.email}
                </a>
              </div>
            </div>
            {showContent && (
              <div className="d-flex align-items-start">
                {!allowComments && (
                  <ClosedLabel className="d-flex mr-3 align-items-center">
                    <Tooltip
                      content={
                        <span>
                          {isClosed.opportunityClosed
                            ? "This opportunity is closed"
                            : isClosed.requirementClosedOrDeactivated
                            ? "This requirement is closed or deactivated"
                            : isClosed.listingClosed
                            ? "This listing is deactivated"
                            : "Error"}
                          , so you can no longer continue this conversation.
                        </span>
                      }
                    />
                    <em className="ml-1">Closed</em>
                  </ClosedLabel>
                )}
                {actions && (
                  <Popover
                    items={actions}
                    width={160}
                    tippyProps={{ offset: [0, -10], placement: "left" }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <Divider>{card.requirement_id && "Listing "} Details</Divider>
        {showContent ? (
          <>
            <section className="px-4">
              {card.requirement_id && (
                <span className="text-capitalize">
                  <Label>Stage:</Label>
                  <DisplayText text={card.stage} />
                </span>
              )}
              <Label>Expires on:</Label>
              <p>{expires_on ? formatDate(expires_on) : placeholder}</p>
              <Label>Price:</Label>
              <p>{price ? formatCurrency(price) : placeholder}</p>
              <Label>Cap Rate:</Label>
              <p>
                {capitalization_percent
                  ? formatPercent(capitalization_percent, 3)
                  : placeholder}
              </p>
              <Label>Lease Term:</Label>
              <DisplayText
                text={
                  lease_term
                    ? lease_term + " Year" + (lease_term > 1 ? "s" : "")
                    : "Unspecified"
                }
              />
              <Label>Address:</Label>
              <DisplayText text={address} />
              <Label>Description:</Label>
              <DisplayText text={description} />
              <Label>Types:</Label>
              <div className="mb-3">
                {types.length ? (
                  <BadgeList items={types} truncateList />
                ) : (
                  "Unspecified"
                )}
              </div>
              {image_urls?.length && (
                <>
                  <Label>Images</Label>
                  <Images urls={image_urls} onClick={setSelectedUrl} />
                </>
              )}
            </section>
            {(card.requirement_id ||
              (requirement.is_open && requirement.is_active)) && (
              <>
                <Divider>Comments</Divider>
                <div className="px-4 pb-4">
                  <Comments
                    {...{
                      displayOnly: !allowComments,
                      userId: currentUser.id,
                      dispatch,
                      cb: (comment) => {
                        matomo.trackEvent(
                          isBuyer
                            ? "My Requirement Detail"
                            : "Requirement Detail",
                          "Comment on opportunity",
                          comment.id,
                          comment.document_urls?.length || 0
                        );
                      },
                      opportunityId: card.id,
                      comments: card.comments,
                      commentAction: !card.requirement_id && commentAction,
                      buttonText: !opportunity && "Comment and Qualify",
                    }}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <CASection cb={() => setShowContent(true)} />
        )}
      </div>
    </Modal>
  );
}
