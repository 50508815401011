import { getApiInstance } from "~utilities/api";
import env from "~utilities/../env";
import { DirectUpload } from "activestorage";
import { defaultParams } from "pages/listings/listingsDefaultConfigs";

export const editListing = async (msal, id, values, files) => {
  const updateImages = function () {
    if (!files || !files.length || !files[0]) {
      return new Promise((resolve) => resolve([]));
    }
    return Promise.all(
      Array.from(files).map(function (file) {
        const railsActiveStorageDirectUploadsUrl = `${env.API_URL}/rails/active_storage/direct_uploads`;

        return new Promise((resolve, reject) => {
          const upload = new DirectUpload(
            file,
            railsActiveStorageDirectUploadsUrl
          );
          upload.create((error, blob) => {
            if (error) {
              reject(error);
            } else {
              // blob exists in active_storage_blobs
              resolve([id, blob.signed_id]);
            }
          });
        });
      })
    );
  };

  const updateListingWithImage = async function (responsesWithBlobIds) {
    const images = responsesWithBlobIds.map((resp) => resp[1]);
    const listing = images.length ? { ...values, images } : { ...values };
    return await getApiInstance(
      msal,
      `/listings/${id}`,
      {
        include: {
          ...defaultParams.include,
          address: true,
          image_urls: true,
        },
      },
      "patch",
      {
        listing,
      }
    );
  };

  const res = await updateImages();
  const updatedListing = await updateListingWithImage(res);
  return updatedListing;
};
