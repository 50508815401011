import React from "react";
import { Badge } from "reactstrap";
import { formatTypeWithTooltip } from "utilities/format";
import Tooltip from "./Tooltip";

export default function BadgeList({ items, truncateList }) {
  if (items?.length > 1 && truncateList) {
    const plusCopy = ` +${items.length - 1}`;
    const copy = items?.[0];
    const content = items.map((data, i) => {
      return (
        <div key={i} className="text-capitalize mb-2">
          {formatTypeWithTooltip(data, false)}
        </div>
      );
    });
    return (
      <Tooltip content={content} triggerStyles={{ maxWidth: "100%" }}>
        <div className="d-flex truncate">
          <Badge className="p-2 text-capitalize truncate">{copy}</Badge>
          <Badge className="p-2 ml-1">{plusCopy}</Badge>
        </div>
      </Tooltip>
    );
  }
  return (
    <div className="d-flex truncate flex-wrap">
      {items.map((item, i) => {
        return (
          <Badge key={i} className="p-2 text-capitalize truncate mr-1 mt-1">
            {formatTypeWithTooltip(item)}
          </Badge>
        );
      })}
    </div>
  );
}
