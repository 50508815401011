import React from "react";
import styled, { css } from "styled-components";
import { colors, zIndex } from "~utilities/styles";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${zIndex.base};
  padding: 1rem;

  & svg {
    cursor: pointer;
    opacity: 0.9;
    transtion: 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
  ${({ styles }) => styles && css(styles)}
`;

export default function CloseButton({
  size,
  closeLink,
  handleClick,
  color,
  styles,
}) {
  const history = useHistory();
  const goBack = closeLink ? () => history.push(closeLink) : history.goBack;
  const onClick = handleClick || goBack;
  return (
    <Button onClick={onClick} styles={styles}>
      <FontAwesomeIcon icon="times" size={size} color={color} />
    </Button>
  );
}

CloseButton.defaultProps = { size: "2x", color: `${colors.foreground}` };
