import React, { useRef, useState, useEffect } from "react";
import { colors } from "utilities/styles";
import styled from "styled-components";

const Map = styled.div`
  height: 30rem;
`;

export default function StatesMapComponent({
  center,
  zoom,
  stateRegions,
  selectedRegions,
  setSelectedRegions,
}) {
  const ref = useRef();
  const [map, setMap] = useState(null);
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, { center, zoom }));
    }
  }, [center, zoom, ref, map]);

  useEffect(() => {
    const polygonsToBeRemovedOnNextRender = [];
    if (map) {
      stateRegions.forEach((sr) => {
        const stateMultiPolygon = sr.geometrygeojson.coordinates;
        const arraysToRenderOnGoogleMaps = stateMultiPolygon.map(
          (statePolygon) => {
            return statePolygon.map((coordinates) => {
              const polygonPath = coordinates.map((c) => {
                return new window.google.maps.LatLng(c[1], c[0]); // order flip is intentional; geojson is long-lat ordering
              });
              // polylines must be a closed-loop.
              const first = polygonPath[0];
              polygonPath.push(
                new window.google.maps.LatLng(first.lat(), first.lng())
              );
              return polygonPath;
            });
          }
        );

        // There is a spurrious level of arrays in our geojson backend reply. Cleaning it up here.
        const firstAndOnlyArrayPerState = arraysToRenderOnGoogleMaps.map(
          (arrays) => arrays[0]
        );
        const unselectedOptions = {
          strokeColor: colors.mapStrokeColor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: colors.mapFillColor,
          fillOpacity: 0.35,
          paths: firstAndOnlyArrayPerState,
        };
        const selectedOptions = {
          ...unselectedOptions,
          fillColor: colors.mapStrokeColor,
        };

        const isSelected =
          selectedRegions.findIndex((item) => {
            return item.name === sr.name;
          }) !== -1;
        const initialOptions = isSelected ? selectedOptions : unselectedOptions;

        // on-click trigger a re-render (setSelectedRegions invocation)
        // then, next-render will cause the newly-selected state to render "filled in"
        const statePolygon = new window.google.maps.Polygon(initialOptions);
        statePolygon.addListener("click", function (ev) {
          const selected =
            selectedRegions.findIndex((item) => {
              return item.name === sr.name;
            }) !== -1;
          if (selected) {
            setSelectedRegions((regions) =>
              regions.filter((region) => region.name !== sr.name)
            );
          } else {
            setSelectedRegions((regions) => [...regions, sr]);
          }
        });
        statePolygon.setMap(map); // setMap adds the region to the map
        polygonsToBeRemovedOnNextRender.push(statePolygon);
      });
    }
    return () => {
      // cleanup function; need to remove the old regions from the map (faster than re-rendering the entire gmap)
      polygonsToBeRemovedOnNextRender.forEach((p) => p.setMap(null));
    };
  }, [map, stateRegions, selectedRegions, setSelectedRegions]);
  return <Map id="map" ref={ref}></Map>;
}
