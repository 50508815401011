import runtimeEnv from "@mars/heroku-js-runtime-env";

const config = {
  development: {
    auth: {
      clientId: "aea8de0d-a44b-430a-8e4a-58a70d32e1c3",
      authority:
        "https://login.microsoftonline.com/b4c85df2-2c41-4123-9957-f3c8a360f511",
      redirectUri: encodeURI("http://localhost:4000/"),
      identityScopes: ["User.Read"],
      forceRefresh: false,
    },
    cache: {
      cacheLocation: "localStorage", // localStorage so direct links from emails work. Otherwise cookies are per-session, and we need to handle correctly redirecting users post-signin
    },
  },
  staging: {
    auth: {
      clientId: "aea8de0d-a44b-430a-8e4a-58a70d32e1c3",
      authority:
        "https://login.microsoftonline.com/b4c85df2-2c41-4123-9957-f3c8a360f511",
      redirectUri: encodeURI(
        "https://newmark-pocketbook-ui-stage.herokuapp.com/"
      ),
      identityScopes: ["User.Read"],
      forceRefresh: false,
    },
    cache: {
      cacheLocation: "localStorage", // localStorage so direct links from emails work. Otherwise cookies are per-session, and we need to handle correctly redirecting users post-signin
    },
  },
  production: {
    auth: {
      clientId: "185f844d-c5be-46d4-bb30-1eacfcfcf0f1",
      authority:
        "https://login.microsoftonline.com/b4c85df2-2c41-4123-9957-f3c8a360f511",
      redirectUri: encodeURI("https://app.pocketbook.nmrk.com/"),
      identityScopes: ["User.Read"],
      forceRefresh: false,
    },
    cache: {
      cacheLocation: "localStorage", // localStorage so direct links from emails work. Otherwise cookies are per-session, and we need to handle correctly redirecting users post-signin
    },
  },
};

const env = runtimeEnv();
const environment = env.REACT_APP_ENV || env.NODE_ENV;
const msalConfig = config[environment];
export { msalConfig };
