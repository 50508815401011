import React, { useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getApiInstance } from "~utilities/api";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useMsal } from "@azure/msal-react";

export default function RegionSelectField({
  form,
  selectedRegions,
  setSelectedRegions,
}) {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const msal = useMsal();
  const onSearch = async (query) => {
    setIsLoading(true);
    if (query.length >= 3) {
      const params = {
        limit: 5,
        where: {
          q: query,
        },
      };
      const { data } = await getApiInstance(msal, "/regions.json", params);
      setOptions(data);
    }
    setIsLoading(false);
  };

  const onChange = (choices) => {
    form.setFieldValue(
      "region_ids",
      choices.map((choice) => choice.id)
    );
    setSelectedRegions(choices);
  };

  return (
    <>
      <AsyncTypeahead
        defaultSelected={options.slice(0, 4)}
        id="regions-search"
        labelKey={(o) =>
          o.description ? `${o.name} (${o.description})` : o.name
        }
        multiple
        options={options}
        placeholder="Search for a market"
        onSearch={onSearch}
        isLoading={isLoading}
        onChange={onChange}
        selected={selectedRegions}
        ref={ref}
      />
    </>
  );
}
