import { Formik } from "formik";
import { Container } from "reactstrap";

export default function FormPageLayout({
  initialValues,
  validate,
  headerText,
  children,
  onSubmit,
}) {
  return (
    <Container className="my-3">
      <h1 className="mt-5 mb-4">{headerText}</h1>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {children}
      </Formik>
    </Container>
  );
}
