import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import MediaProvider from "~providers/media";
import Header from "~components/Header";
import * as Mine from "~pages/mine";
import * as Auth from "~pages/auth";
import * as Listings from "~pages/listings";
import * as Requirements from "~pages/requirements";
import * as Errors from "~pages/errors";
import { ProvideAuth } from "~providers/auth";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { trackPageView } from "./matomo";

library.add(fas);

export const AppStyles = createGlobalStyle``;

function RequireAuth({ children }) {
  let location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  const unprotectedPathsToComponents = {
    "/reset": Auth.ResetEmail,
    "/reset/password": Auth.ResetPassword,
  };
  if (unprotectedPathsToComponents[location.pathname]) {
    const Component = unprotectedPathsToComponents[location.pathname];
    return <Component />;
  }
  return children;
}

function App() {
  return (
    <ProvideAuth>
      <>
        <UnauthenticatedTemplate>
          <Auth.Login />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AppStyles />
          <Router basename={process.env.PUBLIC_URL}>
            <RequireAuth>
              <MediaProvider>
                <Header />
                <main style={{ minHeight: "calc(100vh - 148px)" }}>
                  <Switch>
                    <Route exact path="/mylistings" component={Mine.Listings} />
                    <Route
                      exact
                      path="/myrequirements"
                      component={Mine.Requirements}
                    />
                    <Route exact path="/listings" component={Listings.List} />
                    <Route
                      exact
                      path="/listings/create"
                      component={Listings.Create}
                    />
                    <Route
                      exact
                      path={[
                        "/listings/:id",
                        "/mylistings/:id",
                        "/listings/:id/opportunities",
                      ]}
                      component={Listings.Show}
                    />
                    <Route
                      exact
                      path={["/", "/requirements"]}
                      component={Requirements.List}
                    />
                    <Route
                      exact
                      path="/requirements/create"
                      component={Requirements.Create}
                    />
                    <Route
                      exact
                      path={["/requirements/:id", "/myrequirements/:id"]}
                      component={Requirements.Show}
                    />
                    <Route exact path={["/login"]} component={Auth.Login} />
                    <Route path={["*", "/oops"]} component={Errors.Error404} />
                  </Switch>
                </main>
              </MediaProvider>
            </RequireAuth>
          </Router>
        </AuthenticatedTemplate>
      </>
    </ProvideAuth>
  );
}

export default App;
