const canUseMatomo = () => {
  const { host } = window.location;
  // if we are on prod and _paq (matomo) exists
  return host === "app.pocketbook.nmrk.com" && window._paq;
};

// this takes care of most of the routes, except for things like
// requirements/:requirement-id etc
const pageTitles = {
  "/": "Buyers",
  "/mylistings": "My Listings",
  "/myrequirements": "My requirements",
  "/listings": "For Sale",
  "/requirements": "Buyers",
  "/requirements/create": "Add Requirement",
  "/listings/create": "Add Listing",
};

// Valid categories for tracked events
// see: https://dev.azure.com/nmrk-innovation/Innovation/_wiki/wikis/Innovation.wiki/89/Tracked-events-in-Matomo
const eventCategories = [
  "My Requirements",
  "My Listings",
  "Requirement Detail",
  "Listing Detail",
  "My Requirement Detail",
  "My Listing Detail",
];

const trackEvent = (category, action, name, value) => {
  // Category (Required):            This describes the type of events you want to track.
  //                                 For example, Link Clicks, Videos, Outbound Links, and Form Events.
  //                                 A value from eventCategories enum.
  // Action (Required):              This is the specific action that is taken. For example, with the Video
  //                                 category, you might have a Play, Pause and Complete action.
  // Name (Optional – Recommended):  This is usually the title of the element that is being interacted
  //                                 with, to aid with analysis. For example, it could be the name of a
  //                                 Video that was played or the specific form that is being submitted.
  // Value (Optional):               This is a numeric value and is often added dynamically. It could be the cost
  //                                 of a product that is added to a cart, or the completion percentage of a video.
  // the above is taken from: https://matomo.org/faq/reports/the-anatomy-of-an-event/
  const isValidCategory = eventCategories.includes(category);

  if (canUseMatomo() && isValidCategory) {
    const eventArray = value
      ? [category, action, name, value]
      : [category, action, name];
    window._paq.push(["trackEvent", ...eventArray]);
  }
  if (canUseMatomo() && !isValidCategory) {
    console.error(
      "Invalid event category:",
      category,
      "must be one of:",
      eventCategories
    );
  }
};

const trackPageView = (path) => {
  if (canUseMatomo()) {
    const pageTitle = pageTitles[path] || "Pocketbook";
    window._paq.push(["setCustomUrl", path]);
    window._paq.push(["setDocumentTitle", pageTitle]);
    window._paq.push(["trackPageView"]);
  }
};

const setUserEmail = (email) => {
  if (canUseMatomo()) {
    window._paq.push(["setUserId", email]);
  }
};

export { trackPageView, setUserEmail, trackEvent };
