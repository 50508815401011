import styled from "styled-components";
import { colors } from "~utilities/styles";

const themes = {
  primary: colors.primary,
  secondary: colors.secondary,
  error: colors.error,
  success: colors.success,
};

const StyledButton = styled.button`
  border: none;
  border-radius: 6px;
  color: ${colors.foreground};
  padding: 0.5rem 1.5rem;
  transition: 0.2s;

  &:disabled {
    background: ${colors.gray["200"]};
  }

  ${({ theme }) => `
    background: ${themes?.[theme] || themes.secondary};
  `}
`;

export default function Button({
  onClick,
  theme,
  disabled,
  buttonAttributes,
  children,
}) {
  return (
    <StyledButton {...{ onClick, theme, disabled }} {...buttonAttributes}>
      {children}
    </StyledButton>
  );
}
