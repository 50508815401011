import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import Modal from "~components/Modal";
import { Label } from "./utils";
import { getApiInstance } from "~utilities/api";
import Input from "~components/Input";
import { Formik, Form, Field } from "formik";
import TypeSelect from "~components/TypeSelect";
import { createListing } from "~components/listings/create/utils";
import AddressGeocodeField from "~components/AddressGeocodeField";
import Toggle from "~components/Toggle";
import Tooltip from "~components/Tooltip";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Comments from "~components/Comments";
import { getIntFromString } from "~utilities/format";
import { useMsal } from "@azure/msal-react";
import DatePickerField from "~components/DatePickerField";
import * as matomo from "../../../../matomo.js";
import { getISODateFromString } from "utilities/format";

const StyledTextarea = styled.textarea`
  border-radius: 3px;
  border: 1px solid ${colors.gray[200]};
  transition: 0.2s;
  ${({ errors, name }) => errors?.[name] && `border-color: ${colors.error}`}
`;

const isValueEmpty = (value) => {
  return value === "" || value === undefined;
};

const isValueWithInBoundaries = (value, min, max) => {
  const isValidValue =
    isValueEmpty(value) ||
    (Number.isInteger(value) && value >= min && value <= max);
  return isValidValue;
};

const validateForm = ({ name, capitalization_percent, lease_term }) => {
  let errors = {};
  if (!name) {
    errors.name = " ";
  }
  if (capitalization_percent > 100 || capitalization_percent < 0) {
    errors.capitalization_percent = "Cap rate must be between 0 and 100";
  }
  if (!isValueWithInBoundaries(lease_term, 1, 60)) {
    errors.lease_term =
      "Lease term must be between 1 and 60 years (whole numbers only)";
  }
  return errors;
};

const handleSubmit = async (
  requirement,
  values,
  files,
  commentBody,
  documentBlobs,
  msal,
  cb
) => {
  const formattedDate = getISODateFromString(values.expires_on);
  // Create Listing
  const formattedValues = {
    ...values,
    expires_on: formattedDate,
    price: getIntFromString(values.price),
  };
  const listing = await createListing(
    msal,
    formattedValues,
    files,
    (listing) => {
      return listing;
    }
  );
  // Create Opportunity
  const opportunity = await getApiInstance(
    msal,
    "/opportunities",
    { include: { listing: { user: true, image_urls: true } } },
    "post",
    {
      listing_id: listing.data.id,
      requirement_id: requirement.id,
    }
  );
  matomo.trackEvent(
    "Requirement Detail",
    "Create Opportunity with new listing",
    opportunity.data.id
  );
  // Create Comment
  const comment = await getApiInstance(
    msal,
    "/comments",
    {
      include: { document_urls: true, user: true },
    },
    "post",
    {
      comment: {
        opportunity_id: opportunity.data.id,
        user_id: listing.data.user_id,
        body: commentBody,
        documents: documentBlobs,
      },
    }
  );
  matomo.trackEvent(
    "Requirement Detail",
    "Create comment with opportunity",
    comment.data.id,
    comment.data.document_urls?.length || 0
  );
  // Stitch together the new opportunity to insert in the store
  const newOpportunity = {
    ...opportunity.data,
    requirement: requirement,
    comments: [comment.data],
  };
  cb(newOpportunity);
};

export default function CreateOpportunityModal({
  requirement,
  handleClose,
  dispatch,
}) {
  const msal = useMsal();
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);
  const [commentBody, setCommentBody] = useState();
  const cb = (opportunity) => {
    handleClose();
    dispatch({
      type: "addOpportunity",
      payload: { data: opportunity },
    });
  };
  return (
    <Modal
      {...{
        handleClose,
        title: "Create Opportunity",
        loading,
      }}
    >
      <div className="pb-5">
        <Formik
          initialValues={{
            name: "",
            price: 0,
            types: [],
            comment: "",
            is_private: true,
          }}
          validate={validateForm}
        >
          {(form) => {
            return (
              <Form>
                <div>
                  <div className="d-flex align-items-center p-4">
                    <h3 className="m-0 mr-2">Create A New Opportunity</h3>
                    <Tooltip
                      content={
                        <div>
                          Create a new opportunity. This will also create a new
                          listing and will kick off the conversation with the
                          buyer automatically.
                        </div>
                      }
                    />
                  </div>
                  <div className="px-4">
                    <FormGroup>
                      <Label>Name</Label>
                      <Input name="name" />
                    </FormGroup>
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <Label className="mr-2">Private Listing</Label>
                        <Tooltip
                          content={
                            <div>
                              Private listings will not show up for buyers,
                              unless you have shared it with them directly by
                              creating an opportunity on their requirement.
                            </div>
                          }
                        />
                      </div>
                      <Toggle name="is_private" />
                    </FormGroup>
                    <Field name="description">
                      {({ field }) => (
                        <StyledTextarea
                          className="p-3 w-100"
                          placeholder="Add a description..."
                          {...field}
                        ></StyledTextarea>
                      )}
                    </Field>
                    <FormGroup>
                      <Label>Price($)</Label>
                      <Input name="price" type="number" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Cap Rate(%)</Label>
                      <Input
                        name="capitalization_percent"
                        inputAttributes={{ type: "number" }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Lease Term(Years)</Label>
                      <Input
                        name="lease_term"
                        inputAttributes={{
                          type: "number",
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="d-flex align-items-center">
                        <span className="mr-1">Expiration Date</span>
                        <Tooltip
                          content={
                            <div>
                              Listings will not show up on the For Sale page or
                              as leads for requirements after they expire.
                            </div>
                          }
                        />
                      </Label>
                      <DatePickerField
                        name="expires_on"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Preferred Property Type(s)</Label>
                      <TypeSelect name="types" form={form} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Listing Address</Label>
                      <Field name="street">
                        {({ form }) => <AddressGeocodeField form={form} />}
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Label>Photo Upload</Label>
                      <input
                        type="file"
                        multiple={true}
                        onChange={(e) => {
                          setFiles(e.target.files);
                        }}
                      />
                    </FormGroup>
                    <Label>Comment</Label>
                    <Comments
                      onChange={setCommentBody}
                      value={commentBody}
                      disabledPredicate={() => {
                        return !form.isValid || !commentBody || !form.dirty;
                      }}
                      buttonText="Comment and Create Opportunity"
                      commentAction={(comment, documentBlobs) => {
                        setLoading(true);
                        return handleSubmit(
                          requirement,
                          form.values,
                          files,
                          comment,
                          documentBlobs,
                          msal,
                          cb
                        );
                      }}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
