export const defaultParams = {
  include: {
    user: true,
    leads: {
      regions: true,
      user: true,
      order: {
        created_at: "desc",
      },
    },
    opportunities: {
      requirement: {
        regions: true,
        user: true,
      },
      comments: {
        body: true,
        user: true,
        documents: true,
        document_urls: true,
        order: {
          created_at: "desc",
        },
      },
      order: {
        created_at: "desc",
      },
    },
    regions: true,
    address: true,
    image_urls: true,
  },
};
