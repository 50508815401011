import React from "react";
import Layout from "components/mine/Layout";
import { default as ListingsPage } from "components/mine/Listings";

export default function Listings() {
  return (
    <Layout>
      <ListingsPage />
    </Layout>
  );
}
