import React, { useEffect, useState } from "react";
import Input from "~components/Input";
import {
  Container,
  Badge,
  FormGroup,
  Label,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "styled-components";
import { Form, Field, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import FormPageLayout from "~components/FormPageLayout";
import Button from "~components/Button";
import DatePickerField from "~components/DatePickerField";
import RegionSelectField from "~components/RegionSelectField";
import { getApiInstance, getCachedRequest } from "~utilities/api";
import { useMsal } from "@azure/msal-react";
import { getIntFromString, formatLeaseTerm } from "~utilities/format";
import * as matomo from "../../../matomo.js";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import env from "~utilities/../env";
import StatesMapComponent from "components/map/StatesMapComponent.js";
import Loading from "~components/Loading";
import { centerOfUnitedStates, defaultMapZoom } from "utilities/map";
import {
  formatTypeWithTooltip,
  getISODateFromString,
} from "utilities/format.js";

const StyledBadge = styled(Badge)`
  font-size: 1.125em;
  padding: 0.5em;
  margin: 0 0.125em;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const validateForm = ({ max_price, min_price, min_capitalization_percent }) => {
  let errors = {};
  const max = getIntFromString(max_price);
  const min = getIntFromString(min_price);
  if (max < min) {
    errors.max_price = "Maximum price must be greater than minimum price.";
  }
  if (min < 0) {
    errors.min_price = "Minimum price must be greater than 0.";
  }
  if (min_capitalization_percent > 100 || min_capitalization_percent < 0) {
    errors.min_capitalization_percent =
      "Minimum cap rate must be between 0 and 100";
  }
  return errors;
};
const renderGoogleMapLoadingStatus = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

export default function Create() {
  const history = useHistory();
  const msal = useMsal();

  const [stateRegions, setStateRegions] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [isLeaseTermDropdownOpen, setIsLeaseTermDropdownOpen] = useState(false);
  const [leaseTerm, setLeaseTerm] = useState();

  useEffect(() => {
    async function fetch() {
      const params = {
        limit: 50 + 1 + 3, // +1 for the District of Columbia, +3 for guam, virgin islands, and mariana island
        include: {
          geometrygeojson: true,
        },
        where: {
          type: "state",
        },
      };
      const resource = "regions";
      const res = await getCachedRequest(msal, resource, params);
      setStateRegions(res);
    }
    fetch();
  }, [msal]);
  return (
    <Container className="my-3">
      <FormPageLayout
        initialValues={{
          min_price: 1_000_000,
          max_price: 1_000_000,
          types: [],
          region_ids: [],
          expires_on: undefined,
          note: undefined,
          name: "",
          min_capitalization_percent: undefined,
        }}
        validate={validateForm}
        headerText="New Requirement"
        onSubmit={async (values) => {
          const parsedMin = getIntFromString(values.min_price);
          const parsedMax = getIntFromString(values.max_price);
          const formattedDate = getISODateFromString(values.expires_on);
          const formattedValues = {
            ...values,
            expires_on: formattedDate,
            min_price: parsedMin,
            max_price: parsedMax,
          };
          const { data } = await getApiInstance(
            msal,
            "/requirements",
            {},
            "post",
            {
              requirement: formattedValues,
            }
          );
          // TODO: handle error e.g. 422
          matomo.trackEvent("My Requirements", "Create Requirement", data?.id);
          history.push("/");
        }}
      >
        {(form) => {
          return (
            <Form>
              <FormGroup>
                <Input
                  name="name"
                  label="Headline"
                  isRequired
                  placeholder="Add a brief headline about this requirement"
                />
              </FormGroup>
              <FormGroup>
                <Label>Preferred market(s)</Label>
                <RegionSelectField
                  form={form}
                  selectedRegions={selectedRegions}
                  setSelectedRegions={setSelectedRegions}
                />
                <Wrapper
                  className="h-100 d-inline-block"
                  apiKey={env.googleMapApiKey}
                  render={renderGoogleMapLoadingStatus}
                >
                  {!stateRegions ? (
                    <Loading />
                  ) : (
                    <StatesMapComponent
                      center={centerOfUnitedStates}
                      zoom={defaultMapZoom}
                      stateRegions={stateRegions}
                      selectedRegions={selectedRegions}
                      setSelectedRegions={setSelectedRegions}
                    />
                  )}
                </Wrapper>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <Input
                      name="min_price"
                      label="Minimum Price($)"
                      placeholder="Min Price"
                      type="number"
                    />
                  </Col>
                  <Col>
                    <Input
                      name="max_price"
                      label="Maximum Price($)"
                      placeholder="Max Price"
                      type="number"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <Input
                      name="min_capitalization_percent"
                      label="Minimum Cap Rate(%)"
                      placeholder="Min Cap Rate"
                      inputAttributes={{ type: "number" }}
                    />
                  </Col>
                  <Col>
                    <label
                      htmlFor="Minimum Lease Term(Years)"
                      className="form-label"
                    >
                      Minimum Lease Term(Years)
                    </label>
                    <Dropdown
                      isOpen={isLeaseTermDropdownOpen}
                      toggle={() => {
                        setIsLeaseTermDropdownOpen((prevState) => !prevState);
                      }}
                    >
                      {leaseTerm ? (
                        <DropdownToggle
                          tag="span"
                          caret={true}
                          className="form-control"
                        >
                          {leaseTerm}
                        </DropdownToggle>
                      ) : (
                        <DropdownToggle
                          tag="span"
                          caret={true}
                          className="form-control text-secondary"
                        >
                          Min Lease Term
                        </DropdownToggle>
                      )}
                      <DropdownMenu>
                        {[
                          {},
                          { min: 1, max: 5 },
                          { min: 6, max: 10 },
                          { min: 11, max: 15 },
                          { min: 16 },
                        ].map(({ min, max }) => {
                          const leaseTermOption = formatLeaseTerm({
                            min,
                            max,
                          });
                          return (
                            <DropdownItem
                              key={leaseTermOption}
                              onClick={() => {
                                form.setFieldValue(
                                  "minimum_lease_term",
                                  min || null
                                );
                                form.setFieldValue(
                                  "maximum_lease_term",
                                  max || null
                                );
                                setLeaseTerm(leaseTermOption);
                              }}
                            >
                              {leaseTermOption || "--"}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <FieldArray
                  name="types"
                  render={({ remove, push }) => {
                    const resetTypes = (e) => form.setFieldValue("types", []);
                    const toggleType = (e) => {
                      const type = e.target.value;
                      const index = form.values.types.findIndex(
                        (t) => t === type
                      );
                      return form.values.types.includes(type)
                        ? remove(index)
                        : push(type);
                    };
                    return (
                      <FormGroup>
                        <Label>Preferred property type(s)</Label>
                        <br />
                        <StyledBadge
                          color={
                            form.values.types.length === 0
                              ? "primary"
                              : "secondary"
                          }
                          className="mb-1 mr-1"
                        >
                          <Label className="text-capitalize font-weight-normal mb-0">
                            Anything
                            <input
                              className="sr-only"
                              type="checkbox"
                              onChange={resetTypes}
                            />
                          </Label>
                        </StyledBadge>
                        {[
                          "multifamily",
                          "office",
                          "retail",
                          "industrial",
                          "hospitality",
                          "medical",
                          "STNL",
                        ].map((type, i) => (
                          <div key={i} className="d-inline-block mb-1 mr-1">
                            <StyledBadge
                              color={
                                form.values.types.includes(type)
                                  ? "primary"
                                  : "secondary"
                              }
                            >
                              <Label className="text-capitalize font-weight-normal mb-0">
                                {formatTypeWithTooltip(type)}
                                <input
                                  className="sr-only"
                                  type="checkbox"
                                  onChange={toggleType}
                                  value={type}
                                />
                              </Label>
                            </StyledBadge>
                          </div>
                        ))}
                      </FormGroup>
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Expiration Date</Label>
                <DatePickerField name="expires_on" className="form-control" />
              </FormGroup>
              <FormGroup>
                <Label>Note</Label>
                <Field
                  placeholder="Provide any other information relevant to this requirement"
                  as="textarea"
                  name="note"
                  className="form-control"
                />
              </FormGroup>
              <Button
                buttonAttributes={{ type: "submit" }}
                theme="primary"
                disabled={!form.isValid || !form.dirty}
                onClick={() => {
                  form.setFieldValue(
                    "region_ids",
                    selectedRegions.map((choice) => choice.id)
                  );
                }}
              >
                Submit
              </Button>
            </Form>
          );
        }}
      </FormPageLayout>
    </Container>
  );
}
