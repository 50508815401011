import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getApiInstance } from "~utilities/api";
import { useMsal } from "@azure/msal-react";

export default function AddressGeocodeField({ form, typeaheadProps }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const msal = useMsal();

  const debounceSearch = async (query) => {
    setIsLoading(true);
    if (query.length >= 3) {
      const { data } = await getApiInstance(msal, "/listings/geocode", {
        where: {
          q: query,
        },
      });
      setOptions(data);
    }
    setIsLoading(false);
  };

  const onChange = (suggestions) => {
    if (!suggestions) return;
    const choice = suggestions[0];
    form.setFieldValue("building_number", choice?.building_number);
    form.setFieldValue("street_address", choice?.street_address);
    form.setFieldValue("city", choice?.city);
    form.setFieldValue("state", choice?.state);
    form.setFieldValue("postal_code", choice?.postal_code);
  };
  return (
    <AsyncTypeahead
      filterBy={() => true} // Bypass client-side filtering by returning `true`
      id="address-geocode"
      labelKey={(o) =>
        `${o.building_number} ${o.street_address}, ${o.city}, ${o.state} ${o.postal_code}`
      }
      options={options}
      onSearch={debounceSearch}
      placeholder="Search for an address"
      isLoading={isLoading}
      onChange={onChange}
      renderMenuItemChildren={(o, props) => (
        <span>
          {o.building_number} {o.street_address}, {o.city}, {o.state}{" "}
          {o.postal_code}
        </span>
      )}
      {...typeaheadProps}
    />
  );
}
