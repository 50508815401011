import { getApiInstance } from "~utilities/api";
import styled from "styled-components";
import * as matomo from "../../../../matomo.js";

export const placeholder = "Unspecified";

const StyledLabel = styled.section`
  font-size: 0.9rem;
`;

export const Label = ({ className, children }) => {
  return (
    <StyledLabel className={className}>
      <strong>{children}</strong>
    </StyledLabel>
  );
};

export const DisplayText = ({ text }) => {
  return (
    <p className="truncate" title={text}>
      {text || placeholder}
    </p>
  );
};

export const createOpportunity = async ({
  listing,
  requirementId,
  msal,
  dispatch,
  isBuyer,
}) => {
  const vars = {
    listing_id: listing.id,
    requirement_id: requirementId,
  };
  const { data } = await getApiInstance(
    msal,
    "/opportunities",
    {},
    "post",
    vars
  );
  const opportunity = { ...data, listing };
  matomo.trackEvent(
    isBuyer ? "My Requirement Detail" : "Requirement Detail",
    "Create Opportunity",
    data?.id
  );
  dispatch({ type: "addOpportunity", payload: { data: opportunity } });
};

const toggleOpportunityActive = async ({ id, is_active }, msal, dispatch) => {
  const { data } = await getApiInstance(
    msal,
    `/opportunities/${id}/set_active`,
    {},
    "post",
    { is_active: !is_active }
  );
  matomo.trackEvent(
    "My Requirement Detail",
    `${!is_active ? "Open" : "Close"} Opportunity`,
    data?.id
  );
  dispatch({ type: "updateOpportunity", payload: data });
};

const getListingActions = (listing, requirement, msal, dispatch, isBuyer) => {
  return [
    {
      text: "Create Opportunity",
      action: () => {
        createOpportunity({
          listing: listing,
          requirementId: requirement.id,
          msal,
          dispatch,
          isBuyer,
        });
      },
    },
  ];
};

export const getActions = (card, requirement, currentUser, msal, dispatch) => {
  const isOpportunity = card.requirement_id;
  const toggleAction = isOpportunity && {
    text: card.is_active ? "Close" : "Open",
    action: () => toggleOpportunityActive(card, msal, dispatch),
  };
  const showToggle = isOpportunity;
  const baseActions =
    !isOpportunity &&
    requirement.is_open &&
    requirement.is_active &&
    card.is_active
      ? getListingActions(
          card,
          requirement,
          msal,
          dispatch,
          requirement.user_id === currentUser.id
        )
      : [];
  if (showToggle) {
    return [toggleAction, ...baseActions];
  }
  return baseActions;
};
