import React from "react";
import { Field, ErrorMessage } from "formik";
import { colors } from "~utilities/styles";
import styled from "styled-components";
import { getNumericString } from "~utilities/format";

const StyledInput = styled.input`
  border: ${(props) => props.border || `1px solid ${colors.gray["200"]}`};
`;

const StyledSpan = styled.span`
  color: ${colors.error};
`;

const formatters = {
  number: (str) => {
    const numericString = getNumericString(str);
    const formattedString = numericString.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      "$1,"
    );
    return formattedString;
  },
};

const isRequiredValidation = (value) => {
  if (!value) {
    return "Required Field!";
  }
  return;
};

export default function Input({
  name,
  label,
  placeholder,
  validate,
  isRequired,
  isDisabled,
  inputAttributes,
  type,
  styles,
}) {
  const validation = validate ? validate : isRequiredValidation;
  const formatter = type && formatters[type];
  return (
    <Field name={name} validate={(isRequired || validate) && validation}>
      {({ field, form: { touched, errors }, meta }) => {
        const value =
          typeof formatter === "function"
            ? formatter(field.value)
            : field.value;
        return (
          <div>
            {(label || isRequired) && (
              <label htmlFor={name} className="form-label">
                {label}
                {isRequired && <StyledSpan> * </StyledSpan>}
              </label>
            )}
            <StyledInput
              border={errors[name] && `1px solid  ${colors.error}`}
              className="form-control"
              placeholder={placeholder}
              disabled={isDisabled}
              {...inputAttributes}
              {...styles}
              {...field}
              value={value}
            />
            <ErrorMessage name={name}>
              {(msg) => <StyledSpan>{msg}</StyledSpan>}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
}
