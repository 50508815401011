import React from "react";
import { formatTypeWithTooltip } from "utilities/format";

export default function CapitalizedList({ items }) {
  return (
    <div className="d-flex flex-column ">
      {items.map((item, i) => {
        return (
          <div key={i} className="text-capitalize truncate mr-1 my-0">
            {formatTypeWithTooltip(item)}
          </div>
        );
      })}
    </div>
  );
}
