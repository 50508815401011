import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors, zIndex } from "~utilities/styles";

const StyledItem = styled.li`
  width: 180px;

  hr {
    border-width: 6px;
    margin: 0 0 -1px 0;
    transition: 0.2s ease-in-out;
    transform-origin: bottom left;
    transform: scaleY(0);
    border-color: ${colors.primary};
  }

  ${(props) =>
    props.active && `color: ${colors.primary}; hr {transform: scaleY(1);}`}
`;

const StyledNav = styled.nav`
  z-index: ${zIndex.base};

  ul {
    list-style-type: none;
    padding: 0 0;
    display: flex;
    margin: 0;
  }
  li {
    margin: 0 10px;
    font-size: 1.1em;
    position: relative;
    text-align: center;
    a {
      text-decoration: none;
      color: inherit;

      ${({ expanded }) => {
        return expanded ? "padding: 18px 0;" : "padding: 12px 0;";
      }}

      &:hover {
        color: ${colors.primary};
      }
    }
  }
`;

export default function TabNavgation({ tabs, expanded }) {
  // Tabs is an array of tab objects containing the following keys:
  // path: String for the tab's link path
  // title: String for the tab display text
  // expanded: Boolean, does this navigation have extra padding?

  const match = useRouteMatch();
  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    setActiveLink(match.url);
  }, [match]);

  return (
    <StyledNav expanded={expanded}>
      <ul>
        {tabs.map((tab, i) => (
          <StyledItem key={i} active={activeLink === tab.path}>
            <Link
              className={`nav-link text-capitalize${
                activeLink === tab.path ? " active" : ""
              }`}
              to={tab.path}
            >
              {tab.title}
            </Link>
            <hr />
          </StyledItem>
        ))}
      </ul>
    </StyledNav>
  );
}
