import React from "react";
import styled, { css } from "styled-components";
import { colors } from "~utilities/styles";

const thumbnailSize = 150;

const StyledThumbnail = styled.div`
  height: ${thumbnailSize}px;
  width: ${thumbnailSize}px;
  background-color: ${colors.background};
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ url }) =>
    url &&
    css`
      background-image: url("${url}");
    `}
`;

export default function Thumbnail({ imageUrl }) {
  return (
    <StyledThumbnail url={imageUrl}>{!imageUrl && "No Image"}</StyledThumbnail>
  );
}
