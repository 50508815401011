import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import { useAuth } from "../../../providers/auth";
import { Formik, Form } from "formik";
import Input from "../../../components/Input";
import { colors } from "../../../utilities/styles";
import styled from "styled-components";
import AuthForm from "../components/AuthForm";

const FormError = styled.div`
  color: ${colors.error};
`;

const validate = ({ password, confirmation }) => {
  let errors = {};
  if (!password) {
    errors.password = " ";
  }
  if (password !== confirmation) {
    errors.confirmation = "Your passwords do not match.";
  }
  if (!confirmation) {
    errors.confirmation = " ";
  }
  return errors;
};

export default function ResetEmail() {
  const auth = useAuth();
  const history = useHistory();
  const { search } = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const token = params.get("reset_token");
  const [error, setError] = useState("");

  async function handleSubmit({ password, confirmation }) {
    const response = await auth.resetPassword(token, password, confirmation);
    if (!response || response.error) {
      setError("Something went wrong. Review the form and try again.");
      return;
    }
    // TODO: toast/alert a "password changed successfully" message
    history.push("/login");
  }

  return (
    <AuthForm title="New Password">
      <Formik
        initialValues={{
          password: "",
          confirmation: "",
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(form) => {
          return (
            <Form>
              <FormGroup size="lg" id="passwordReset">
                <Input
                  autoFocus
                  type="password"
                  name="password"
                  label="New Password"
                />
              </FormGroup>
              <FormGroup size="lg" id="passwordReset">
                <Input
                  autoFocus
                  type="password"
                  name="confirmation"
                  label="Confirm Password"
                />
              </FormGroup>
              <Button
                block
                className="mb-3"
                size="lg"
                type="submit"
                disabled={!form.isValid || !form.dirty}
              >
                Set New Password
              </Button>
              {error && <FormError className="my-2">{error}</FormError>}
              {auth.user ? (
                <Link to="/">Back to Home</Link>
              ) : (
                <Link to="/login">Back to Login</Link>
              )}
            </Form>
          );
        }}
      </Formik>
    </AuthForm>
  );
}
