import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Modal from "~components/Modal";
import { Formik, Form, Field } from "formik";
import RegionSelectField from "~components/RegionSelectField";
import { getApiInstance, getCachedRequest } from "utilities/api";
import { useMsal } from "@azure/msal-react";
import DatePickerField from "~components/DatePickerField";
import { getIntFromString } from "~utilities/format";
import Input from "~components/Input";
import Button from "~components/Button";
import TypeSelect from "~components/TypeSelect";
import { formatDate, formatLeaseTerm } from "~utilities/format";
import * as matomo from "../../../matomo.js";
import Loading from "~components/Loading";
import StatesMapComponent from "components/map/StatesMapComponent";
import { centerOfUnitedStates, defaultMapZoom } from "utilities/map";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import env from "~utilities/../env";
import { defaultParams } from "pages/requirements/requirementsDefaultConfigs.js";
import { getISODateFromString } from "utilities/format.js";

// Copy from src/pages/requirements/create/index.js
const isValueEmpty = (value) => {
  return value === "" || value === undefined;
};

const isValueWithinBoundaries = (value, min, max) => {
  const isValueValid =
    isValueEmpty(value) ||
    (Number.isInteger(value) && value >= min && value <= max);
  return isValueValid;
};

const validateForm = ({
  max_price,
  min_price,
  min_capitalization_percent,
  lease_term,
}) => {
  let errors = {};
  const max = getIntFromString(max_price);
  const min = getIntFromString(min_price);
  if (max < min) {
    errors.max_price = "Maximum price must be greater than minimum price.";
  }
  if (min < 0) {
    errors.min_price = "Minimum price must be greater than 0.";
  }
  if (min_capitalization_percent > 100 || min_capitalization_percent < 0) {
    errors.min_capitalization_percent =
      "Minimum cap rate must be between 0 and 100";
  }
  if (!isValueWithinBoundaries(lease_term, 1, 60)) {
    errors.lease_term =
      "Lease term must be between 1 and 60 years (whole numbers only)";
  }
  return errors;
};
const renderGoogleMapLoadingStatus = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

export default function EditModal({ requirement, handleClose, dispatch }) {
  const [loading, setLoading] = useState(false);
  const msal = useMsal();
  const [stateRegions, setStateRegions] = useState(null);
  const [isLeaseTermDropdownOpen, setIsLeaseTermDropdownOpen] = useState(false);
  const [leaseTerm, setLeaseTerm] = useState(
    formatLeaseTerm({
      min: requirement.minimum_lease_term,
      max: requirement.maximum_lease_term,
    })
  );
  useEffect(() => {
    async function fetch() {
      const params = {
        limit: 50 + 1 + 3, // +1 for the District of Columbia, +3 for guam, virgin islands, and mariana island
        include: {
          geometrygeojson: true,
        },
        where: {
          type: "state",
        },
      };
      const resource = "regions";
      const res = await getCachedRequest(msal, resource, params);
      setStateRegions(res);
    }
    fetch();
  }, [msal]);
  const [selectedRegions, setSelectedRegions] = useState(
    requirement.regions ?? []
  );

  if (!Object.keys(requirement).length) return <h1>Loading</h1>;

  return (
    <Modal
      {...{
        handleClose,
        title: "Edit Requirement",
        loading,
      }}
    >
      <div className="pb-5">
        <Formik
          initialValues={{
            ...requirement,
            ...{
              note: requirement.note || "",
              min_capitalization_percent:
                requirement.min_capitalization_percent || "",
              expires_on: requirement.expires_on
                ? formatDate(requirement.expires_on)
                : "",
            },
          }}
          validate={validateForm}
          onSubmit={async (values) => {
            setLoading(true);
            const formattedDate = getISODateFromString(values.expires_on);
            const updatedRequirement = await getApiInstance(
              msal,
              `/requirements/${requirement.id}`,
              {
                include: defaultParams.include,
              },
              "put",
              {
                requirement: {
                  ...values,
                  expires_on: formattedDate,
                  region_ids: selectedRegions.map((r) => r.id),
                  max_price: getIntFromString(values.max_price),
                  min_price: getIntFromString(values.min_price),
                },
              }
            );
            setLoading(false);
            matomo.trackEvent(
              "My Requirement Detail",
              "Edit Requirement",
              updatedRequirement.data.id
            );
            // TODO: handle error e.g. 422
            handleClose();
            if (dispatch) {
              dispatch({
                type: "updateRequirement",
                payload: updatedRequirement.data,
              });
            }
          }}
        >
          {(form) => {
            return (
              <div className="d-flex align-items-center p-4">
                <Form>
                  <FormGroup>
                    <Input
                      name="name"
                      label="Headline"
                      type="text"
                      isRequired
                      placeholder="Add a brief headline about this requirement"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Preferred market(s)</Label>
                    <RegionSelectField
                      form={form}
                      selectedRegions={selectedRegions}
                      setSelectedRegions={setSelectedRegions}
                    />
                    <Wrapper
                      className="h-100 d-inline-block"
                      apiKey={env.googleMapApiKey}
                      render={renderGoogleMapLoadingStatus}
                    >
                      {!stateRegions ? (
                        <Loading />
                      ) : (
                        <StatesMapComponent
                          center={centerOfUnitedStates}
                          zoom={defaultMapZoom}
                          stateRegions={stateRegions}
                          selectedRegions={selectedRegions}
                          setSelectedRegions={setSelectedRegions}
                        />
                      )}
                    </Wrapper>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Minimum Price($)</Label>
                        <Input name="min_price" type="number" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Maximum Price</Label>
                        <Input name="max_price" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col>
                        <Input
                          name="min_capitalization_percent"
                          label="Minimum Cap Rate(%)"
                          inputAttributes={{ type: "number" }}
                        />
                      </Col>
                      <Col>
                        <label
                          htmlFor="Minimum Lease Term(Years)"
                          className="form-label"
                        >
                          Minimum Lease Term(Years)
                        </label>
                        <Dropdown
                          isOpen={isLeaseTermDropdownOpen}
                          toggle={() => {
                            setIsLeaseTermDropdownOpen(
                              (prevState) => !prevState
                            );
                          }}
                        >
                          {leaseTerm ? (
                            <DropdownToggle
                              tag="span"
                              caret={true}
                              className="form-control"
                            >
                              {leaseTerm}
                            </DropdownToggle>
                          ) : (
                            <DropdownToggle
                              tag="span"
                              caret={true}
                              className="form-control text-secondary"
                            >
                              Min Lease Term
                            </DropdownToggle>
                          )}
                          <DropdownMenu>
                            {[
                              {},
                              { min: 1, max: 5 },
                              { min: 6, max: 10 },
                              { min: 11, max: 15 },
                              { min: 16 },
                            ].map(({ min, max }) => {
                              const leaseTermOption = formatLeaseTerm({
                                min,
                                max,
                              });
                              return (
                                <DropdownItem
                                  key={leaseTermOption}
                                  onClick={() => {
                                    form.setFieldValue(
                                      "minimum_lease_term",
                                      min || null
                                    );
                                    form.setFieldValue(
                                      "maximum_lease_term",
                                      max || null
                                    );
                                    setLeaseTerm(leaseTermOption);
                                  }}
                                >
                                  {leaseTermOption || "--"}
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </FormGroup>
                  <TypeSelect
                    name="types"
                    form={form}
                    label="Preferred property type(s)"
                  />
                  <FormGroup>
                    <Label>Expiration Date</Label>
                    <DatePickerField
                      name="expires_on"
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Note</Label>
                    <Field
                      as="textarea"
                      name="note"
                      className="form-control"
                      value={form.values.note}
                      placeholder="Provide any other information relevant to this requirement"
                    />
                  </FormGroup>
                  <Button
                    theme="primary"
                    disabled={
                      !form.isValid ||
                      !(
                        // order important here for short-circuit
                        (
                          requirement.regions.map((r) => r.id).join(",") !==
                            selectedRegions.map((r) => r.id).join(",") ||
                          form.dirty
                        )
                      )
                    }
                    buttonAttributes={{ type: "submit" }}
                    onClick={() => {
                      form.setFieldValue(
                        "region_ids",
                        selectedRegions.map((choice) => choice.id)
                      );
                    }}
                  >
                    Save Changes
                  </Button>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
