import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useListProvider } from "~providers/list";
import { getApiInstance, useApi } from "~utilities/api";
import Table, { DateCell, TitleCell } from "../Table";
import Loading from "../Loading";
import styled from "styled-components";
import { useAuth } from "../../providers/auth";
import { colors } from "~utilities/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableNav, { useQuery } from "./TableNav";
import { useMsal } from "@azure/msal-react";
import { formatPercent } from "~utilities/format";
import BadgeList from "../BadgeList";

const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 900;
  padding: 0.5rem 0;
`;

const cols = [
  {
    id: "name",
    Header: "Name",
    accessor: ({ name }) => {
      return name && name.toLowerCase();
    },
    Cell: ({ row }) => <TitleCell value={row.original.name} />,
  },
  {
    id: "capitalization_percent",
    Header: "Cap Rate",
    accessor: ({ capitalization_percent }) => {
      return formatPercent(capitalization_percent, 3);
    },
  },
  {
    id: "types",
    Header: "Property Type",
    accessor: (requirement) => {
      return requirement.types.length > 1
        ? requirement.types.length
        : requirement.types?.[0];
    },
    Cell: ({ row }) => {
      const { types } = row.original;
      return <BadgeList items={types} truncateList />;
    },
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    id: "is_private",
    Header: "Private",
    columnWidth: "80px",
    centerContent: true,
    accessor: ({ is_private }) => {
      return Number(is_private);
    },
    Cell: ({ value }) => {
      return (
        <span>
          {value ? (
            <FontAwesomeIcon
              icon="eye-slash"
              size={"1x"}
              color={colors.gray["500"]}
            />
          ) : (
            ""
          )}
        </span>
      );
    },
  },
  {
    Header: "Expires on",
    accessor: "expires_on",
    Cell: DateCell,
  },
];

const ZeroState = ({ hasListings, filter }) => {
  const copyObject = {
    inbox: " with an opportunity waiting for your response.",
    awaiting: " with an opportunity waiting for a response.",
    unproductive: " without opportunities.",
  };
  if (!filter || !hasListings) {
    return (
      <div className="pt-3">
        You have no listings.
        <Link to="/listings/create"> Create a listing</Link>.
      </div>
    );
  }
  return <div className="pt-3">You have no listings{copyObject[filter]}</div>;
};

const hasNewComment = ({ user_id }, opportunities) => {
  return opportunities.find(({ comments }) => {
    return comments.length && comments[0]?.user_id !== user_id;
  });
};

const filterItems = [
  { label: "All", navKey: null },
  { label: "Inbox", navKey: "inbox" },
  { label: "Awaiting Response", navKey: "awaiting" },
  { label: "Unproductive Listings", navKey: "unproductive" },
];

const filters = {
  inbox: hasNewComment,
  awaiting: (lisitng, opportunities) => {
    return !hasNewComment(lisitng, opportunities) && opportunities.length;
  },
  unproductive: (_, opportunities) => {
    return !opportunities.length;
  },
};

const filterData = (listings, opportunities, filterKey, cb) => {
  const filterFn = filters[filterKey];
  if (filterKey) {
    return listings.reduce((acc, listing) => {
      if (
        filterFn(
          listing,
          opportunities.filter((o) => o.listing_id === listing.id)
        )
      ) {
        return [...acc, listing];
      }
      return acc;
    }, []);
  }
  return listings;
};

export default function Listings() {
  const { user } = useAuth();
  const { state, dispatch } = useListProvider();
  const columns = React.useMemo(() => cols, []);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [opportunitiesResponse] = useApi("/opportunities", {
    limit: 1000,
    include: {
      comments: true,
      requirement: true,
    },
    where: {
      listing: { user_id: user.id },
    },
  });
  const filterKey = useQuery().get("filter");
  const msal = useMsal();
  useEffect(() => {
    async function fetch() {
      const params = {
        limit: 1000,
        include: {
          address: true,
          regions: true,
          image_urls: true,
        },
        order: {
          created_at: "desc",
        },
        where: {
          user: {
            email: user.email,
          },
        },
      };
      const response = await getApiInstance(msal, "/listings", params);
      setListings(response.data);
      setLoading(false);
    }
    fetch();
  }, [state.params, dispatch, user.email, msal]);
  const tableData = React.useMemo(() => {
    if (!opportunitiesResponse.loading) {
      return filterData(listings, opportunitiesResponse.data, filterKey);
    }
    return [];
  }, [listings, opportunitiesResponse, filterKey]);
  const tableHeight = "calc(100vh - 225px)";
  if (loading || opportunitiesResponse.loading) {
    return <Loading />;
  }
  return (
    <>
      <PageTitle className="py-4">My Listings</PageTitle>
      <Row className="pb-3">
        <Col md="3" lg="2">
          <TableNav
            urlPath="mylistings"
            navItems={filterItems}
            tableHeight={tableHeight}
          />
        </Col>
        <Col md="9" lg="10">
          {tableData.length ? (
            <Table
              resourcePath="mylistings"
              columns={columns}
              data={tableData}
              tableHeight={tableHeight}
            />
          ) : (
            <ZeroState hasListings={listings.length} filter={filterKey} />
          )}
        </Col>
      </Row>
    </>
  );
}
