import { createContext, useContext, useState } from "react";
import { useApi } from "~utilities/api";

const ShowContext = createContext({
  config: {
    params: {},
    setParams: () => {},
  },
});

export const ShowProvider = ({
  resourcePath,
  defaultParams,
  store,
  dispatch,
  children,
}) => {
  const [params, setParams] = useState(defaultParams);
  const request = useApi(`/${resourcePath}`, params);
  const value = {
    config: {
      params,
      setParams,
    },
    request,
    store,
    dispatch,
  };
  return <ShowContext.Provider value={value}>{children}</ShowContext.Provider>;
};

export const useShowProvider = () => useContext(ShowContext);
