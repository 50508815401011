import { createContext, useContext, useMemo } from "react";

import useListReducer from "~reducers/list";

const ListContext = createContext({
  state: {},
  dispatch: () => {},
});

export const ListProvider = ({ initialState, children }) => {
  // config and status
  const [state, dispatch] = useListReducer(initialState);

  const context = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <ListContext.Provider value={context}>{children}</ListContext.Provider>
  );
};

export const useListProvider = () => useContext(ListContext);
