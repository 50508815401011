import React, { useCallback } from "react";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import RegionFilterSelect from "~components/RegionFilterSelect";
import TypeFilterSelect from "~components/TypeFilterSelect";
import { useListProvider } from "~providers/list";
import { Label } from "reactstrap";
import { useAuth } from "~providers/auth";
import { useLocation } from "react-router-dom";
import Tooltip from "~components/Tooltip";
import Checkbox from "react-custom-checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const StyledNav = styled.nav`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid ${colors.gray["200"]};
  ul {
    list-style-type: none;
  }
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  background-color: ${colors.success};
  color: ${colors.foreground};
  overflow: "hidden";
`;
const StyledLabel = styled(Label)`
  cursor: pointer;
`;

const StyledItem = styled.li`
  margin-left: 7px;
  ${(props) => props.active && `font-weight: 700; margin-left: 0;`}
`;

export const defaultFilters = {
  user_id: true, // includeMineSelected === true
  is_active: [true], // isActiveSelected === true && isInactiveSelected === false case
};

export default function FilterSidebar() {
  const { state, dispatch } = useListProvider();
  const { user } = useAuth();
  const includeMineSelected = !state.params.where?.user_id?.not;
  const [activeSelected, setActiveSelected] = React.useState(true);
  const [inactiveSelected, setInactiveSelected] = React.useState(false);
  const location = useLocation();
  const itemType = location.pathname.includes("requirements")
    ? "Requirements"
    : location.pathname.includes("listings")
    ? "Listings"
    : "Requirements"; // no endpoint or '/' displays requirements page
  const checkboxText = "Include My " + itemType;

  const handleSelectChange = useCallback(
    (checked, name) => {
      let additionalCondition = {};
      if (name.startsWith("Include My")) {
        additionalCondition = { user_id: checked || { not: user.id } };
      } else if (name === "Active" || name === "Inactive") {
        const isActiveSelected = name === "Active" ? checked : activeSelected;
        const isInactiveSelected =
          name === "Inactive" ? checked : inactiveSelected;

        if (isActiveSelected && isInactiveSelected) {
          additionalCondition = { is_active: [true, false] };
        } else if (isActiveSelected && !isInactiveSelected) {
          additionalCondition = { is_active: [true] };
        } else if (!isActiveSelected && isInactiveSelected) {
          additionalCondition = { is_active: [false] };
        } else if (!isActiveSelected && !isInactiveSelected) {
          additionalCondition = { is_active: "" };
        }

        setActiveSelected(isActiveSelected);
        setInactiveSelected(isInactiveSelected);
      }

      dispatch({
        type: "UPDATE_PARAMS",
        params: {
          where: {
            ...state.params.where,
            ...additionalCondition,
          },
        },
      });
    },
    [state.params.where, dispatch, user.id, activeSelected, inactiveSelected]
  );

  React.useEffect(() => {
    // Default filter selection
    dispatch({
      type: "UPDATE_PARAMS",
      params: {
        where: {
          ...state.params.where,
          ...defaultFilters,
        },
      },
    });
  }, []);
  return (
    <StyledNav>
      <h4 className="p-3 border-bottom">Filter By</h4>
      <ul className="px-3 border-bottom">
        <StyledItem className="d-flex align-items-center">
          <StyledLabel className="text-capitalize font-weight-normal mb-0 d-flex">
            <Checkbox
              className="mr-1"
              name={checkboxText}
              checked={includeMineSelected}
              onChange={(e) => handleSelectChange(e, checkboxText)}
              borderRadius="3px"
              icon={<StyledFontAwesomeIcon icon={faCheck} />}
              borderColor={colors.secondary}
            />
            {checkboxText}
          </StyledLabel>
          <br />
        </StyledItem>
      </ul>
      <ul className="px-3 py-2 border-bottom">
        <TypeFilterSelect />
      </ul>
      <ul className="px-3 py-2 border-bottom">
        <h5>Regions</h5>
        <RegionFilterSelect />
      </ul>
      <ul className="px-3 py-2">
        <h5>Status</h5>
        <StyledItem className="d-flex align-items-center">
          <StyledLabel className="text-capitalize font-weight-normal mb-0 d-flex">
            <Checkbox
              className="mr-1"
              name="Active"
              checked={activeSelected}
              onChange={(e) => handleSelectChange(e, "Active")}
              borderRadius="3px"
              icon={<StyledFontAwesomeIcon icon={faCheck} />}
              borderColor={colors.secondary}
            />
            Active
            <Tooltip
              cssEmbed={true}
              content={
                <span>
                  Include your active {itemType.toLocaleLowerCase()} and others'
                  active {itemType.toLocaleLowerCase()}
                </span>
              }
            />
          </StyledLabel>
          <br />
        </StyledItem>
        <StyledItem className="d-flex align-items-center">
          <StyledLabel className="text-capitalize font-weight-normal mb-0 d-flex">
            <Checkbox
              className="mr-1"
              name="Inactive"
              checked={inactiveSelected}
              onChange={(e) => handleSelectChange(e, "Inactive")}
              borderRadius="3px"
              icon={<StyledFontAwesomeIcon icon={faCheck} />}
              borderColor={colors.secondary}
            />
            Inactive
            <Tooltip
              cssEmbed={true}
              content={
                <span>
                  Include your deactivated {itemType.toLocaleLowerCase()} (but
                  not others' deactivated {itemType.toLocaleLowerCase()})
                </span>
              }
            />
          </StyledLabel>
          <br />
        </StyledItem>
      </ul>
    </StyledNav>
  );
}
