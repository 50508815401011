import { useReducer } from "react";
import merge from "~utilities/merge";

export const ViewSelection = {
  Grid: "Grid",
  List: "List",
  Map: "Map",
};

const listReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PARAMS":
      return {
        ...state,
        params: { ...state.params, ...action.params },
      };
    case "UPDATE_METADATA":
      const metadata = merge({}, state.metadata, action.metadata);
      return {
        ...state,
        metadata: { ...metadata },
      };
    case "UPDATE_DISPLAY_FILTER":
      return {
        ...state,
        displayFilter: action.payload,
      };
    case "UPDATE_VIEW_SELECTION":
      return {
        ...state,
        viewSelection: action.payload,
      };
    default:
      return state;
  }
};

export default function useListReducer(initialState) {
  return useReducer(listReducer, initialState);
}
