import { Button } from "reactstrap";
import Popover from "~components/Popover";

export default function FilterButton({
  setFilterState,
  filterState,
  additionalOptions,
}) {
  return (
    <Popover
      width={150}
      items={[
        {
          text: "All",
          action: () => {
            setFilterState("all");
          },
        },
        {
          text: "Open",
          action: () => {
            setFilterState("open");
          },
        },
        {
          text: "New Messages",
          action: () => {
            setFilterState("newMessages");
          },
        },
        {
          text: "Closed",
          action: () => {
            setFilterState("closed");
          },
        },
        ...additionalOptions,
      ]}
      tippyProps={{ offset: [0, 0], placement: "bottom" }}
    >
      <Button outline color="secondary text-capitalize">
        Filter: {filterState === "newMessages" ? "New Messages" : filterState}
      </Button>
    </Popover>
  );
}
