import React from "react";
import { useAuth } from "../../providers/auth";
import { Row, Col } from "reactstrap";
import { useApi } from "~utilities/api";
import Table, { DateCell, TitleCell } from "../Table";
import Loading from "../Loading";
import TableNav, { useQuery } from "./TableNav";
import styled from "styled-components";

const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 900;
  padding: 0.5rem 0;
`;

const filterItems = [
  { label: "All", navKey: null },
  { label: "Inbox", navKey: "inbox" },
  { label: "Awaiting response", navKey: "awaiting" },
  { label: "Archived", navKey: "archived" },
];

const cols = [
  {
    Header: "Requirement",
    accessor: ({ requirement }) => {
      return requirement.name;
    },
    Cell: TitleCell,
  },
  {
    Header: "Listing",
    accessor: ({ listing }) => {
      return listing.name;
    },
  },
  {
    Header: "Stage",
    accessor: "stage",
  },
  {
    Header: "Created at",
    accessor: "created_at",
    Cell: DateCell,
  },
  {
    Header: "Updated at",
    accessor: "updated_at",
    Cell: DateCell,
  },
];

const ZeroState = ({ filterKey }) => {
  const copyObject = {
    inbox: " opportunities that need a reply.",
    awaiting: " opportunities that are waiting a response.",
    archived: " archived opportunities.",
  };
  const copy = filterKey ? copyObject[filterKey] : " opportunities.";
  return <div className="pt-3">You have no {copy}</div>;
};

export default function Opportunities() {
  const columns = React.useMemo(() => cols, []);
  const { user } = useAuth();
  const [{ loading, data = [] }] = useApi("/opportunities.json", {
    limit: 1000,
    include: {
      listing: true,
      requirement: true,
      comments: {
        user: true,
      },
    },
  });
  const filterKey = useQuery().get("filter");
  const filter = filterItems.reduce((acc, { navKey, label }) => {
    return navKey === filterKey ? label : acc;
  }, "All");
  const tableData = React.useMemo(() => {
    const filters = {
      archived: ({ stage }) => {
        return stage === "archived";
      },
      inbox: ({ comments }) => {
        return comments.length && comments[0]?.user.email !== user.email;
      },
      awaiting: ({ comments }) => {
        return comments.length && comments[0]?.user.email === user.email;
      },
    };
    const filterFunction = filters[filterKey];
    const processedData = filterFunction ? data.filter(filterFunction) : data;
    return processedData;
  }, [data, filterKey, user.email]);
  if (loading) {
    return <Loading />;
  }
  const tableHeight = "calc(100vh - 210px)";
  return (
    <>
      <PageTitle className="py-4">My Opportunities</PageTitle>
      <Row className="pb-3">
        <Col md="3" lg="2">
          <TableNav
            urlPath="home/"
            navItems={filterItems}
            tableHeight={tableHeight}
          />
        </Col>
        <Col md="9" lg="10">
          <div>
            {tableData.length ? (
              <Table
                title={filter}
                resourcePath="opportunities"
                columns={columns}
                data={tableData}
                tableHeight={tableHeight}
              />
            ) : (
              <ZeroState filterKey={filterKey} />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
