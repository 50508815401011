import React, { useEffect, useState } from "react";
import CardModal from "./CardModal";
import ListingCards from "./ListingCards";
import CreateOpportunityModal from "./CreateOpportunityModal";
import Button from "~components/Button";

export default function ListingContent({ user, dispatch, store, listing }) {
  const [selectedCard, setSelectedCard] = useState();
  const [cardList, setCardList] = useState();
  const [showCreateModal, setShowCreateModal] = useState();
  const handleClick = (card) => setSelectedCard(card);
  const handleModalClose = () => {
    setSelectedCard();
  };
  const isSeller = user.id === listing.user_id;
  useEffect(() => {
    // Display Opportunities before Leads
    const opportunityIds =
      listing.opportunities?.map((o) => o.requirement_id) || [];
    const fiteredLeads = listing.leads.filter(
      (l) => !opportunityIds.includes(l.id)
    );
    const data = [...(listing.opportunities || []), ...fiteredLeads].sort(
      (firstItem, secondItem) =>
        new Date(firstItem.created_at) > new Date(secondItem.created_at)
    );
    setCardList(data);
  }, [listing, setCardList, user]);
  return (
    <>
      <div className="d-flex justify-content-end px-5 py-3">
        {!isSeller && !listing.is_private && listing.is_active && (
          <div className="mr-3">
            <Button theme="primary" onClick={() => setShowCreateModal(true)}>
              Create Opportunity
            </Button>
          </div>
        )}
      </div>
      <ListingCards
        {...{
          handleClick,
          listing,
          closeModal: handleModalClose,
          dispatch,
          cardList,
          currentUser: user,
        }}
      />
      {showCreateModal && (
        <CreateOpportunityModal
          {...{
            listing,
            handleClose: () => {
              setShowCreateModal(false);
            },
            dispatch,
          }}
        />
      )}
      {selectedCard && (
        <CardModal
          title={
            selectedCard.opportunity
              ? "Opportunity Details"
              : "Requirement Details"
          }
          {...{
            dispatch,
            listing,
            handleClose: handleModalClose,
            store,
            opportunity: selectedCard.opportunity,
            requirement: selectedCard.requirement,
            setSelectedCard: handleClick,
            currentUser: user,
          }}
        />
      )}
    </>
  );
}
