import React, { useState } from "react";
import Input from "~components/Input";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import FormPageLayout from "~components/FormPageLayout";
import Button from "~components/Button";
import AddressGeocodeField from "~components/AddressGeocodeField";
import TypeSelect from "~components/TypeSelect";
import Toggle from "~components/Toggle";
import Tooltip from "~components/Tooltip";
import { createListing } from "~components/listings/create/utils";
import { useMsal } from "@azure/msal-react";
import { getIntFromString } from "~utilities/format";
import DatePickerField from "~components/DatePickerField";
import * as matomo from "../../../matomo.js";
import { getISODateFromString } from "utilities/format.js";

const isValueEmpty = (value) => {
  return value === "" || value === undefined;
};

const isValueWithinBoundaries = (value, min, max) => {
  const isValueValid =
    isValueEmpty(value) ||
    (Number.isInteger(value) && value >= min && value <= max);
  return isValueValid;
};

const validate = ({ capitalization_percent, lease_term }) => {
  const errors = {};
  if (capitalization_percent > 100 || capitalization_percent < 0) {
    errors.capitalization_percent = "Cap rate must be between 0 and 100";
  }
  if (!isValueWithinBoundaries(lease_term, 1, 60)) {
    errors.lease_term =
      "Lease term must be between 1 and 60 years (whole numbers only)";
  }
  return errors;
};

export default function Create() {
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const msal = useMsal();
  return (
    <Container className="mt-3">
      <FormPageLayout
        initialValues={{
          name: "",
          price: 1000000,
          types: [],
          building_number: undefined,
          street_address: undefined,
          city: undefined,
          state: undefined,
          postal_code: undefined,
          description: undefined,
          images: undefined,
          is_private: true,
          capitalization_percent: undefined,
          lease_term: undefined,
        }}
        validate={validate}
        headerText="New Listing"
        onSubmit={async (values) => {
          const parsedPrice = getIntFromString(values.price);
          const formattedDate = getISODateFromString(values.expires_on);
          createListing(
            msal,
            { ...values, expires_on: formattedDate, price: parsedPrice },
            files,
            ({ data }) => {
              matomo.trackEvent("My Listings", "Create Listing", data?.id);
              history.push("/listings");
            }
          );
        }}
      >
        {(form) => (
          <Form>
            <FormGroup>
              <Input
                name="name"
                label="Name"
                isRequired
                inputAttributes={{ className: "form-control", type: "text" }}
              />
            </FormGroup>
            <FormGroup>
              <Label className="d-flex align-items-center">
                <span className="mr-1">Private Listing</span>
                <Tooltip
                  content={
                    <div>
                      Private listings will not show up for buyers, unless you
                      have shared it with them directly by creating an
                      opportunity on their requirement.
                    </div>
                  }
                />
              </Label>
              <Toggle name="is_private" />
            </FormGroup>
            <FormGroup>
              <Input
                name="price"
                label="Price($)"
                placeholder="Price"
                type="number"
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    name="capitalization_percent"
                    label="Cap Rate(%)"
                    inputAttributes={{
                      className: "form-control",
                      type: "number",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    name="lease_term"
                    label="Lease Term(Years)"
                    inputAttributes={{
                      className: "form-control",
                      type: "number",
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label className="d-flex align-items-center">
                <span className="mr-1">Expiration Date</span>
                <Tooltip
                  content={
                    <div>
                      Listings will not show up on the For Sale page or as leads
                      for requirements after they expire.
                    </div>
                  }
                />
              </Label>
              <DatePickerField name="expires_on" className="form-control" />
            </FormGroup>
            <TypeSelect
              name="types"
              form={form}
              label="Preferred property type(s)"
            />
            <FormGroup>
              <Label>Listing address</Label>
              <Field name="street">
                {({ form }) => <AddressGeocodeField form={form} />}
              </Field>
            </FormGroup>
            <FormGroup>
              <Label>Photo Upload</Label>
              <input
                type="file"
                className="form-control-file"
                multiple={true}
                onChange={(val) => {
                  setFiles(val.target.files);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Description</Label>
              <Field
                as="textarea"
                name="description"
                className="form-control"
              />
            </FormGroup>
            <Button
              buttonAttributes={{ type: "submit" }}
              theme="primary"
              disabled={!form.isValid || !form.dirty}
            >
              Submit
            </Button>
          </Form>
        )}
      </FormPageLayout>
    </Container>
  );
}
