const gray = {
  100: "#FAFAFA",
  200: "#D9DADE",
  300: "#A5A6A8",
  400: "#767779",
  500: "#4E4E50",
  600: "#2D2D2D",
};

const primary = "#50B196";
const primaryDark = "#387E6A";
const secondary = "#2F373A";
const accent = "#73F7BD";
const accentLight = "#F5FCFA";
const background = gray["100"];
const primaryHover = "#221e53";
const foreground = "#ffffff";
const backgroundDark = gray["600"];
const error = "#ff6262";
const success = "#5ad5a0";
const overlay = "rgba(0,0,0,0.75)";
const overlayDark = "rgba(0,0,0,0.85)";
const warning = "#FEA154";
const mapStrokeColor = "#FF0000";
const mapFillColor = "#ebae34";
const iconStroke = "#00B395";
const colors = {
  primary,
  background,
  primaryHover,
  foreground,
  backgroundDark,
  error,
  success,
  overlay,
  gray,
  warning,
  secondary,
  accent,
  accentLight,
  primaryDark,
  overlayDark,
  mapStrokeColor,
  mapFillColor,
  iconStroke,
};

// Based on Booststrap breakpoints:
// https://getbootstrap.com/docs/5.0/layout/breakpoints/
const breakpoints = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 };

const zIndex = { modal: 999, overlay: 100, elevated: 5, base: 1 };

const setBreakPoint = ({ size, mediaSetting, styles }) => {
  // size: a string, one of: xxl xl lg md sm xs from Bootstrap breakpoints, or a pixel value
  // mediaSetting: e.g. min-width, max-wdith
  // styles: string, styles to display at this breakpoint
  return `@media (${mediaSetting || "min-width"}: ${
    breakpoints[size] || size
  }px) {
    ${styles}
  }`;
};

export { colors, breakpoints, zIndex, setBreakPoint };
