import React, { forwardRef, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { colors } from "~utilities/styles";
import styled, { css } from "styled-components";
import "tippy.js/animations/shift-away-subtle.css";

const MenuItem = styled.div`
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: ${colors.gray["100"]};
  }
`;

const Menu = styled.div`
  background: ${colors.foreground};
  border-radius: 3px;
  ${({ menuWidth }) =>
    css`
      width: ${menuWidth}px;
    `}
`;

const Trigger = forwardRef(({ children }, ref) => {
  return (
    <span className="cursor-poiner" ref={ref}>
      {children ? (
        children
      ) : (
        <FontAwesomeIcon
          icon="ellipsis-v"
          size="lg"
          color={colors.gray["300"]}
        />
      )}
    </span>
  );
});

export default function Popover({
  items,
  width,
  itemsClassName,
  tippyProps,
  children,
  overflowVisible = false,
}) {
  // items is a list of objects containing the following keys:
  // action: Function to fire when the menu item is clicked
  // text: Display text for the menu item
  const tippyEl = useRef(null);

  // only render popover if provided with items to displays
  if (!items.length) {
    return <></>;
  }
  return (
    <span className="d-flex position-relative">
      <Tippy
        ref={tippyEl}
        animation="shift-away-subtle"
        content={
          <Menu
            className={`box-shadow ${
              !overflowVisible ? "overflow-hidden" : ""
            }`}
            menuWidth={width}
          >
            {items.map(
              (item, i) =>
                item && (
                  <MenuItem
                    key={i}
                    className={itemsClassName ? itemsClassName : "py-2 px-3"}
                    onClick={() => {
                      item.action();
                      tippyEl.current._tippy.hide();
                    }}
                  >
                    {item.text}
                  </MenuItem>
                )
            )}
          </Menu>
        }
        {...{ trigger: "click", interactive: true, offset: [0, "2rem"] }}
        {...tippyProps}
      >
        <Trigger>{children}</Trigger>
      </Tippy>
    </span>
  );
}

Popover.defaultProps = { width: 100 };
