import { createContext, useContext, useMemo } from "react";

import useEditReducer from "~reducers/list";

const EditContext = createContext({
  state: {},
  dispatch: () => {},
});

export const EditProvider = ({ resourceId, initialState, children }) => {
  // config and status
  initialState.id = resourceId;
  const [state, dispatch] = useEditReducer(initialState);

  const context = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <EditContext.Provider value={context}>{children}</EditContext.Provider>
  );
};

export const useEditProvider = () => useContext(EditContext);
