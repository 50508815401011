import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const OpportunityLeads = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const createOpportunity = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={createOpportunity}
      direction="left"
    >
      <DropdownToggle tag="span">Create Opportunity</DropdownToggle>
      <DropdownMenu>
        {props.leads.length > 0 ? (
          props.leads.map((lead) => (
            <DropdownItem
              key={lead.id}
              onClick={() => props.addOpportunity(lead)}
            >
              {lead.name}
            </DropdownItem>
          ))
        ) : (
          <DropdownItem>You don't have any corresponding leads</DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OpportunityLeads;
