import React, { useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { ReactComponent as PocketbookLogo } from "~assets/img/pocketbook.svg";
import styled from "styled-components";
import { zIndex } from "~utilities/styles";
import Button from "components/Button";

const StyledNav = styled.div`
  position: sticky;
  top: 0;
  z-index: ${zIndex.base};
`;

function handleLogout(history) {
  localStorage.clear();
  history.push("/login"); // Users don't want to log out of Azure
}

export default function Header() {
  const history = useHistory();
  const match = useRouteMatch();
  const [activeLink, setActiveLink] = useState("/");
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const location = useLocation();
  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, [match]);
  if (location.pathname === "/login") return <></>;
  return (
    <StyledNav>
      <Navbar dark fixed="true" expand="md">
        <Container fluid>
          <Link to="/" className="navbar-brand mr-4">
            <PocketbookLogo />
          </Link>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar className="mr-lg-3">
              <NavItem active={activeLink === "/requirements"}>
                <Link className="nav-link" to="/requirements">
                  Buyers
                </Link>
              </NavItem>
              <NavItem active={activeLink === "/listings"}>
                <Link className="nav-link" to="/listings">
                  For Sale
                </Link>
              </NavItem>
              <NavItem active={activeLink === "/myrequirements"}>
                <Link className="nav-link" to="/myrequirements">
                  My Book
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
          <div>
            <Collapse isOpen={!collapsed} navbar>
              <Nav navbar className="mr-lg-3">
                <NavItem active={activeLink === "/requirements/create"}>
                  <Link className="nav-link" to="/requirements/create">
                    Add Requirement
                  </Link>
                </NavItem>
                <NavItem active={activeLink === "/listings/create"}>
                  <Link className="nav-link" to="/listings/create">
                    Add Listing
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
          <div>
            <Button
              buttonAttributes={{ className: "bg-secondary" }}
              onClick={() => handleLogout(history)}
            >
              Log out
            </Button>
          </div>
        </Container>
      </Navbar>
    </StyledNav>
  );
}
