import React from "react";
import Layout from "components/mine/Layout";
import { default as RequirementsPage } from "components/mine/Requirements";

export default function Requirements() {
  return (
    <Layout>
      <RequirementsPage />
    </Layout>
  );
}
