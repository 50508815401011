import React, { useEffect } from "react";
import { Toast, ToastHeader } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "utilities/styles";

const StyledToast = styled(Toast)`
  position: fixed;
  right: 20px;
  top: 50px;

  & .toast_head {
    padding: 10px 15px;
    background-color: green;

    span {
      color: #fff;
    }

    strong {
      display: flex;
      justify-content: space-between;
      width: 250px;
    }

    .close {
      cursor: pointer;
    }
  }
`;

const ToastView = (props) => {
  useEffect(() => {
    setTimeout(() => {
      if (props.isOpen) props.closeToast();
    }, 3000);
  }, [props.isOpen]);

  return (
    <StyledToast isOpen={props.isOpen}>
      <ToastHeader className="toast_head">
        <span onClick={props.navigateToPage}>{props.message}</span>
        <span className="close" onClick={props.closeToast}>
          <FontAwesomeIcon icon="times" size="sm" color={colors.foreground} />
        </span>
      </ToastHeader>
    </StyledToast>
  );
};

export default ToastView;
