import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { useListProvider, ListProvider } from "~providers/list";
import { getApiInstance } from "~utilities/api";
import placeholderImg from "~assets/img/img-placeholder.jpg";
import Layout from "~components/listings/list/Layout";
import initialState from "./initialState";
import Loading from "~components/Loading";
import { colors } from "~utilities/styles";
import { useMsal } from "@azure/msal-react";
import {
  formatDate,
  formatPercent,
  formatCurrency,
  formatStreetAddress,
  formatCityAndState,
  formatAddress,
} from "~utilities/format";
import FilterSidebar from "~components/FilterSidebar";
import { useAuth } from "~providers/auth";
import Table from "~components/Table";
import CapitalizedList from "~components/CapitalizedList";
import { ViewSelection } from "~reducers/list";
import { defaultFilters } from "~components/FilterSidebar";
import Popover from "~components/Popover";
import { useHistory } from "react-router-dom";
import OpportunityLeads from "~components/OpportunityLeads";
import { createOpportunity } from "~components/listings/show/components/utils";
import ToastView from "~components/ToastView";

const GridViewSelfTag = styled.div`
  background: ${colors.secondary};
  top: 5%;
  left: 5%;
  position: absolute;
  display: block;
  color: ${colors.foreground};
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 2px;
`;

const ListViewSelfTag = styled.span`
  display: inline-block;
  background: ${colors.secondary};
  text-align: center;
  color: ${colors.foreground};
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
  border-radius: 2px;
`;

const StyledListWrapper = styled(Col)`
  max-height: calc(100vh - 112px);
  overflow: auto;
`;

const StyledEmail = styled.div`
  color: ${colors.primary};
`;

const CardLink = styled(Link)`
  color: inherit;
  &:hover {
    text-decoration: none !important;
    color: ${colors.primaryDark};
    .link-primary {
      color: ${colors.primaryDark};
    }
  }
`;

const ImgWrapper = styled.div`
  height: 7rem;
  width: 100%;
  text-align: center;
  background: ${colors.gray["600"]};
  overflow: hidden;
  border-radius: 4px 4px 0 0;
`;

const StyledImg = styled.img``;

const StyledCardTitle = styled(CardTitle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 50px;
`;

const StyledPopover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;

  svg {
    line-height: 75px;
    height: 75px;
  }
`;

const reducer = (state, action) => {
  switch (action.type) {
    case "updateListing":
      return {
        ...state,
        ...action.payload,
      };

    case "addOpportunity":
      const { data } = action.payload;
      const opportunity = {
        ...data,
        ...{ comments: data.comments || [] },
      };
      return {
        ...state,
        opportunity,
        message: "Created Opportunity",
      };

    case "hideToast":
      return {
        ...state,
        message: "",
      };

    default:
      return state;
  }
};

const Img = ({ name, address, image_urls, user }) => {
  const auth = useAuth();

  return (
    <ImgWrapper>
      {user.id === auth.user.id && (
        <small>
          <GridViewSelfTag>My Listing</GridViewSelfTag>
        </small>
      )}
      <StyledImg
        src={`${image_urls ? image_urls[0] : placeholderImg}`}
        alt={name || address}
      />
    </ImgWrapper>
  );
};

const _getGridView = (listings) => {
  return (
    <Row className="pt-4" xl="4" lg="3" md="2" xs="1">
      {listings &&
        listings.map((listing, i) => (
          <Col key={listing.id}>
            <CardLink to={`listings/${listing.id}`} className="d-block pb-3">
              <Card className="h-100">
                <Img {...listing} />
                <CardBody>
                  <StyledCardTitle tag="h4">
                    {listing.name || "Unspecified"}
                  </StyledCardTitle>
                  <strong>{listing.user.name}</strong>
                  <div className="link-primary mb-2">{listing.user.email}</div>
                  <Row>
                    <Col>
                      <strong>Expiration date:</strong>
                      <div>
                        {listing.expires_on
                          ? formatDate(listing.expires_on)
                          : "Unspecified"}
                      </div>
                    </Col>
                    <Col className="flex-grow-0">
                      <strong className="truncate">Cap Rate:</strong>
                      <div className="truncate">
                        {listing.capitalization_percent
                          ? formatPercent(listing.capitalization_percent, 3)
                          : "Unspecified"}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardLink>
          </Col>
        ))}
    </Row>
  );
};

const _getListView = (auth, listings, dispatch, goToPage) => {
  const msal = useMsal();
  const { user: currentUser } = useAuth();

  const addOpportunity = (requirement, listing) => {
    createOpportunity({
      requirement,
      listing,
      msal,
      dispatch,
      currentUser,
    });
  };

  return (
    <Table
      resourcePath="listings"
      tableHeight="calc(100vh - 160px)"
      columns={[
        {
          id: "name",
          Header: "Listing",
          accessor: ({ name }) => {
            return name && name.toLowerCase();
          },
          columnWidth: "30rem",
          Cell: ({ row }) => {
            const { name, user } = row.original;
            return (
              <div className="h-100 d-flex flex-column justify-content-between">
                {user.id === auth.user.id && (
                  <small>
                    <ListViewSelfTag>My Listing</ListViewSelfTag>
                  </small>
                )}
                {name && <strong>{name}</strong>}
                <br />
                {user && (
                  <div className="d-flex flex-column justify-content-end">
                    <small>{user.name}</small>
                    <StyledEmail>{user.email}</StyledEmail>
                  </div>
                )}
              </div>
            );
          },
        },
        {
          id: "capitalization_percent",
          Header: "Cap Rate",
          columnWidth: "10rem",
          accessor: ({ capitalization_percent }) => {
            return formatPercent(capitalization_percent, 3);
          },
        },
        {
          id: "price",
          Header: "Price",
          columnWidth: "15rem",
          accessor: ({ price }) => {
            return price;
          },
          Cell: ({ row }) => {
            const { price } = row.original;
            if (price) {
              return <strong>{formatCurrency(price)}</strong>;
            } else {
              return <></>;
            }
          },
        },
        {
          id: "types",
          Header: "Property Type",
          columnWidth: "10rem",

          accessor: (listing) => {
            return listing.types.length > 1
              ? listing.types.length
              : listing.types?.[0];
          },
          Cell: ({ row }) => {
            const { types } = row.original;
            return <CapitalizedList items={types} />;
          },
        },
        {
          id: "address",
          Header: "Address",
          columnWidth: "10rem",
          accessor: ({
            building_number,
            street_address,
            city,
            state,
            postal_code,
          }) => {
            return formatAddress(
              building_number,
              street_address,
              city,
              state,
              postal_code
            );
          },
          Cell: ({ row }) => {
            let { building_number, street_address, city, state, postal_code } =
              row.original;
            const first_line = formatStreetAddress(
              building_number,
              street_address
            );
            const second_line = formatCityAndState(city, state);

            return (
              <>
                {first_line}
                {first_line && second_line && <br />}
                {second_line}
                {second_line && postal_code && <br />}
                {postal_code || ""}
              </>
            );
          },
        },
        {
          id: "expires_on",
          Header: "Expiration",
          columnWidth: "10rem",
          accessor: ({ expires_on }) => {
            return formatDate(expires_on);
          },
          Cell: ({ row }) => {
            let { expires_on } = row.original;
            return <strong>{formatDate(expires_on)}</strong>;
          },
        },
        {
          id: "action",
          Header: "Actions",
          columnWidth: "10rem",
          accessor: () => {
            return "";
          },
          Cell: ({ row }) => {
            const { id, leads } = row.original;
            const menuItems = [
              { text: "View", action: () => goToPage(id) },
              {
                text: (
                  <OpportunityLeads
                    addOpportunity={(req) => addOpportunity(req, row.original)}
                    resourcePath={"listings"}
                    leads={leads}
                  />
                ),
                action: () => {},
              },
            ];
            return (
              <StyledPopover>
                <Popover
                  items={menuItems}
                  width={160}
                  tippyProps={{ placement: "right", offset: [0, -15] }}
                  overflowVisible
                />
              </StyledPopover>
            );
          },
        },
      ]}
      data={listings}
    />
  );
};

const ListContent = ({ dispatch, listState }) => {
  const { state } = useListProvider();
  const [listings, setListings] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const msal = useMsal();
  const auth = useAuth();
  const history = useHistory();

  const onClick = (id) => {
    history.push(`/listings/${id}`);
  };

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { loading, data } = await getApiInstance(msal, "/listings", {
        ...state.params,
        where: {
          ...state.params.where,
          ...defaultFilters,
        },
        include: {
          ...state.params.include,
          leads: {
            ...(state.params.include?.leads || {}),
            user: true,
          },
        },
      });
      if (state.params.where.user_id === true) {
        setListings(data);
      } else {
        let filterData = [];
        filterData = data.filter((object) => object.user.id !== auth.user.id);
        setListings(filterData);
      }
      setLoading(loading);
    }
    fetch();
  }, [state.params, msal, auth]);

  const onClose = () => {
    dispatch({ type: "hideToast" });
  };

  return (
    <Layout>
      <ToastView
        isOpen={!!listState.message}
        message={listState.message}
        closeToast={onClose}
        navigateToPage={() => onClick(listState.opportunity?.listing_id)}
      />
      <Row>
        <Col className="py-3" md="3" hidden={!state.displayFilter}>
          <FilterSidebar />
        </Col>
        <StyledListWrapper>
          {/* Column size is not specified so it can take up the whole width when the TableNav is closed */}
          {isLoading ? (
            <Loading />
          ) : state.viewSelection === ViewSelection.Grid ? (
            _getGridView(listings)
          ) : state.viewSelection === ViewSelection.List ? (
            _getListView(auth, listings, dispatch, onClick)
          ) : (
            // TODO display grid view by default?
            _getGridView(listings)
          )}
        </StyledListWrapper>
      </Row>
    </Layout>
  );
};

export default function List() {
  const [state, dispatch] = React.useReducer(reducer, null);
  return (
    <ListProvider
      initialState={initialState}
      reducer={reducer}
      store={state || {}}
      dispatch={dispatch}
    >
      <ListContent dispatch={dispatch} listState={state || {}} />
    </ListProvider>
  );
}
