import React, { useEffect, useState } from "react";
import CardModal from "./CardModal";
import RequirementCards from "./RequirementCards";
import FilterButton from "./FilterButton";
import CreateOpportunityModal from "./CreateOpportunityModal";
import Button from "~components/Button";

const filters = {
  open: (data, _) => {
    return data.filter((card) => card.is_active);
  },
  newMessages: (data, currentUser) => {
    return data.filter(
      (card) =>
        card.comments?.length && card.comments[0]?.user.id !== currentUser.id
    );
  },
  closed: (data, _) => {
    return data.filter((card) => card.requirement_id && !card.is_active);
  },
  leads: (data, _) => {
    return data.filter((card) => !card.requirement_id);
  },
};

export default function RequirementContent({
  user,
  dispatch,
  store,
  requirement,
}) {
  const [sortedData, setSortedData] = React.useState([]);
  const [selectedCard, setSelectedCard] = React.useState();
  const [cardList, setCardList] = React.useState();
  const [filterState, setFilterState] = useState("all");
  const [showCreateModal, setShowCreateModal] = React.useState();
  const handleClick = (card) => setSelectedCard(card);
  const handleModalClose = () => {
    setSelectedCard();
  };
  const isBuyer = user.id === requirement.user_id;
  const additionalFilterOptions = [
    {
      text: isBuyer ? "Leads" : "Listings",
      action: () => setFilterState("leads"),
    },
  ];
  useEffect(() => {
    // Display Opportunities before Leads
    const opportunityIds = requirement.opportunities.map((o) => o.listing_id);
    const fiteredLeads = requirement.leads.filter(
      (l) => !opportunityIds.includes(l.id)
    );
    const data = [...requirement.opportunities, ...fiteredLeads].sort(
      (firstItem, secondItem) =>
        new Date(firstItem.created_at) > new Date(secondItem.created_at)
    );
    setSortedData(data);
  }, [requirement]);
  useEffect(() => {
    if (filterState !== "all") {
      const filterdData = filters[filterState](sortedData, user);
      return setCardList(filterdData);
    }
    return setCardList(sortedData);
  }, [sortedData, requirement, setCardList, filterState, user]);

  return (
    <>
      <div className="d-flex justify-content-end px-5 py-3">
        {!isBuyer && requirement.is_open && requirement.is_active && (
          <div className="mr-3">
            <Button theme="primary" onClick={() => setShowCreateModal(true)}>
              Create Opportunity
            </Button>
          </div>
        )}
        <FilterButton
          {...{
            setFilterState,
            filterState,
            additionalOptions: additionalFilterOptions,
          }}
        />
      </div>
      <RequirementCards
        {...{
          handleClick,
          requirement,
          closeModal: handleModalClose,
          dispatch,
          cardList,
          currentUser: user,
          filterState,
        }}
      />
      {showCreateModal && (
        <CreateOpportunityModal
          {...{
            requirement,
            handleClose: () => {
              setShowCreateModal(false);
            },
            dispatch,
          }}
        />
      )}
      {selectedCard && (
        <CardModal
          title={
            selectedCard.opportunity ? "Opportunity Details" : "Listing Details"
          }
          {...{
            dispatch,
            requirement,
            handleClose: handleModalClose,
            store,
            opportunity: selectedCard.opportunity,
            listing: selectedCard.listing,
            setSelectedCard: handleClick,
            currentUser: user,
          }}
        />
      )}
    </>
  );
}
