const initialState = {
  displayFilter: true,
  params: {
    where: {},
    include: {
      regions: true,
      user: true,
    },
    limit: 1000,
    offset: 0,
  },
  metadata: {
    count: 0,
  },
};

export default initialState;
