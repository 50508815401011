import { getApiInstance } from "~utilities/api";
import styled from "styled-components";
import * as matomo from "../../../../matomo.js";

export const placeholder = "Unspecified";

const StyledLabel = styled.section`
  font-size: 0.9rem;
`;

export const Label = ({ className, children }) => {
  return (
    <StyledLabel className={className}>
      <strong>{children}</strong>
    </StyledLabel>
  );
};

export const DisplayText = ({ text }) => {
  return (
    <p className="truncate" title={text}>
      {text || placeholder}
    </p>
  );
};

export const createOpportunity = async ({
  requirement,
  listing,
  msal,
  dispatch,
  currentUser,
}) => {
  const isSeller = currentUser.id === listing.user_id;
  const vars = {
    requirement_id: requirement.id,
    listing_id: listing.id,
  };
  const { data } = await getApiInstance(
    msal,
    "/opportunities",
    {},
    "post",
    vars
  );
  const opportunity = { ...data, requirement };
  matomo.trackEvent(
    isSeller ? "My Listing Detail" : "Listing Detail",
    "Create Opportunity",
    opportunity.id
  );
  dispatch({ type: "addOpportunity", payload: { data: opportunity } });
};

const toggleOpportunityActive = async ({ id, is_active }, msal, dispatch) => {
  const { data } = await getApiInstance(
    msal,
    `/opportunities/${id}/set_active`,
    {},
    "post",
    { is_active: !is_active }
  );
  matomo.trackEvent(
    "Listing Detail",
    `${!is_active ? "Open" : "Close"} Opportunity`,
    data.id
  );
  dispatch({ type: "updateOpportunity", payload: data });
};

const getListingActions = (
  requirement,
  listing,
  msal,
  dispatch,
  currentUser
) => {
  return [
    {
      text: "Create Opportunity",
      action: () => {
        createOpportunity({
          requirement: requirement,
          listing,
          msal,
          dispatch,
          currentUser,
        });
      },
    },
  ];
};

export const getActions = (card, listing, currentUser, msal, dispatch) => {
  const isOpportunity = card.listing_id;
  const toggleAction = isOpportunity && {
    text: card.is_active ? "Close" : "Open",
    action: () => toggleOpportunityActive(card, msal, dispatch),
  };
  const showToggle = isOpportunity;
  const baseActions =
    !isOpportunity && card.is_open && card.is_active && listing.is_active
      ? getListingActions(card, listing, msal, dispatch, currentUser)
      : [];
  if (showToggle) {
    return [toggleAction, ...baseActions];
  }
  return baseActions;
};
