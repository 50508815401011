import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import Modal from "~components/Modal";
import { Label } from "./utils";
import { getApiInstance } from "~utilities/api";
import Input from "~components/Input";
import { Formik, Form, Field } from "formik";
import TypeSelect from "~components/TypeSelect";
import Tooltip from "~components/Tooltip";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Comments from "~components/Comments";
import { getIntFromString, formatLeaseTerm } from "~utilities/format";
import { useMsal } from "@azure/msal-react";
import DatePickerField from "~components/DatePickerField";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as matomo from "../../../../matomo.js";
import { getISODateFromString } from "utilities/format";

const StyledTextarea = styled.textarea`
  border-radius: 3px;
  border: 1px solid ${colors.gray[200]};
  transition: 0.2s;
  ${({ errors, name }) => errors?.[name] && `border-color: ${colors.error}`}
`;

const validateForm = ({ name, min_capitalization_percent }) => {
  let errors = {};
  if (!name) {
    errors.name = " ";
  }
  if (min_capitalization_percent > 100 || min_capitalization_percent < 0) {
    errors.min_capitalization_percent =
      "Minimum cap rate must be between 0 and 100";
  }
  return errors;
};

const handleSubmit = async (
  listing,
  values,
  files,
  commentBody,
  documentBlobs,
  msal,
  cb
) => {
  const formattedDate = getISODateFromString(values.expires_on);
  // Create Requirement
  const formattedValues = {
    ...values,
    expires_on: formattedDate,
    min_price: getIntFromString(values.min_price),
    max_price: getIntFromString(values.max_price),
  };
  const requirement = await getApiInstance(msal, "/requirements", {}, "post", {
    requirement: formattedValues,
  });

  // Create Opportunity
  const opportunity = await getApiInstance(
    msal,
    "/opportunities",
    { include: { requirement: { user: true } } },
    "post",
    {
      requirement_id: requirement.data.id,
      listing_id: listing.id,
    }
  );
  matomo.trackEvent(
    "Listing Detail",
    "Create Opportunity with new requirement",
    opportunity.data.id
  );
  // Create Comment
  const comment = await getApiInstance(
    msal,
    "/comments",
    {
      include: { document_urls: true, user: true },
    },
    "post",
    {
      comment: {
        opportunity_id: opportunity.data.id,
        user_id: requirement.data.user_id,
        body: commentBody,
        documents: documentBlobs,
      },
    }
  );
  matomo.trackEvent(
    "Listing Detail",
    "Create comment with opportunity",
    comment.data.id,
    comment.data.document_urls?.length || 0
  );
  // Stitch together the new opportunity to insert in the store
  cb({
    ...opportunity.data,
    listing: listing,
    comments: [comment.data],
  });
};

export default function CreateOpportunityModal({
  listing,
  handleClose,
  dispatch,
}) {
  const msal = useMsal();
  const [files] = useState();
  const [loading, setLoading] = useState(false);
  const [isLeaseTermDropdownOpen, setIsLeaseTermDropdownOpen] = useState(false);
  const [leaseTerm, setLeaseTerm] = useState();
  const [commentBody, setCommentBody] = useState();
  const cb = (opportunity) => {
    handleClose();
    dispatch({
      type: "addOpportunity",
      payload: { data: opportunity },
    });
  };
  return (
    <Modal
      {...{
        handleClose,
        title: "Create Opportunity",
        loading,
      }}
    >
      <div className="pb-5">
        <Formik
          initialValues={{
            name: "",
            min_price: 1000000,
            max_price: 1000000,
            types: [],
            comment: "",
            min_capitalization_percent: "",
          }}
          validate={validateForm}
        >
          {(form) => {
            return (
              <Form>
                <div>
                  <div className="d-flex align-items-center p-4">
                    <h3 className="m-0 mr-2">Create A New Opportunity</h3>
                    <Tooltip
                      content={
                        <div>
                          Create a new opportunity. This will also create a new
                          requirement and will kick off the conversation with
                          the seller automatically.
                        </div>
                      }
                    />
                  </div>
                  <div className="px-4">
                    <FormGroup>
                      <Label>Name</Label>
                      <Input name="name" />
                    </FormGroup>
                    <Field name="note">
                      {({ field }) => (
                        <StyledTextarea
                          className="p-3 w-100"
                          placeholder="Add a note..."
                          {...field}
                        ></StyledTextarea>
                      )}
                    </Field>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Minimum Price($)</Label>
                          <Input name="min_price" type="number" />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Maximum Price</Label>
                          <Input name="max_price" type="number" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            name="min_capitalization_percent"
                            label="Minimum Cap Rate(%)"
                            inputAttributes={{
                              className: "form-control",
                              type: "number",
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            htmlFor="Minimum Lease Term(Years)"
                            className="form-label"
                          >
                            Minimum Lease Term(Years)
                          </label>
                          <Dropdown
                            isOpen={isLeaseTermDropdownOpen}
                            toggle={() => {
                              setIsLeaseTermDropdownOpen(
                                (prevState) => !prevState
                              );
                            }}
                          >
                            {leaseTerm ? (
                              <DropdownToggle
                                tag="span"
                                caret={true}
                                className="form-control"
                              >
                                {leaseTerm}
                              </DropdownToggle>
                            ) : (
                              <DropdownToggle
                                tag="span"
                                caret={true}
                                className="form-control text-secondary"
                              >
                                Min Lease Term
                              </DropdownToggle>
                            )}
                            <DropdownMenu>
                              {[
                                {},
                                { min: 1, max: 5 },
                                { min: 6, max: 10 },
                                { min: 11, max: 15 },
                                { min: 16 },
                              ].map(({ min, max }) => {
                                const leaseTermOption = formatLeaseTerm({
                                  min,
                                  max,
                                });
                                return (
                                  <DropdownItem
                                    key={leaseTermOption}
                                    onClick={() => {
                                      form.setFieldValue(
                                        "minimum_lease_term",
                                        min || null
                                      );
                                      form.setFieldValue(
                                        "maximum_lease_term",
                                        max || null
                                      );
                                      setLeaseTerm(leaseTermOption);
                                    }}
                                  >
                                    {leaseTermOption || "--"}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <Label className="mr-1">
                          <span>Expiration Date</span>
                        </Label>
                        <Tooltip
                          content={
                            <div>
                              Requirements will not show up on the Buyers page
                              or as leads for listings after they expire.
                            </div>
                          }
                        />
                      </div>
                      <DatePickerField
                        name="expires_on"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Preferred Property Type(s)</Label>
                      <TypeSelect name="types" form={form} />
                    </FormGroup>
                    <Label>Comment</Label>
                    <Comments
                      onChange={setCommentBody}
                      value={commentBody}
                      disabledPredicate={() => {
                        return !form.isValid || !commentBody;
                      }}
                      buttonText="Comment and Create Opportunity"
                      commentAction={(comment, documentBlobs) => {
                        setLoading(true);
                        return handleSubmit(
                          listing,
                          form.values,
                          files,
                          comment,
                          documentBlobs,
                          msal,
                          cb
                        );
                      }}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
