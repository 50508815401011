import React, { useState } from "react";
import { FormGroup, Label } from "reactstrap";
import Modal from "~components/Modal";
import { Formik, Form, Field } from "formik";
import Input from "~components/Input";
import Button from "~components/Button";
import TypeSelect from "~components/TypeSelect";
import AddressGeocodeField from "~components/AddressGeocodeField";
import Toggle from "~components/Toggle";
import Tooltip from "~components/Tooltip";
import { editListing } from "./utils";
import { getIntFromString } from "~utilities/format";
import { useMsal } from "@azure/msal-react";
import DatePickerField from "~components/DatePickerField";
import { formatDate } from "~utilities/format";
import * as matomo from "../../../matomo.js";
import { getISODateFromString } from "utilities/format";

const isValueEmpty = (value) => {
  return value === "" || value === undefined;
};

const isValueWithinBoundaries = (value, min, max) => {
  const isValueValid =
    isValueEmpty(value) ||
    (Number.isInteger(value) && value >= min && value <= max);
  return isValueValid;
};

const validate = ({ capitalization_percent, lease_term }) => {
  const errors = {};
  if (capitalization_percent > 100 || capitalization_percent < 0) {
    errors.capitalization_percent = "Cap rate must be between 0 and 100";
  }
  if (!isValueWithinBoundaries(lease_term, 1, 60)) {
    errors.lease_term =
      "Lease term must be between 1 and 60 years (whole numbers only)";
  }
  return errors;
};

export default function EditModal({ listing, handleClose, dispatch }) {
  const msal = useMsal();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    id,
    name,
    types,
    price,
    building_number,
    street_address,
    city,
    state,
    postal_code,
    description,
    is_private,
    expires_on,
    capitalization_percent,
    lease_term,
  } = listing;
  const handleSubmit = async (values) => {
    const parsedPrice = getIntFromString(values.price);
    const formattedDate = getISODateFromString(values.expires_on);
    setLoading(true);
    const updatedListing = await editListing(
      msal,
      id,
      { ...values, expires_on: formattedDate, price: parsedPrice },
      files
    );
    // TODO: handle error e.g. 422
    setLoading(false);
    handleClose();
    matomo.trackEvent(
      "My Listing Detail",
      "Edit Listing",
      updatedListing.data.id
    );
    if (dispatch) {
      dispatch({
        type: "updateListing",
        payload: updatedListing.data,
      });
    }
  };
  if (!Object.keys(listing).length) return <h1>Loading</h1>;
  return (
    <Modal
      {...{
        handleClose,
        title: "Edit Listing",
        loading,
      }}
    >
      <div className="pb-5">
        <Formik
          initialValues={{
            name,
            price,
            types: types || [],
            building_number,
            street_address,
            city,
            state,
            postal_code,
            expires_on: expires_on ? formatDate(expires_on) : "",
            description: description || "",
            is_private,
            capitalization_percent,
            lease_term,
          }}
          validate={validate}
          headerText="Edit Listing"
          onSubmit={handleSubmit}
        >
          {(form) => (
            <div className="d-flex align-items-center p-4">
              <Form>
                <FormGroup>
                  <Input
                    name="name"
                    label="Name"
                    isRequired
                    inputAttributes={{
                      className: "form-control",
                      type: "text",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="d-flex align-items-center">
                    <span className="mr-1">Private Listing</span>
                    <Tooltip
                      content={
                        <div>
                          Private listings will not show up for buyers, unless
                          you have shared it with them directly by creating an
                          opportunity on their requirement.
                        </div>
                      }
                    />
                  </Label>
                  <Toggle name="is_private" />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="price"
                    label="Price"
                    placeholder="Price"
                    type="number"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="capitalization_percent"
                    label="Cap Rate(%)"
                    inputAttributes={{
                      type: "number",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="lease_term"
                    label="Lease Term(Years)"
                    inputAttributes={{
                      type: "number",
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="d-flex align-items-center">
                    <span className="mr-1">Expiration Date</span>
                    <Tooltip
                      content={
                        <div>
                          Listings will not show up on the For Sale page or as
                          leads for requirements after they expire.
                        </div>
                      }
                    />
                  </Label>
                  <DatePickerField name="expires_on" className="form-control" />
                </FormGroup>
                <TypeSelect
                  name="types"
                  form={form}
                  label="Preferred property type(s)"
                />
                <FormGroup>
                  <Label>Listing address</Label>
                  <Field name="street">
                    {({ form }) => {
                      const addressObject = {
                        building_number: form.values?.building_number || "",
                        street_address: form.values?.street_address || "",
                        city: form.values?.city || "",
                        state: form.values?.state || "",
                        postal_code: form.values?.postal_code || "",
                      };
                      const typeaheadProps = listing.address
                        ? { defaultSelected: [addressObject] }
                        : {};
                      return (
                        <AddressGeocodeField
                          form={form}
                          typeaheadProps={typeaheadProps}
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Label>Photo Upload</Label>
                  <input
                    type="file"
                    className="form-control-file"
                    multiple={true}
                    onChange={(val) => {
                      setFiles(val.target.files);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Description</Label>
                  <Field
                    as="textarea"
                    name="description"
                    className="form-control"
                  />
                </FormGroup>
                <Button
                  buttonAttributes={{ type: "submit" }}
                  theme="primary"
                  disabled={!form.isValid || !(form.dirty || files.length)}
                >
                  Save Changes
                </Button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
