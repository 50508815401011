import React, { useEffect, useState } from "react";
import { useListProvider } from "providers/list";
import AsyncSelect from "react-select/async";
import { getApiInstance } from "~utilities/api";
import { useMsal } from "@azure/msal-react";

export default function RegionFilterSelect() {
  const { state, dispatch } = useListProvider();
  const [selectedRegions, selectRegions] = useState([]);
  const [regions, setRegions] = useState([]);
  const msal = useMsal();

  const handleSelectChange = (e) => {
    const { region_ids, ...rest } = state.params.where;
    selectRegions(e);
    dispatch({
      type: "UPDATE_PARAMS",
      params: {
        where: {
          ...rest,
          ...(selectedRegions && { region_ids: e.map((r) => r.id) }),
        },
      },
    });
  };

  useEffect(() => {
    async function fetch() {
      const region_ids = state.params.where.region_ids;
      if (region_ids && region_ids.length > 0) {
        const params = {
          limit: 100,
          where: {
            id: state.params.where.region_ids,
          },
        };
        const { data } = await getApiInstance(msal, "/regions", params);
        selectRegions(data);
      } else {
        selectRegions([]);
      }
    }
    fetch();
  }, [state.params.where.region_ids, msal]);

  const searchRegions = async (query) => {
    if (regions.length > 0) {
      return regions.filter((r) =>
        r.name.toLowerCase().includes(query.toLowerCase().trim())
      );
    } else {
      let params =
        query && query.length > 0
          ? {
              limit: 1000,
              where: {
                q: query,
              },
            }
          : { limit: 1000 };
      const { data } = await getApiInstance(msal, "/regions", params);
      setRegions(data);
      return data;
    }
  };

  return (
    <AsyncSelect
      placeholder="Type in region (city, state or region)"
      isMulti={true}
      value={selectedRegions}
      onChange={handleSelectChange}
      isClearable={true}
      defaultOptions
      loadOptions={searchRegions}
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
    />
  );
}
