import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "~utilities/styles";

const StyledLoadingWrapper = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Loading() {
  return (
    <StyledLoadingWrapper>
      <FontAwesomeIcon icon="spinner" size="2x" color={colors.primary} pulse />
    </StyledLoadingWrapper>
  );
}
