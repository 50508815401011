import React from "react";
import { Field } from "formik";
import { colors } from "~utilities/styles";
import styled from "styled-components";

const switchSize = 14;

const ToggleSwitch = styled.div`
  pointer-events: none;
  background-color: ${colors.gray["300"]};
  border-radius: 50%;
  height: ${switchSize}px;
  width: ${switchSize}px;
  transform-origin: left;
  transition: 0.2s;
  ${(props) =>
    props.switchOn &&
    `background-color: ${colors.primary};  transform: translateX(${switchSize}px)`}
`;

const ToggleWrapper = styled.div`
  cursor: pointer;
  background-color: ${colors.gray["100"]};
  border: 1px solid ${colors.gray["200"]};
  padding: 2px;
  border-radius: ${switchSize}px;
  width: ${2.5 * switchSize}px;
`;

export default function Toggle({ name }) {
  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <ToggleWrapper
            {...field}
            id={name}
            onClick={(e) => {
              e.currentTarget.value = !field.value;
              field.onChange(e);
            }}
          >
            <ToggleSwitch switchOn={field.value} />
          </ToggleWrapper>
        );
      }}
    </Field>
  );
}
