import React, { forwardRef, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { colors } from "~utilities/styles";
import styled from "styled-components";
import "tippy.js/animations/shift-away.css";

const TooltipContent = styled.div`
  background: ${colors.gray["600"]};
  color: ${colors.foreground};
  border-radius: 4px;
`;

const TriggerWrapper = styled.span((props) => ({
  ...props.triggerStyles,
}));

const Trigger = forwardRef(({ triggerStyles, children }, ref) => {
  return (
    <TriggerWrapper ref={ref} triggerStyles={triggerStyles}>
      {children ? (
        children
      ) : (
        <FontAwesomeIcon
          icon="question-circle"
          size="1x"
          color={colors.gray["300"]}
        />
      )}
    </TriggerWrapper>
  );
});

export default function Tooltip({
  content,
  tippyProps,
  triggerStyles,
  children,
  cssEmbed,
}) {
  const tippyEl = useRef(null);
  return (
    <span
      className={
        cssEmbed ? "position-relative ml-2" : "d-flex position-relative"
      }
    >
      <Tippy
        ref={tippyEl}
        animation="shift-away"
        content={
          <TooltipContent className="p-3 box-shadow">{content}</TooltipContent>
        }
        {...tippyProps}
      >
        <Trigger triggerStyles={triggerStyles}>{children}</Trigger>
      </Tippy>
    </span>
  );
}
