import React from "react";
import Popover from "~components/Popover";
import Loading from "~components/Loading";
import { formatCurrencyRange } from "~utilities/format";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import { getActions, Label, DisplayText, placeholder } from "./utils";
import { useMsal } from "@azure/msal-react";
import { getApiInstance } from "~utilities/api";

const StyledCard = styled.div`
  display: flex;
  margin-top: 6px;
  background: ${colors.foreground};
  border-radius: 6px;
  position: relative;
  border: 1px solid ${colors.gray["200"]};

  ${({ card }) =>
    card.listing_id &&
    !card.is_active &&
    `background: ${colors.gray["100"]}; color: ${colors.gray["400"]};`}
`;

const WarningBar = styled.div`
  position: absolute;
  left: -2px;
  top: 0;
  bottom: 0;
  border-radius: 3px;
  width: 6px;
  background: ${colors.warning};
`;

const ClickableDiv = styled.div`
  cursor: pointer;

  p {
    margin-bottom: 0;
  }
`;

const WarningMessage = styled.div`
  color: ${colors.warning};
`;

const BuyerZeroState = () => {
  return (
    <div>
      You have no requirements, create one to start a conversation with the
      creator of this listing.
    </div>
  );
};

const SellerZeroState = () => {
  return <div>This listing has no opportunities or leads.</div>;
};

export const ListingCard = ({
  handleClick,
  listing,
  closeModal,
  dispatch,
  currentUser,
  card,
  isSeller,
}) => {
  const msal = useMsal();
  const actionCallback = (action) => {
    dispatch(action);
    closeModal();
  };
  const isOpportunity = card.listing_id;
  const actions = getActions(card, listing, currentUser, msal, actionCallback);
  const onClick = () => {
    const selectedCard = isOpportunity
      ? { opportunity: card }
      : { requirement: card };
    handleClick(selectedCard);
  };
  const {
    name,
    min_price,
    max_price,
    user: cardUser,
  } = isOpportunity ? card.requirement : card;
  const [firstComment] = card?.comments || [];
  const showWarning =
    isOpportunity && firstComment && firstComment?.user_id !== currentUser.id;
  const menuItems = [{ text: "View", action: onClick }, ...actions];
  const requirementText = isSeller ? "Lead" : "Requirement";

  const [user, setUser] = React.useState(cardUser);

  React.useEffect(
    function () {
      if (user) {
        return;
      }
      if (isOpportunity) {
        setUser(card.requirement.user);
        return;
      }
      const toInvoke = async function () {
        const { data } = await getApiInstance(msal, "/users/byid", {
          id: card.user_id,
        });
        setUser(data);
      };
      toInvoke();
    },
    [msal, card, user, cardUser, isOpportunity]
  );

  return !user ? (
    <Loading></Loading>
  ) : (
    <StyledCard card={card}>
      <ClickableDiv className="d-flex p-4 w-100 truncate" onClick={onClick}>
        {showWarning && <WarningBar />}
        <div className="row px-3 truncate" style={{ flex: 1 }}>
          <div className="col-md-12">
            <h5 className="mb-2">
              <strong>{isOpportunity ? "Opportunity" : requirementText}</strong>
            </h5>
          </div>
          <div className="col-md-6">
            <Label>Name:</Label>
            <DisplayText text={name} />
            <Label>Price:</Label>
            <p>
              {min_price
                ? formatCurrencyRange(min_price, max_price)
                : placeholder}
            </p>
          </div>
          <div className="col-md-6">
            <Label>Contact:</Label>
            <div>
              <div>{user.name}</div>
              <div className="link-primary">{user.email}</div>
            </div>
          </div>
        </div>
        {isOpportunity && (
          <div className="d-flex flex-column text-right">
            <div className="text-capitalize">
              <strong>Status: </strong>
              {card.is_active &&
              card.requirement.is_open &&
              card.requirement.is_active &&
              listing.is_active
                ? "Open"
                : "Closed"}
            </div>
            <div>
              {`${card.comments?.length || "No "} 
              ${card.comments?.length === 1 ? "Comment" : "Comments"} `}
            </div>
            {showWarning && (
              <WarningMessage>
                <em>New Message!</em>
              </WarningMessage>
            )}
          </div>
        )}
      </ClickableDiv>
      <div className="p-4 mt-1">
        <Popover
          items={menuItems}
          width={160}
          tippyProps={{ placement: "auto", offset: [0, -15] }}
        />
      </div>
    </StyledCard>
  );
};

export default function ListingCards({
  handleClick,
  listing,
  closeModal,
  dispatch,
  cardList,
  currentUser,
  userList,
}) {
  const isSeller = currentUser.id === listing.user_id;
  const ZeroStateRenderer = isSeller ? SellerZeroState : BuyerZeroState;
  if (!cardList) {
    return (
      <div className="d-flex align-items-center px-5 py-3">
        <Loading />
      </div>
    );
  }
  return (
    <section className="px-3 pb-2 flex-grow-1 overflow-auto">
      {!cardList.length ? (
        <ZeroStateRenderer />
      ) : (
        cardList.map((item) => {
          return (
            <ListingCard
              key={item.id}
              {...{
                card: item,
                handleClick,
                closeModal,
                dispatch,
                listing,
                currentUser,
                isSeller,
                userList,
              }}
            />
          );
        })
      )}
    </section>
  );
}
