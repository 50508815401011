import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable, useSortBy } from "react-table";
import { colors } from "~utilities/styles";
import { formatDate } from "~utilities/format";

const StyledHeader = styled.div`
  ${(props) =>
    props.active &&
    `font-weight: 700; color: ${colors.primary}` +
      `background-color: transparent`}
`;

const StyledCell = styled.div`
  overflow: hidden;
  width: 100%;
  ${({ column }) =>
    css`
      ${column.columnWidth &&
      `max-width: ${column.columnWidth}; min-width: ${column.columnWidth};`}
      ${column.centerContent && `text-align: center`}
    `}
`;

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ tableHeight }) => `max-height: ${tableHeight ? tableHeight : "50vh"};`}
  .tr {
    display: flex;
    align-items: start;
  }
  .thead {
    position: sticky;
    top: 0;
    margin-bottom: 5px;
    background: ${colors.background};
    color: ${colors.gray["300"]};
    .th {
      color: inherit;
      padding: 10px 0px 0px 10px;
      div {
        white-space: nowrap;
      }
    }
    .th:hover {
      color: ${colors.primary};
    }
  }
  .tbody {
    overflow: auto;
    .tr {
      border: 1px solid ${colors.gray["200"]};
      border-radius: 4px;
      margin-top: 10px;
      background: ${colors.foreground};
      a {
        display: block;
        padding: 1rem;
        color: inherit;
      }
      &:hover {
        a {
          text-decoration: none;
          color: ${colors.primaryHover};
        }
      }
    }
  }
`;

const TableTitle = styled.div`
  background: ${colors.background};
  magin-bottom: 1px;
  font-size: 1.28rem;
`;

export const DateCell = (props) => {
  return formatDate(props.value);
};

export const TitleCell = (props) => {
  return <strong>{props.value}</strong>;
};

export default function Table({
  resourcePath,
  columns,
  data,
  tableHeight,
  title,
}) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <StyledTable tableHeight={tableHeight} {...getTableProps()}>
      {title && (
        <TableTitle className="py-3">
          <strong>{title}</strong>
        </TableTitle>
      )}
      <div className="thead">
        {headerGroups.map((headerGroup) => (
          <div className="tr" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledCell
                column={column}
                className="th"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <StyledHeader
                  active={column.isSorted}
                  className={!column.isSorted && "truncate"}
                >
                  {column.render("Header")}
                  {column.isSorted && (
                    <FontAwesomeIcon
                      className="ml-1"
                      icon={column.isSortedDesc ? "chevron-down" : "chevron-up"}
                      size="sm"
                    />
                  )}
                </StyledHeader>
              </StyledCell>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <div
              className="tr"
              key={`${resourcePath}-row-${i}`}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => {
                return cell.column.id !== "action" ? (
                  <StyledCell
                    className="td"
                    column={cell.column}
                    {...cell.getCellProps()}
                  >
                    <Link to={`/${resourcePath}/${row.original.id}`}>
                      <div className="truncate">{cell.render("Cell")}</div>
                    </Link>
                  </StyledCell>
                ) : (
                  <div key={row.original.id}>{cell.render("Cell")}</div>
                );
              })}
            </div>
          );
        })}
      </div>
    </StyledTable>
  );
}
