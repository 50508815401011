import ReactDOM from "react-dom";
import React from "react";
import styled, { css } from "styled-components";
import { colors, zIndex, breakpoints, setBreakPoint } from "~utilities/styles";
import CloseButton from "~components/CloseButton";
import Loading from "~components/Loading";

const modalRoot = document.getElementById("root");
const thumbnailSize = 140;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${colors.overlay};
  z-index: ${zIndex.modal};
  display: flex;
  justify-content: center;

  ${setBreakPoint({
    size: "md",
    styles: `padding: 3rem 0;`,
  })}
`;

const StyledLoadingOverlay = styled.div`
  background: ${colors.overlayDark};
  z-index: ${zIndex.base};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`;

const Body = styled.div`
  background: ${colors.foreground};
  z-index: ${zIndex.modal};
  width: ${breakpoints.sm}px;
  min-width: min(${breakpoints.sm}px, 100%)
  height: 100%;
  overflow: auto;
  position: relative;

  ${setBreakPoint({
    size: "md",
    styles: `height: 800px;`,
  })}
`;

const Header = styled.div`
  background: ${colors.secondary};
  color: ${colors.foreground};
  z-index: ${zIndex.elevated};
  position: sticky;
  top: 0;
`;

const StyledLabel = styled.div`
  font-size: 0.9rem;
`;

const StyledDivider = styled.div`
  background: ${colors.gray["100"]};
  padding: 0.5rem 0;
  font-size: 0.9rem;
`;

const StyledAside = styled.div`
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  min-width: min(${breakpoints.sm}px, 100%);
  width: calc(100% - ${breakpoints.sm + 50}px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${setBreakPoint({
    size: "md",
    mediaSetting: "max-width",
    styles: `width: 100%`,
  })}

  ${({ modalBody }) =>
    modalBody &&
    setBreakPoint({
      size: breakpoints.sm * 2,
      mediaSetting: "max-width",
      styles: `position: fixed; top: 0; left: 0; bottom: 0; z-index: ${
        zIndex.modal + 10
      }; background: rgba(0, 0, 0, 0.95); width: 100%;`,
    })}
`;

const ImageContainer = styled.div`
  flex: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${({ url }) =>
    css`
      background-image: url("${url}");
    `}
`;

const AsideThumbnails = styled.div`
  width: 100%;
  overflow: auto;
  background: ${colors.gray["600"]};
`;

const StyledThumbnail = styled.div`
  height: ${thumbnailSize}px;
  width: ${thumbnailSize}px;
  min-height: ${thumbnailSize}px;
  min-width: ${thumbnailSize}px;
  background-size: cover;
  opacity: 0.5;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
  ${({ url, isSelected }) =>
    css`
      background-image: url("${url}");
      ${isSelected && `opacity: 1;`}
    `}
`;

export const Label = ({ children }) => {
  return (
    <StyledLabel>
      <strong>{children}</strong>
    </StyledLabel>
  );
};

const Aside = ({ asideUrls, selectedUrl, setSelectedUrl, modalBody }) => {
  return (
    <StyledAside modalBody={modalBody}>
      <section className="position-relative justify-content-end p-3">
        <CloseButton handleClick={() => setSelectedUrl()} size="lg" />
      </section>
      <ImageContainer url={selectedUrl} className="m-3" />
      <AsideThumbnails className="d-flex mt-3 p-3">
        {asideUrls?.map((url) => {
          return (
            <StyledThumbnail
              key={url}
              url={url}
              className="mr-3 box-shadow"
              onClick={() => setSelectedUrl(url)}
              isSelected={selectedUrl === url}
            />
          );
        })}
      </AsideThumbnails>
    </StyledAside>
  );
};

const LoadingOverlay = () => {
  return (
    <StyledLoadingOverlay className="d-flex align-items-center justify-content-center">
      <Loading />
    </StyledLoadingOverlay>
  );
};

export const Divider = ({ children }) => {
  return (
    <StyledDivider className="d-flex px-4 my-4">
      <Label>{children}</Label>
    </StyledDivider>
  );
};

export default function Modal({
  handleClose,
  title,
  asideUrls,
  selectedUrl,
  setSelectedUrl,
  loading,
  children,
}) {
  const overlayRef = React.useRef(null);
  const onClickOutside = (e) => {
    if (overlayRef?.current === e.target) {
      handleClose();
    }
  };
  if (!modalRoot) return null;
  return ReactDOM.createPortal(
    <Overlay ref={overlayRef} onClick={onClickOutside}>
      {children && (
        <Body>
          <Header className="d-flex justify-content-between align-items-center p-3">
            {title && <strong>{title}</strong>}
            <CloseButton handleClick={handleClose} size="lg" />
          </Header>
          <div className="overflow-auto position-relative">
            <>
              {loading && <LoadingOverlay />}
              {children}
            </>
          </div>
        </Body>
      )}
      {selectedUrl && (
        <Aside
          {...{
            asideUrls,
            selectedUrl,
            setSelectedUrl,
            modalBody: children,
          }}
        />
      )}
    </Overlay>,
    modalRoot
  );
}
