import Tooltip from "components/Tooltip";

function formatDate(date, format) {
  if (!date) {
    return "";
  }
  // if date is in yyyy-mm-dd format switch it to yyyy/mm/dd if we dont
  // change the format new Date returns a date string at the previous day
  const jsDate = `${date}`.match(/\d{4}[-]\d{2}[-]\d{2}$/)
    ? new Date(date.split("-").join("/"))
    : new Date(date);
  const formattedDate = jsDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
}

function getISODateFromString(date) {
  if (!date) {
    return date;
  }
  return new Date(date).toISOString();
}

function getNumericString(str) {
  return (str || "").toString().replace(/[^0-9]/g, "");
}

function getIntFromString(str) {
  return parseInt(getNumericString(str)) || 0;
}

function formatCurrency(currency) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(currency);
}

function formatLeaseTerm({ min, max }) {
  return max ? `${min}-${max}` : min ? `${min}+` : undefined;
}

function formatCurrencyRange(minPrice, maxPrice) {
  if (typeof minPrice != "number") return "Unspecified";

  const formattedMinPrice = formatCurrency(minPrice);

  if (!maxPrice) return formattedMinPrice;

  const formattedMaxPrice = formatCurrency(maxPrice);

  return `${formattedMinPrice} - ${formattedMaxPrice}`;
}

function formatCurrencyCompact(currency) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
  }).format(currency);
}
function formatCurrencyRangeCompact(minPrice, maxPrice) {
  if (typeof minPrice != "number") return "Unspecified";

  const formattedMinPrice = formatCurrencyCompact(minPrice);

  if (!maxPrice) return formattedMinPrice;

  const formattedMaxPrice = formatCurrencyCompact(maxPrice);

  return `${formattedMinPrice} - ${formattedMaxPrice}`;
}
function formatPercent(percent, digits = 2) {
  // percent: number to format, can be string
  // digits: optional, number of decimal digits to fix the percent
  if (percent) {
    const roundedPercent = `${percent}`.includes(".")
      ? parseFloat(percent).toFixed(digits)
      : percent;

    return `${roundedPercent}%`;
  }
  return "";
}

function formatStreetAddress(building_number, street_address) {
  let formatted = "";
  if (street_address && building_number) {
    formatted += building_number + " " + street_address;
  } else {
    formatted += street_address || "";
    formatted += building_number || "";
  }
  return formatted;
}

function formatCityAndState(city, state) {
  let formatted = "";
  if (city && state) {
    formatted += city + ", " + state;
  } else {
    formatted += city || "";
    formatted += state || "";
  }
  return formatted;
}

function formatAddress(
  building_number,
  street_address,
  city,
  state,
  postal_code
) {
  const first_line = formatStreetAddress(building_number, street_address);
  const second_line = formatCityAndState(city, state);
  let full_address = "";
  full_address += first_line || "";
  if (first_line && second_line) {
    full_address += ", ";
  }
  full_address += second_line || "";
  if (second_line && postal_code) {
    full_address += " ";
  }
  return full_address;
}

function formatTypeWithTooltip(propertyType, isTooltip = true) {
  switch (propertyType) {
    case "STNL":
      return isTooltip ? (
        <Tooltip content={<span>Single Tenant Net Lease</span>}>
          {propertyType}
        </Tooltip>
      ) : (
        "Single Tenant Net Lease"
      );
    default:
      return propertyType;
  }
}

export {
  formatCurrency,
  formatCurrencyRange,
  formatCurrencyRangeCompact,
  formatDate,
  getISODateFromString,
  getNumericString,
  getIntFromString,
  formatPercent,
  formatStreetAddress,
  formatCityAndState,
  formatAddress,
  formatLeaseTerm,
  formatTypeWithTooltip,
};
