import React, { useCallback, useState } from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Button from "components/Button";
import { useListProvider } from "providers/list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import filterLogo from "~assets/img/filter.svg";
import { ReactComponent as GridLogo } from "~assets/img/grid.svg";
import { ReactComponent as ListLogo } from "~assets/img/list.svg";
import ToggleButton from "./ToggleButton";
import { ViewSelection } from "~reducers/list";

const SubheadingText = styled.div`
  font-size: 1rem;
`;

const NavSection = styled.section`
  background: ${colors.foreground};
  border-bottom: 1px solid ${colors.gray["300"]};
  padding: 18px 0;
`;

const WelcomeText = styled.div`
  font-size: 1.5rem;
`;

const BadgeFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -3px;
  right: 10px;
`;

const StyledContainer = styled(Container)`
  max-height: calc(100vh - 44px);
  overflow: hidden;
`;
const ImgWrapper = styled.div`
  text-align: center;
  overflow: hidden;
  margin: 0 10px 0 0;
`;
const StyledImg = styled.img`
  height: 1.5rem;
  width: auto;
`;

const showFilterSelected = (where) => {
  return Boolean(
    where.user_id?.not?.length ||
      where.types?.overlaps?.length ||
      where.region_ids?.length ||
      where.is_active?.length === 0 ||
      where.is_active?.some((ele) => ele === false)
  );
};

export default function Layout({ children }) {
  const { state, dispatch } = useListProvider();
  const filterSelected = showFilterSelected(state.params.where);
  const handleFilterChange = useCallback(
    (e) => {
      dispatch({
        type: "UPDATE_DISPLAY_FILTER",
        payload: !state.displayFilter,
      });
    },
    [state.displayFilter, dispatch]
  );
  const [viewSelected, setViewSelected] = useState(ViewSelection.Grid);
  const onFilterItemSelect = function (newlySelectedView) {
    return function () {
      setViewSelected(newlySelectedView);
      dispatch({
        type: "UPDATE_VIEW_SELECTION",
        payload: newlySelectedView,
      });
    };
  };

  const ViewSelectionButton = ({ view, Logo, disabled }) => {
    return (
      <ToggleButton
        disabled={disabled}
        selected={viewSelected === view}
        onClick={onFilterItemSelect(view)}
      >
        <Logo
          stroke={viewSelected === view ? colors.iconStroke : colors.gray[400]}
          fill="none"
        ></Logo>
      </ToggleButton>
    );
  };

  return (
    <StyledContainer fluid>
      <NavSection className="row px-4 ">
        <div className="d-flex col-sm-3">
          <WelcomeText className="d-flex align-items-center ">
            For sale
          </WelcomeText>
        </div>
        <div className="d-flex col-sm-6">
          <SubheadingText className="d-flex align-items-center">
            Find sellers listing properties that match your buying requirements
          </SubheadingText>
        </div>
        <div className="d-flex mb-2 col-sm-3 justify-content-end">
          <div className="d-flex  align-items-center justify-content-end ">
            <ViewSelectionButton view={ViewSelection.Grid} Logo={GridLogo} />
            <ViewSelectionButton view={ViewSelection.List} Logo={ListLogo} />
          </div>
          <div className="pl-5 d-flex justify-content-end">
            <Button
              name="displayFilter"
              theme={state.displayFilter ? "primary" : "secondary"}
              onClick={handleFilterChange}
            >
              <div className="d-flex align-items-center">
                <div className="mr-auto ml-0 pl-0">Filters</div>
                <ImgWrapper className="ml-2 mr-0 pr-0">
                  <StyledImg src={filterLogo} />
                </ImgWrapper>
              </div>
            </Button>
            {filterSelected && (
              <BadgeFontAwesomeIcon
                icon="circle"
                size="xs"
                color={colors.warning}
                title="Filter(s) Selected"
              />
            )}
          </div>
        </div>
      </NavSection>
      {children}
    </StyledContainer>
  );
}
