import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => (
  <div className="p-5">
    <h3 className="mb-3">Looks like there's nothing here.</h3>
    <Link to="/">Back to Home</Link>
  </div>
);

export default Error404;
