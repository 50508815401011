import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useShowProvider } from "~providers/show";
import Loading from "~components/Loading";
import FullScreenLayout from "~components/pages/FullScreenLayout";
import {
  formatCurrencyRange,
  formatDate,
  formatPercent,
  formatLeaseTerm,
} from "~utilities/format";
import RequirementContent from "./components/RequirementContent";
import { Label, DisplayText } from "./components/utils";
import { useAuth } from "../../../providers/auth";
import BadgeList from "~components/BadgeList";
import { useApi } from "~utilities/api";
import styled from "styled-components";
import { colors, setBreakPoint } from "~utilities/styles";
import Button from "~components/Button";
import EditModal from "./../edit/index";
import { getApiInstance } from "utilities/api";
import { useMsal } from "@azure/msal-react";
import Tooltip from "~components/Tooltip";
import * as matomo from "../../../matomo.js";

const ContentSection = styled.section`
  background: ${colors.gray["100"]};
  flex-wrap: wrap;
  overflow: auto;

  ${setBreakPoint({
    size: "md",
    styles: `overflow: hidden; flex-wrap: no-wrap;`,
  })}
`;

const DetailsPanel = styled.div`
  background: ${colors.foreground};
`;

const CardsPanel = styled.div`
  ${setBreakPoint({
    size: "md",
    styles: `max-height: 100%;`,
  })}
`;

const toggleOpen = async (msal, { id, is_open }, dispatch) => {
  const { data } = await getApiInstance(
    msal,
    `/requirements/${id}/set_open`,
    {},
    "post",
    { is_open: !is_open }
  );
  matomo.trackEvent(
    "My Requirement Detail",
    `${!is_open ? "Open" : "Close"} Requirement`,
    data?.id
  );
  dispatch({
    type: "updateRequirement",
    payload: data,
  });
};
const toggleActive = async (msal, { id, is_active }, dispatch) => {
  const { data } = await getApiInstance(
    msal,
    `/requirements/${id}/set_active`,
    {},
    "post",
    { is_active: !is_active }
  );
  matomo.trackEvent(
    "My Requirement Detail",
    `${!is_active ? "Deactivate" : "Activate"} Requirement`,
    data?.id
  );
  dispatch({
    type: "updateRequirement",
    payload: data,
  });
};

const SellerContent = ({ user, dispatch, store, requirement }) => {
  const [updatedState, setUpdatedState] = React.useState(false);
  const [listingsResponse] = useApi("/listings", {
    limit: 1000,
    include: {
      address: true,
      regions: true,
      image_urls: true,
      user: true,
    },
    where: {
      user: {
        email: user.email,
      },
    },
  });
  const [opportunitiesResponse] = useApi("/opportunities", {
    limit: 1000,
    include: {
      listing: {
        address: true,
        regions: true,
        image_urls: true,
        user: true,
      },
      comments: {
        body: true,
        user: true,
        document_urls: true,
      },
    },
    where: {
      requirement_id: requirement.id,
    },
  });
  React.useEffect(() => {
    if (
      !opportunitiesResponse.loading &&
      !listingsResponse.loading &&
      !updatedState
    ) {
      setUpdatedState(true);
      dispatch({
        type: "setStore",
        payload: {
          ...requirement,
          ...{
            opportunities: opportunitiesResponse.data,
            leads: listingsResponse.data,
          },
        },
      });
    }
  }, [
    store,
    dispatch,
    opportunitiesResponse,
    listingsResponse,
    updatedState,
    requirement,
  ]);
  if (opportunitiesResponse.loading || listingsResponse.loading) {
    <div className="d-flex align-items-center px-5 py-3">
      <Loading />
    </div>;
  }
  return (
    <RequirementContent
      {...{
        user,
        dispatch,
        store,
        requirement,
      }}
    />
  );
};

export default function Show(props) {
  const {
    request: [response],
    dispatch,
    store: requirement,
  } = useShowProvider();
  const msal = useMsal();
  const { user: currentUser } = useAuth();
  const { url } = useRouteMatch();
  const [showEditModal, setShowEditModal] = React.useState(false);
  React.useEffect(() => {
    if (!response.loading && !requirement) {
      dispatch({ type: "setStore", payload: response.data });
    }
  }, [response, requirement, dispatch]);
  if (response.loading || !requirement) return <Loading />;
  if (response.error) return <p>Error!</p>;
  const {
    user_id,
    is_open,
    is_active,
    name,
    user,
    note,
    min_price,
    max_price,
    types,
    regions,
    expires_on,
    min_capitalization_percent,
    minimum_lease_term,
    maximum_lease_term,
  } = requirement;
  const baseUrl = "/" + url.split("/")[1];
  const isBuyer = currentUser.id === user_id;
  const leaseTerm = formatLeaseTerm({
    min: minimum_lease_term,
    max: maximum_lease_term,
  });
  return (
    <FullScreenLayout
      {...{
        title: name,
        closeLink: baseUrl,
        titleLabel: "Requirement:",
        user,
      }}
    >
      <ContentSection className="d-flex flex-grow-1">
        <DetailsPanel className="col-sm-12 col-md-4 p-0">
          <div className="px-4 pb-4">
            {isBuyer && (
              <div className="d-flex align-items-center py-3">
                <Button
                  buttonAttributes={{ className: "mr-3" }}
                  theme="primary"
                  onClick={() => setShowEditModal(true)}
                >
                  Edit Requirement
                </Button>
                <Button
                  theme="secondary"
                  buttonAttributes={{ className: "mr-3" }}
                  onClick={() => toggleOpen(msal, requirement, dispatch)}
                >
                  {is_open ? (
                    <>
                      Close Requirement
                      <Tooltip
                        cssEmbed={true}
                        content={
                          <span>
                            If you close this requirement, users will no longer
                            be able to create opporunities.
                          </span>
                        }
                      />
                    </>
                  ) : (
                    "Open Requirement"
                  )}
                </Button>
                <Button
                  theme="secondary"
                  buttonAttributes={{ className: "mr-3" }}
                  onClick={() => toggleActive(msal, requirement, dispatch)}
                >
                  {is_active ? (
                    <>
                      Deactivate
                      <Tooltip
                        cssEmbed={true}
                        content={
                          <span>
                            If you deactivate this requirement, users will no
                            longer be able to create opporunities and this
                            requirement will be hidden to you by default (always
                            hidden to others).
                          </span>
                        }
                      />
                    </>
                  ) : (
                    "Activate"
                  )}
                </Button>
              </div>
            )}
            <div className="d-flex align-items-center">
              <h3 className="my-4 mr-4">Details</h3>
              {!is_open && (
                <div className="d-flex align-items-center mt-1">
                  <Tooltip
                    content={
                      <span>
                        This requirement is closed. You can not create any new
                        opportunities with this requirement.
                      </span>
                    }
                  />
                  <em className="ml-1">Closed</em>
                </div>
              )}
              {!is_active && (
                <div className="d-flex align-items-center mt-1">
                  <Tooltip
                    content={
                      <span>
                        This requirement is deactivated. You can not create any
                        new opportunities with this requirement.
                      </span>
                    }
                  />
                  <em className="ml-1">Deactivated</em>
                </div>
              )}
            </div>
            {note && <p>{note}</p>}
            <Label>Expires on</Label>
            <DisplayText text={expires_on && formatDate(expires_on)} />
            <Label>Price</Label>
            <DisplayText
              text={
                (min_price || max_price) &&
                formatCurrencyRange(min_price, max_price)
              }
            />
            <Label>Minimum Cap Rate</Label>
            <DisplayText
              text={
                min_capitalization_percent
                  ? formatPercent(min_capitalization_percent, 3)
                  : "Unspecified"
              }
            />
            <Label>Minimum Lease Term</Label>
            <DisplayText
              text={leaseTerm ? leaseTerm + " Years" : "Unspecified"}
            />
            <Label>Types</Label>
            {types.length ? <BadgeList items={types} /> : "Unspecified"}
            <Label className="mt-3">Regions</Label>
            {regions.length ? (
              <BadgeList items={regions.map((r) => r.name)} />
            ) : (
              "National"
            )}
          </div>
        </DetailsPanel>
        <CardsPanel className="col-sm-12 col-md-8 d-flex flex-column p-0">
          {isBuyer ? (
            <RequirementContent
              {...{
                user: currentUser,
                dispatch,
                store: requirement,
                requirement,
              }}
            />
          ) : (
            <SellerContent
              {...{
                user: currentUser,
                dispatch,
                store: requirement,
                requirement,
              }}
            />
          )}
        </CardsPanel>
        {showEditModal && (
          <EditModal
            requirement={requirement}
            handleClose={() => {
              setShowEditModal(false);
            }}
            dispatch={dispatch}
          />
        )}
      </ContentSection>
    </FullScreenLayout>
  );
}
