import React from "react";
import { colors, breakpoints } from "../../../utilities/styles";
import styled from "styled-components";
import { ReactComponent as PocketbookLogo } from "~assets/img/pocketbook.svg";

const Page = styled.div`
  background: ${colors.gray["600"]};
  width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  background: ${colors.secondary};
  max-width: ${breakpoints.md}px;
  max-height: 550px;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  a {
    color: ${colors.primary};
  }
`;

const LogoWrapper = styled.div`
  width: 50%;
`;

const FormWrapper = styled.div`
  background: ${colors.foreground};
  width: 50%;
  border-radius: 6px 0 0 6px;
`;

export default function AuthForm({ title, children }) {
  return (
    <Page className="d-flex align-items-center justify-content-center p-3">
      <Container className="d-flex box-shadow">
        <FormWrapper className="p-4">
          {title && <h4 className="mt-3 mb-4">{title}</h4>}
          {children}
        </FormWrapper>
        <LogoWrapper className="d-flex align-items-center justify-content-center">
          <PocketbookLogo />
        </LogoWrapper>
      </Container>
    </Page>
  );
}
