import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import { Formik, Form } from "formik";
import Input from "../../../components/Input";
import { useAuth } from "../../../providers/auth";
import { colors } from "../../../utilities/styles";
import styled from "styled-components";
import AuthForm from "../components/AuthForm";
import { validateEmail } from "../../../utilities/validations";

const FormError = styled.div`
  color: ${colors.error};
`;

const fieldValidate = ({ name, email, password, confirmPassword }, errors) => {
  if (!validateEmail(email)) {
    errors.email = "Invalid email";
  }
  if (!password) {
    errors.password = " ";
  }
  if (password !== confirmPassword) {
    errors.confirmPassword = "Your passwords do not match.";
  }
  if (!confirmPassword) {
    errors.confirmPassword = " ";
  }
  return errors;
};

export default function Register() {
  const [error, setError] = useState("");
  const auth = useAuth();
  const history = useHistory();
  const validate = (values) => {
    let errors = {};
    if (error) {
      setError();
    }
    return fieldValidate(values, errors);
  };
  async function handleSubmit({ name, email, password }) {
    const response = await auth.signup(name, email, password);
    if (!response || !response.name) {
      history.push("/register");
    }
    if (response.error) {
      setError("Something went wrong. Review the form and try again.");
    }
    if (response.name) {
      history.push("/login");
    }
  }

  return (
    <AuthForm title="Register">
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(form) => {
          return (
            <Form>
              <FormGroup size="lg" id="name">
                <Input autoFocus name="name" label="Name" isRequired />
              </FormGroup>
              <FormGroup size="lg" id="email">
                <Input autoFocus type="email" name="email" label="Email" />
              </FormGroup>
              <FormGroup size="lg" id="password">
                <Input type="password" name="password" label="Password" />
              </FormGroup>
              <FormGroup size="lg" id="confirmPassword">
                <Input
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                />
              </FormGroup>
              <Button
                block
                className="mb-3"
                size="lg"
                type="submit"
                disabled={!form.isValid}
              >
                Register
              </Button>
              {error && <FormError className="my-2">{error}</FormError>}
              {auth.user ? (
                <Link to="/">Back to Home</Link>
              ) : (
                <Link to="/login">Back to Login</Link>
              )}
            </Form>
          );
        }}
      </Formik>
    </AuthForm>
  );
}
