import React, { useState } from "react";
import {
  formatCurrencyRange,
  formatDate,
  formatPercent,
  formatLeaseTerm,
} from "~utilities/format";
import Comments from "~components/Comments";
import Modal, { Divider } from "~components/Modal";
import { getActions, Label, DisplayText, placeholder } from "./utils";
import Popover from "components/Popover";
import { getApiInstance } from "~utilities/api";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Tooltip from "~components/Tooltip";
import BadgeList from "~components/BadgeList";
import { useMsal } from "@azure/msal-react";
import * as matomo from "../../../../matomo.js";

const ClosedLabel = styled.div`
  color: ${colors.gray["600"]};
`;

const getCardFromStore = (opportunity, requirement, store) => {
  const cards = opportunity ? store.opportunities : store.leads;
  const id = opportunity?.id || requirement?.id;
  return cards.find((card) => card.id === id);
};

const canOpportunityBeCreated = (requirement, listing) => {
  return (
    requirement &&
    requirement.is_open &&
    requirement.is_active &&
    listing &&
    listing.is_active
  );
};

const canOpportunityBeCommented = (opportunity, requirement, listing) => {
  return (
    requirement &&
    requirement.is_open &&
    requirement.is_active &&
    listing &&
    listing.is_active &&
    opportunity.requirement_id &&
    opportunity.listing_id &&
    opportunity.is_active
  );
};

export default function CardModal({
  opportunity,
  requirement,
  currentUser,
  handleClose,
  title,
  dispatch,
  listing,
  store,
}) {
  const msal = useMsal();
  const [loading, setLoading] = useState();
  const card = getCardFromStore(opportunity, requirement, store);
  // requirement data to display, if card is an opportunity
  // key into the opportunity's requirement
  const {
    id,
    name,
    note,
    min_price,
    max_price,
    types,
    user,
    regions,
    expires_on,
    min_capitalization_percent,
    minimum_lease_term,
    maximum_lease_term,
  } = card.listing_id ? card?.requirement : card;
  const leaseTerm = formatLeaseTerm({
    min: minimum_lease_term,
    max: maximum_lease_term,
  });
  const actions = getActions(card, listing, currentUser, msal, dispatch);
  const isSeller = listing.user_id === currentUser.id;
  const commentAction = async (commentBody, documentBlobs) => {
    setLoading(true);
    //TODO: this should be one request
    const vars = {
      requirement_id: id,
      listing_id: listing.id,
    };
    // create opportunity
    const { data } = await await getApiInstance(
      msal,
      "/opportunities",
      {},
      "post",
      vars
    );
    matomo.trackEvent(
      isSeller ? "My Listing Detail" : "Listing Detail",
      "Create Opportunity",
      data.id
    );
    const commentVars = {
      comment: {
        opportunity_id: data.id,
        user_id: isSeller ? listing.user_id : requirement.user_id,
        body: commentBody,
        documents: documentBlobs,
      },
    };
    // add comment to opportunity
    const comments = await getApiInstance(
      msal,
      "/comments",
      {
        include: {
          user: true,
          document_urls: true,
        },
      },
      "post",
      commentVars
    );
    matomo.trackEvent(
      isSeller ? "My Listing Detail" : "Listing Detail",
      "Create comment with opportunity",
      comments.data.id,
      comments.data.document_urls?.length || 0
    );
    const newOpportunity = {
      ...data,
      listing_id: listing.id,
      requirement_id: card.id,
      requirement: card,
      listing,
      comments: [comments.data],
    };
    // add new opportunity with comment to the store
    dispatch({
      type: "addOpportunity",
      payload: { data: newOpportunity },
    });
    handleClose();
  };
  const isOpportunity = card.listing_id;
  requirement = isOpportunity ? card.requirement : requirement;
  const allowComments = isOpportunity
    ? canOpportunityBeCommented(card, requirement, listing)
    : canOpportunityBeCreated(requirement, listing);
  const isClosed = {
    opportunityClosed: isOpportunity && !card.is_active,
    requirementClosedOrDeactivated:
      !requirement.is_open || !requirement.is_active,
    listingClosed: !listing.is_active,
  };
  return (
    <Modal
      {...{
        handleClose,
        title,
        loading,
      }}
    >
      <div className="d-flex flex-column">
        <div className="px-4 pt-4">
          <Label>Requirement Name:</Label>
          <div className="d-flex justify-content-between w-100">
            <div>
              <h3 className="mb-3">{name}</h3>
              <div>
                <Label>Contact Information:</Label>
                <>{user.name}</>
              </div>
              <div>
                <a className="link-primary" href={`mailto:${user.email}`}>
                  {user.email}
                </a>
              </div>
            </div>
            <div className="d-flex align-items-start">
              {!allowComments && (
                <ClosedLabel className="d-flex mr-3 align-items-center">
                  <Tooltip
                    content={
                      <span>
                        {isClosed.opportunityClosed
                          ? "This opportunity is closed"
                          : isClosed.requirementClosedOrDeactivated
                          ? "This requirement is closed or deactivated"
                          : isClosed.listingClosed
                          ? "This listing is deactivated"
                          : "Error"}
                        , so you can no longer continue this conversation.
                      </span>
                    }
                  />
                  <em className="ml-1">Closed</em>
                </ClosedLabel>
              )}
              {actions && (
                <Popover
                  items={actions}
                  width={160}
                  tippyProps={{ offset: [0, -10], placement: "left" }}
                />
              )}
            </div>
          </div>
        </div>
        <Divider>{card.listing_id && "Requirement "} Details</Divider>
        <section className="px-4">
          {card.listing_id && (
            <span className="text-capitalize">
              <Label>Stage:</Label>
              <DisplayText text={card.stage} />
            </span>
          )}
          <Label>Expires on:</Label>
          <p>{expires_on ? formatDate(expires_on) : placeholder}</p>
          <Label>Price:</Label>
          <p>
            {min_price
              ? formatCurrencyRange(min_price, max_price)
              : placeholder}
          </p>
          <Label>Minimum Cap Rate:</Label>
          <p>
            {min_capitalization_percent
              ? formatPercent(min_capitalization_percent, 3)
              : placeholder}
          </p>
          <Label>Minimum Lease Term</Label>
          <DisplayText
            text={leaseTerm ? leaseTerm + " Years" : "Unspecified"}
          />
          <Label>Note:</Label>
          <DisplayText text={note} />
          <Label>Types:</Label>
          <div className="mb-3">
            {types.length ? (
              <BadgeList items={types} truncateList />
            ) : (
              "Unspecified"
            )}
          </div>
          <Label>Regions:</Label>
          <div className="mb-3">
            {regions?.length ? (
              <BadgeList
                items={regions.map((region) => region.name)}
                truncateList
              />
            ) : (
              "National"
            )}
          </div>
        </section>
        <Divider>Comments</Divider>
        <div className="px-4 pb-4">
          <Comments
            {...{
              displayOnly: !allowComments,
              userId: currentUser.id,
              dispatch,
              opportunityId: card.id,
              comments: card.comments,
              commentAction: !card.listing_id && commentAction,
              buttonText: !opportunity && "Comment and Qualify",
              cb: (comment) => {
                matomo.trackEvent(
                  isSeller ? "My Listing Detail" : "Listing Detail",
                  "Comment on opportunity",
                  comment.id,
                  comment.document_urls?.length || 0
                );
              },
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
