import React, { useState, useContext, createContext } from "react";
import {
  unauthenticatedApiInstance,
  getApiInstance,
  useApi,
} from "~utilities/api";
import { msalConfig } from "../MSALConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Loading from "~components/Loading";
import { setUserEmail } from "../matomo";

const msalInstance = new PublicClientApplication(msalConfig);

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  const isAutheticated = useIsAuthenticated();
  const [{ loading, data = {} }] = useApi("/users");
  if (loading && isAutheticated) {
    return <Loading />;
  }
  setUserEmail(data.email); // set user's email in matomo
  return (
    <authContext.Provider value={{ ...auth, user: data }}>
      {children}
    </authContext.Provider>
  );
};

export function ProvideAuth({ children }) {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>{children}</AuthProvider>
    </MsalProvider>
  );
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  let u = null;
  const msal = useMsal();

  if (
    localStorage.getItem("pocketbook_user") &&
    localStorage.getItem("pocketbook_authorization_token")
  ) {
    u = JSON.parse(localStorage.getItem("pocketbook_user"));
  }
  const [user, setUser] = useState(u);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email, password) => {
    try {
      const doSignIn = unauthenticatedApiInstance.post("/users/sign_in", {
        user: { email, password },
      });
      const response = await doSignIn;
      const authorization_token = response.headers.authorization.split(" ")[1];
      localStorage.setItem(
        "pocketbook_authorization_token",
        authorization_token
      );
      localStorage.setItem("pocketbook_user", JSON.stringify(response.data));
      setUser(response.data);

      return response.data;
    } catch (e) {
      return e.response.data;
    }
  };

  const signup = async (name, email, password) => {
    try {
      const doSignup = unauthenticatedApiInstance.post("/users", {
        user: { name, email, password },
      });
      const response = await doSignup;
      setUser(response.data.user);
      return response.data.user;
    } catch (e) {
      return e.response.data;
    }
  };

  const signout = async () => {
    const doSignout = getApiInstance(msal, "/users/sign_out", {}, "delete");
    await doSignout;
    setUser(null);
    localStorage.removeItem("pocketbook_authorization_token");
  };
  const sendPasswordResetEmail = async (email) => {
    try {
      return await unauthenticatedApiInstance.post("/users/password", {
        user: { email },
      });
    } catch (e) {
      return e.response.data;
    }
  };
  const resetPassword = async (resetToken, password, confirmation) => {
    try {
      return await unauthenticatedApiInstance.put(`/users/password`, {
        user: {
          reset_password_token: resetToken,
          password,
          confirmation,
        },
      });
    } catch (e) {
      return e.response.data;
    }
  };
  return {
    user,
    signin,
    signout,
    signup,
    sendPasswordResetEmail,
    resetPassword,
  };
}
