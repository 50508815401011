import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors, setBreakPoint } from "~utilities/styles";

const indicatorSize = 9;

const StyledNav = styled.nav`
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid ${colors.gray["200"]};
  ul {
    list-style-type: none;
    padding: 0 10px;
  }
  li {
    padding: 10px;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: ${colors.primary};
      }
    }
  }
  ${({ tableHeight }) =>
    setBreakPoint({
      size: "md",
      styles: `height: ${tableHeight || "100%"};`,
    })}
`;

const StyledItem = styled.li`
  margin-left: ${indicatorSize + 5}px;
  ${(props) => props.active && `font-weight: 700; margin-left: 0;`}
`;

const ActiveIndicator = styled.div`
  width: ${indicatorSize}px;
  height: ${indicatorSize}px;
  margin-right: 5px;
  background-color: ${colors.primary};
  border-radius: 50%;
`;

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function TableNav({ navItems, urlPath, tableHeight }) {
  const query = useQuery();
  const filter = query.get("filter");

  return (
    <StyledNav vertical tableHeight={tableHeight}>
      <ul>
        {navItems.map(({ label, navKey }, i) => {
          const link = navKey ? `/${urlPath}?filter=${navKey}` : `/${urlPath}`;
          const active = filter === navKey;
          return (
            <StyledItem
              className="d-flex align-items-center"
              key={i}
              active={active}
            >
              {active && <ActiveIndicator />}
              <Link to={link}>{label}</Link>
            </StyledItem>
          );
        })}
      </ul>
    </StyledNav>
  );
}
