import React from "react";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import { zIndex } from "~utilities/styles";
import CloseButton from "~components/CloseButton";

const StyledSection = styled.section`
  background: ${colors.background};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.elevated};
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.header`
  background: ${colors.secondary};
  color: #ffffff;

  h1 {
    font-weight: 700;
  }
`;

const StyledLi = styled.li`
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderAside = ({ headerDetails }) => {
  return (
    <ul className="col-md-4">
      {headerDetails.map(({ label, value }, i) => {
        return (
          <StyledLi key={i} className="mb-2">
            <div>
              <strong>{label}</strong>
            </div>
            <div>{value || "-"}</div>
          </StyledLi>
        );
      })}
    </ul>
  );
};

const DefaultHeader = ({ title, headerDetails, titleLabel, user }) => {
  return (
    <div className="row p-4">
      <div className="col-md-7">
        {titleLabel && <strong>{titleLabel}</strong>}
        <h1 className="truncate">{title}</h1>
        {user && (
          <>
            <strong className="mt-3 mb-1">Contact Information:</strong>
            <div className="truncate">{user.name}</div>
            <div className="truncate">
              <a
                className="link-primary truncate"
                href={`mailto:${user.email}`}
              >
                {user.email}
              </a>
            </div>
          </>
        )}
      </div>
      {headerDetails && <HeaderAside headerDetails={headerDetails} />}
    </div>
  );
};

export default function FullScreenLayout({
  title,
  titleLabel,
  headerDetails,
  closeLink,
  Header,
  user,
  children,
}) {
  // headerDetails is a list of objects [{label: "String", value: "Value"}]
  //    to be displayed in a table in the header.
  return (
    <StyledSection fluid>
      <StyledHeader>
        <CloseButton closeLink={closeLink} />
        {Header ? (
          <Header />
        ) : (
          <DefaultHeader {...{ headerDetails, title, titleLabel, user }} />
        )}
      </StyledHeader>
      {children}
    </StyledSection>
  );
}
