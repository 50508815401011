import React, { useState } from "react";
import { formatDate } from "~utilities/format";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Button from "~components/Button";
import Tooltip from "~components/Tooltip";
import { getApiInstance } from "~utilities/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DirectUpload } from "activestorage";
import env from "~utilities/../env";
import { useMsal } from "@azure/msal-react";

const StyledDate = styled.span`
  color: ${colors.gray["300"]};
`;

const CommentTextarea = styled.textarea`
  border-radius: 4px 4px 0 0;
  &:focus {
    outline: none;
  }
`;

const CommentTray = styled.div`
  color: ${colors.gray["400"]};
  border-radius: 0 0 4px 4px;
  background-color: ${colors.gray["100"]};
  border: 1px solid ${colors.gray["400"]};
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    cursor: pointer;
    &:hover {
      color: ${colors.primary};
    }
  }
`;

const CommentsSection = styled.section`
  input[type="file"] {
    display: none;
    pointer-events: none;
  }
`;

const RemoveDocument = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const StyledDocument = styled.div`
  border: 1px solid ${colors.gray["200"]};
  max-width: 110px;
  border-radius: 6px;
  background: ${colors.gray["100"]};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const AttachmentLink = styled.a`
  color: ${colors.primary};
  &:hover {
    color: ${colors.primaryDark};
  }
`;

const uploadDocuments = async (documents) => {
  return await Promise.all(
    documents.map((document) => {
      const railsActiveStorageDirectUploadsUrl = `${env.API_URL}/rails/active_storage/direct_uploads`;

      return new Promise((resolve, reject) => {
        const upload = new DirectUpload(
          document,
          railsActiveStorageDirectUploadsUrl
        );
        upload.create((error, blob) => {
          if (error) {
            reject(error);
          } else {
            // blob exists in active_storage_blobs
            resolve(blob.signed_id);
          }
        });
      });
    })
  );
};

export const DocumentList = ({ documents, setDocuments }) => {
  return (
    <div className="d-flex flex-wrap py-2">
      {documents?.map(({ name }) => {
        return (
          <Tooltip key={name} content={<div>{name}</div>}>
            <StyledDocument className="p-3 mr-2 mb-2 truncate">
              <FontAwesomeIcon
                icon={"file"}
                size="4x"
                color={colors.gray["200"]}
              />
              {setDocuments && (
                <RemoveDocument
                  className="px-3 py-2"
                  onClick={() => {
                    const updatedFileList = documents.filter((doc) => {
                      return doc.name !== name;
                    });
                    setDocuments(updatedFileList);
                  }}
                >
                  <FontAwesomeIcon
                    icon={"times"}
                    size="1x"
                    color={colors.gray["200"]}
                  />
                </RemoveDocument>
              )}
              <small className="mt-3 truncate">{name}</small>
            </StyledDocument>
          </Tooltip>
        );
      })}
    </div>
  );
};

export const comment = async (
  msal,
  opportunityId,
  userId,
  body,
  documentBlobs,
  cb
) => {
  const data = {
    comment: {
      opportunity_id: opportunityId,
      user_id: userId,
      body: body,
      documents: documentBlobs,
    },
  };
  const params = {
    include: {
      user: true,
      document_urls: true,
    },
  };
  const comments = await getApiInstance(
    msal,
    "/comments",
    params,
    "post",
    data
  );
  if (typeof cb === "function") {
    cb();
  }
  return comments.data;
};

const CommentsDisplay = ({ comments }) => {
  return comments.map(({ body, user, id, created_at, document_urls }) => {
    const date = formatDate(created_at);
    return (
      <div className="my-3" key={id}>
        <div>
          <strong className="mr-3">{user.name}</strong>
          <StyledDate>{date}</StyledDate>
        </div>
        <p>{body}</p>
        <div className="d-flex flex-wrap">
          {document_urls?.map((url, i) => {
            return (
              <AttachmentLink key={url} href={url}>
                <StyledDocument className="p-3 mr-2 mb-2 truncate">
                  <FontAwesomeIcon
                    icon={"file"}
                    size="4x"
                    color={colors.gray["200"]}
                  />
                  <small>Attachment {i + 1}</small>
                </StyledDocument>
              </AttachmentLink>
            );
          })}
        </div>
      </div>
    );
  });
};

export default function Comments({
  comments,
  opportunityId,
  userId,
  dispatch,
  commentAction,
  value,
  onChange,
  buttonText,
  displayOnly,
  disabledPredicate,
  cb,
}) {
  const msal = useMsal();
  const [commentState, setCommentState] = useState("");
  const isDisabled =
    typeof disabledPredicate === "function"
      ? disabledPredicate(commentState)
      : !commentState;
  const [documents, setDocuments] = useState([]);
  const handleChange = onChange || setCommentState;
  const addComment = async (_, documentBlobs) => {
    const newComment = await comment(
      msal,
      opportunityId,
      userId,
      commentState,
      documentBlobs,
      () => {
        handleChange("");
        setDocuments([]);
      }
    );
    if (dispatch) {
      dispatch({
        type: "comment",
        payload: newComment,
      });
    }
    if (typeof cb === "function") {
      cb(newComment);
    }
  };
  const handleComment = commentAction || addComment;
  return (
    <CommentsSection className="d-flex flex-column flex-1">
      {!displayOnly && (
        <>
          <CommentTextarea
            className="p-3"
            value={value || commentState}
            placeholder="Add a comment..."
            onChange={({ target }) => {
              handleChange(target.value);
            }}
          />
          <CommentTray className="py-2 pl-3 pr-2">
            <label className="m-0" htmlFor="document-upload">
              Add attachments
            </label>
            <input
              id="document-upload"
              type="file"
              multiple={true}
              onChange={(e) => {
                setDocuments([...documents, ...e.target.files]);
              }}
            />
            <div>
              <Button
                disabled={isDisabled}
                onClick={async () => {
                  const blobs = documents.length
                    ? await uploadDocuments(documents)
                    : [];
                  handleComment(value || commentState, blobs);
                }}
                theme={"primary"}
              >
                {buttonText || "Comment"}
              </Button>
            </div>
          </CommentTray>
          {documents && (
            <DocumentList documents={documents} setDocuments={setDocuments} />
          )}
        </>
      )}
      <div className="mb-3">
        <CommentsDisplay comments={comments} />
      </div>
    </CommentsSection>
  );
}

Comments.defaultProps = { comments: [] };
