import { FieldArray } from "formik";
import { Badge, FormGroup, Label, FormText } from "reactstrap";
import styled from "styled-components";
import { formatTypeWithTooltip } from "utilities/format";

const StyledBadge = styled(Badge)`
  font-size: 1.125em;
  padding: 0.5em;
  margin: 0 0.125em;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const StyledLabel = styled(Label)`
  cursor: pointer;
`;

export default function TypeSelect({ name, fieldText, form, label }) {
  return (
    <FieldArray
      name={name}
      render={({ remove, push }) => {
        const resetTypes = (e) => form.setFieldValue("types", []);
        const toggleType = (e) => {
          const type = e.target.value;
          const index = form.values.types.findIndex((t) => t === type);
          return form.values.types.includes(type) ? remove(index) : push(type);
        };
        return (
          <FormGroup>
            {label && <Label>{label}</Label>}
            <div>
              <StyledBadge
                color={form.values.types.length === 0 ? "primary" : "secondary"}
                className="mb-1 mr-1"
              >
                <StyledLabel className="text-capitalize font-weight-normal mb-0">
                  Anything
                  <input
                    className="sr-only"
                    type="checkbox"
                    onChange={resetTypes}
                  />
                </StyledLabel>
              </StyledBadge>
              {[
                "multifamily",
                "office",
                "retail",
                "industrial",
                "hospitality",
                "medical",
                "STNL",
              ].map((type, i) => (
                <div key={i} className="d-inline-block mb-1 mr-1">
                  <StyledBadge
                    color={
                      form.values.types.includes(type) ? "primary" : "secondary"
                    }
                  >
                    <StyledLabel className="text-capitalize font-weight-normal mb-0">
                      {formatTypeWithTooltip(type)}
                      <input
                        className="sr-only"
                        type="checkbox"
                        onChange={toggleType}
                        value={type}
                      />
                    </StyledLabel>
                  </StyledBadge>
                </div>
              ))}
              {fieldText && <FormText>{fieldText}</FormText>}
            </div>
          </FormGroup>
        );
      }}
    />
  );
}
