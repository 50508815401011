import React from "react";
import { ShowProvider } from "~providers/show";
import ShowComponent from "~components/listings/show";
import { useParams } from "react-router-dom";
import { defaultParams } from "../listingsDefaultConfigs";

const reducer = (state, action) => {
  switch (action.type) {
    case "setStore":
      return action.payload;

    case "updateListing":
      return {
        ...state,
        ...action.payload,
      };

    case "addOpportunity":
      const { data } = action.payload;
      const opportunity = {
        ...data,
        ...{ comments: data.comments || [] },
      };
      return {
        ...state,
        ...{ opportunities: [opportunity, ...state.opportunities] },
      };

    case "updateOpportunity":
      const { id } = action.payload;
      const opportunities = state.opportunities.map((o) =>
        id === o.id ? { ...o, ...action.payload } : o
      );
      return { ...state, ...{ opportunities } };

    case "comment":
      const { opportunity_id } = action.payload;
      const updatedOpportunities = state.opportunities.map((o) => {
        if (o.id === opportunity_id) {
          return { ...o, ...{ comments: [action.payload, ...o.comments] } };
        }
        return o;
      });
      return { ...state, ...{ opportunities: updatedOpportunities } };

    default:
      return state;
  }
};

export default function Show(props) {
  const { id } = useParams();
  const [state, dispatch] = React.useReducer(reducer, null);
  return (
    <ShowProvider
      resourcePath={`listings/${id}/`}
      defaultParams={defaultParams}
      reducer={reducer}
      store={state}
      dispatch={dispatch}
    >
      <ShowComponent />
    </ShowProvider>
  );
}
