import styled from "styled-components";
import { colors } from "~utilities/styles";

const StyledButtonUnselected = styled.button`
  border-radius: 3px;
  border-color: ${colors.foreground};
  padding: 3px;
  background-color: inherit;
  border-style: solid;
  margin-left: 3px;
  margin-right: 3px;
`;
const StyledButtonSelected = styled(StyledButtonUnselected)`
  border-color: rgb(0, 179, 149, 0.001);
  background-color: rgb(0, 179, 149, 0.08);
`;
export default function ToggleButton({
  selected,
  children,
  onClick,
  disabled,
}) {
  if (selected) {
    return (
      <StyledButtonSelected onClick={onClick}>{children}</StyledButtonSelected>
    );
  }
  return (
    <StyledButtonUnselected onClick={onClick} disabled={disabled}>
      {children}
    </StyledButtonUnselected>
  );
}
