import React, { useCallback } from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import { colors } from "~utilities/styles";
import Button from "components/Button";
import { useListProvider } from "providers/list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import filterLogo from "~assets/img/filter.svg";
import Tooltip from "components/Tooltip";

const NavSection = styled.section`
  background: ${colors.foreground};
  border-bottom: 1px solid ${colors.gray["300"]};
  padding: 18px 0;
`;

const WelcomeText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;
const SubheadingText = styled.div`
  font-size: 1rem;
`;

const BadgeFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -3px;
  right: -3px;
`;
const ImgWrapper = styled.div`
  text-align: center;
  overflow: hidden;
  margin: 0 10px 0 0;
`;
const StyledImg = styled.img`
  height: 1.5rem;
  width: auto;
`;

const showFilterSelected = (where) => {
  return Boolean(
    where.user_id?.not?.length ||
      where.types?.overlaps?.length ||
      where.region_ids?.length ||
      where.is_active?.length === 0 ||
      where.is_active?.some((ele) => ele === false)
  );
};

export default function Layout({ children }) {
  const { state, dispatch } = useListProvider();
  const filterSelected = showFilterSelected(state.params.where);
  const handleFilterChange = useCallback(
    (e) => {
      dispatch({
        type: "UPDATE_DISPLAY_FILTER",
        payload: !state.displayFilter,
      });
    },
    [state.displayFilter, dispatch]
  );

  const FilterContent = () => (
    <div className="d-flex align-items-center">
      <div className="mr-auto ml-0 pl-0">Filters</div>
      <ImgWrapper className="ml-3 mr-0 pr-0">
        <StyledImg src={filterLogo} />
      </ImgWrapper>
    </div>
  );

  return (
    <Container fluid>
      <NavSection className="row px-4">
        <div className="flex-fill d-flex">
          <WelcomeText className="d-flex align-items-center ">
            Buyers in the market
          </WelcomeText>
        </div>
        <div className="flex-fill d-flex">
          <SubheadingText className="d-flex align-items-center">
            You can find 1031 exchange buyers through a brokerage that
            specializes in these transactions
          </SubheadingText>
        </div>
        <div className="position-relative mb-2 mr-5">
          <Button
            className="px-0"
            name="displayFilter"
            theme={state.displayFilter ? "primary" : "secondary"}
            onClick={handleFilterChange}
          >
            {filterSelected ? (
              <Tooltip
                content={
                  <span>
                    The dot on the button indicates filtering has been changed
                    from the default
                  </span>
                }
              >
                <FilterContent />
              </Tooltip>
            ) : (
              <FilterContent />
            )}
          </Button>
          {filterSelected && (
            <BadgeFontAwesomeIcon
              icon="circle"
              size="xs"
              color={colors.warning}
              title="Filter(s) Selected"
            />
          )}
        </div>
      </NavSection>
      {children}
    </Container>
  );
}
