import React, { useEffect, useState } from "react";
import { useListProvider } from "providers/list";
import { Label } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "react-custom-checkbox";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { colors } from "~utilities/styles";
import { formatTypeWithTooltip } from "utilities/format";

const StyledLabel = styled(Label)`
  cursor: pointer;
`;

const StyledItem = styled.li`
  margin-left: 7px;
  ${(props) => props.active && `font-weight: 700; margin-left: 0;`}
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  background-color: ${colors.success};
  color: ${colors.foreground};
  overflow: "hidden";
`;
export default function TypeFilterSelect() {
  const { state, dispatch } = useListProvider();
  const [selectedTypes, selectTypes] = useState([]);
  const types = [
    "multifamily",
    "office",
    "retail",
    "industrial",
    "hospitality",
    "medical",
    "STNL",
  ];

  function clearTypes() {
    const { types, ...rest } = state.params.where;
    dispatch({
      type: "UPDATE_PARAMS",
      params: {
        where: rest,
      },
    });
  }

  const handleSelectChange = (checked, type) => {
    const { where } = state.params;
    const overlaps = checked
      ? where.types?.overlaps.concat([type]) || [type]
      : where.types?.overlaps.filter((elem) => elem !== type);
    dispatch({
      type: "UPDATE_PARAMS",
      params: {
        where: { ...where, types: { overlaps } },
      },
    });
  };

  useEffect(() => {
    const types = state.params.where.types?.overlaps;
    if (types && types.length) {
      selectTypes(types);
    } else {
      selectTypes([]);
    }
  }, [state.params.where.types]);

  return (
    <>
      <h5 className="d-inline-block pr-2">Property Types</h5>
      {selectedTypes.length > 0 && (
        <FontAwesomeIcon
          icon="times"
          id="clearPropertyTypes"
          title="Clear Selections"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            selectTypes([]);
            clearTypes();
          }}
        />
      )}
      {types.map(function (propertyType, index) {
        return (
          <div key={index}>
            <StyledItem className="d-flex align-items-center py-1">
              <StyledLabel className="text-capitalize font-weight-normal mb-0 d-flex">
                <Checkbox
                  className="mr-1"
                  borderRadius="3px"
                  name={propertyType}
                  checked={
                    selectedTypes.filter((t) => t === propertyType).length
                  }
                  onChange={(e) => handleSelectChange(e, propertyType)}
                  icon={<StyledFontAwesomeIcon icon={faCheck} />}
                  borderColor={colors.secondary}
                />
                {formatTypeWithTooltip(propertyType)}
              </StyledLabel>
              <br />
            </StyledItem>
          </div>
        );
      })}
    </>
  );
}
