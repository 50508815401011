import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import { useAuth } from "../../../providers/auth";
import { Formik, Form } from "formik";
import Input from "../../../components/Input";
import { colors } from "../../../utilities/styles";
import { validateEmail } from "../../../utilities/validations";
import styled from "styled-components";
import AuthForm from "../components/AuthForm";

const FormError = styled.div`
  color: ${colors.error};
`;

const validation = (values, errors) => {
  if (!validateEmail(values.email)) {
    errors.email = "Invalid email";
  }
  return errors;
};

export default function ResetEmail() {
  const [error, setError] = useState("");
  const auth = useAuth();
  const history = useHistory();
  const validate = (values) => {
    let errors = {};
    if (error) {
      setError();
    }
    return validation(values, errors);
  };
  async function handleSubmit({ email }) {
    const response = await auth.sendPasswordResetEmail(email);
    if (!response || response.error) {
      setError("Something went wrong. Review the form and try again.");
      return;
    }
    history.push("/login");
  }

  return (
    <AuthForm title="Reset Password">
      <Formik
        initialValues={{
          email: "",
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(form) => {
          return (
            <Form>
              <FormGroup size="lg" id="email">
                <Input autoFocus name="email" type="email" label="Email" />
              </FormGroup>
              <Button
                block
                className="mb-3"
                size="lg"
                type="submit"
                disabled={!form.isValid || !form.dirty}
              >
                Send Reset Email
              </Button>
              {error && <FormError className="my-2">{error}</FormError>}
              {auth.user ? (
                <Link to="/">Back to Home</Link>
              ) : (
                <Link to="/login">Back to Login</Link>
              )}
            </Form>
          );
        }}
      </Formik>
    </AuthForm>
  );
}
