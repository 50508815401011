import runtimeEnv from "@mars/heroku-js-runtime-env";

// For CI build-specific environment variables, see .env files.
// These environment variables are available client-side.
const config = {
  development: {
    API_URL: "http://localhost:3000",
    // "AIzaSyDx44rX5YKiDlBWLVR2rTLjNaw6jWQ2OE0" === pocketbook GMap key, in pocketbook project, in google maps
    // "AIzaSyB0dVUPlroSv1ZpcDjMIVZ5aiz-BQDvqqU" === pocketbook GMap key, in *SPACEFUL* project, in google maps
    // above tech debt is because of permissions on billing account
    googleMapApiKey: "AIzaSyB0dVUPlroSv1ZpcDjMIVZ5aiz-BQDvqqU", //"AIzaSyDx44rX5YKiDlBWLVR2rTLjNaw6jWQ2OE0",
  },
  staging: {
    API_URL: "https://newmark-pocketbook-stage.herokuapp.com",
    googleMapApiKey: "AIzaSyB0dVUPlroSv1ZpcDjMIVZ5aiz-BQDvqqU", //"AIzaSyDx44rX5YKiDlBWLVR2rTLjNaw6jWQ2OE0",
  },
  production: {
    API_URL: "https://newmark-pocketbook-prod.herokuapp.com",
    googleMapApiKey: "AIzaSyBS93O2_yfExGW0w6vNNnyhCeLaEHG3QVU",
  },
};
const env = runtimeEnv();
const environment = env.REACT_APP_ENV || env.NODE_ENV;

export default config[environment];
