import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useApi } from "~utilities/api";
import Table, { DateCell, TitleCell } from "../Table";
import Loading from "../Loading";
import styled from "styled-components";
import TableNav, { useQuery } from "./TableNav";
import { colors } from "~utilities/styles";
import { useAuth } from "../../providers/auth";
import { formatPercent } from "~utilities/format";
import CapitalizedList from "components/CapitalizedList";

const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 900;
  padding: 0.5rem 0;
`;

const Warning = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${colors.warning};
`;

const filterItems = [
  { label: "All", navKey: null },
  { label: "Inbox", navKey: "inbox" },
  { label: "Pending Leads", navKey: "pending" },
  { label: "Unproductive Requirements", navKey: "unproductive" },
];

const hasNewComment = ({ user_id, opportunities }) => {
  return opportunities.find(({ comments }) => {
    return comments.length && comments[0]?.user.id !== user_id;
  });
};

const cols = [
  {
    id: "name",
    Header: "Name",
    columnWidth: "45rem",
    accessor: ({ name }) => name && name.toLowerCase(),
    Cell: ({ row }) => <TitleCell value={row.original.name} />,
  },
  {
    id: "min_capitalization_percent",
    Header: "Min. Cap Rate",
    columnWidth: "10rem",

    accessor: ({ min_capitalization_percent }) => {
      return formatPercent(min_capitalization_percent, 3);
    },
  },
  {
    id: "types",
    Header: "Property Type",
    columnWidth: "10rem",

    accessor: (requirement) => {
      return requirement.types.length > 1
        ? requirement.types.length
        : requirement.types?.[0];
    },
    Cell: ({ row }) => {
      const { types } = row.original;
      return <CapitalizedList items={types} />;
    },
  },
  {
    id: "regions",
    Header: "Preferred Location",
    columnWidth: "10rem",

    accessor: ({ regions }) => {
      const names = regions.map((region) => region.name);
      return names.length > 1 ? names.length : names?.[0];
    },
    Cell: ({ row }) => {
      let { regions } = row.original;
      regions = regions.length > 0 ? regions : [{ name: "National" }];
      return <CapitalizedList items={regions.map((region) => region.name)} />;
    },
  },

  {
    id: "leads",
    columnWidth: "10rem",
    Header: "Leads",
    accessor: ({ leads }) => {
      return leads.length;
    },
    Cell: ({ value }) => {
      return <span>{value === 50 ? "50+" : value}</span>;
    },
  },
  {
    id: "opportunities",
    columnWidth: "10rem",
    Header: "Opportunities",
    accessor: ({ opportunities }) => {
      return opportunities.length;
    },
    Cell: ({ row }) => {
      const warning = hasNewComment(row.original);
      return (
        <span className="position-relative pr-2">
          {warning && <Warning />}
          {
            row.original.opportunities.filter((o) => o.stage !== "archived")
              .length
          }
        </span>
      );
    },
  },
  {
    Header: "Expires On",
    accessor: "expires_on",
    columnWidth: "10rem",
    Cell: DateCell,
  },
];

const ZeroState = ({ hasRequirements, filter }) => {
  const copyObject = {
    inbox: " with an opportunity waiting for a response.",
    pending: " with leads, but no opportunities.",
    unproductive: " producing no leads, or opportunities.",
  };
  if (filter === "all" || !hasRequirements) {
    return (
      <div className="pt-3">
        You have no requirements.
        <Link to="/requirements/create">Create a requirement</Link> to start
        reaching out to listings.
      </div>
    );
  }
  return (
    <div className="pt-3">You have no requirements{copyObject[filter]}</div>
  );
};

const filters = {
  inbox: hasNewComment,
  pending: ({ leads, opportunities }) => {
    return !opportunities.length && leads.length;
  },
  unproductive: ({ leads, opportunities }) => {
    return !opportunities.length && !leads.length;
  },
};

export default function Requirements() {
  const columns = React.useMemo(() => cols, []);
  const { user } = useAuth();
  const [{ loading, data = [] }] = useApi("/requirements.json", {
    limit: 1000,
    include: {
      regions: true,
      leads: true,
      opportunities: {
        comments: {
          user: true,
        },
      },
    },
    order: {
      created_at: "desc",
    },
    where: {
      user: {
        email: user.email,
      },
    },
  });
  const filterKey = useQuery().get("filter");
  const filter = filterItems.reduce((acc, { navKey, label }) => {
    return navKey === filterKey ? label : acc;
  }, "All");
  const tableData = React.useMemo(() => {
    const filterFunction = filters[filterKey];
    const processedData = filterFunction ? data.filter(filterFunction) : data;
    return processedData;
  }, [data, filterKey]);
  if (loading) {
    return <Loading />;
  }
  const navHeight = "calc(100vh - 225px)";
  const tableHeight = "calc(100vh - 125px)";
  return (
    <>
      <Row className="pb-3">
        <Col md="3" lg="2">
          <PageTitle className="py-2">My Requirements</PageTitle>
          <TableNav
            urlPath="myrequirements"
            navItems={filterItems}
            tableHeight={navHeight}
          />
        </Col>
        <Col md="9" lg="10">
          {tableData.length ? (
            <Table
              title={filter}
              resourcePath="myrequirements"
              columns={columns}
              data={tableData}
              tableHeight={tableHeight}
            />
          ) : (
            <ZeroState filter={filterKey} hasRequirements={data.length} />
          )}
        </Col>
      </Row>
    </>
  );
}
